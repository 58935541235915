import styled from "@emotion/styled";
import { ReactNode } from "react";

const Button = styled.button<{ active: boolean, highlighted: boolean }>`
  border: none;
  background: none;

  width: 100px;
  padding: 1.5rem 0;
  background: ${(props) => (props.active ? "#5c80bc" : "none")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  border: ${(props) => (props.highlighted ? "2px solid #f00" : "none")};
  path {
    fill: ${(props) => (props.active ? "#fff" : "#000")};
  }
  
  &:hover {
    background: #5c80bc;
    color: #fff;

    path {
      fill: "#fff" !important;
    }
  }

  
`;

export const NavButton: React.FC<{
  onClick: () => void;
  label: string;
  children: ReactNode;
  active: boolean;
  highlighted: boolean;
}> = ({ onClick, active, label, children, highlighted }) => {
  return (
    <Button onClick={onClick} active={active} highlighted={highlighted}>
      {children}
      <br />
      {label}
    </Button>
  );
};
