import styled from "@emotion/styled";
import * as React from "react";
import bg from "./bg.png";

const IMG = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  max-width: none;
`;

function DialogBG() {
  return <IMG src={bg} />;
}

export default DialogBG;
