import React from "react";
import styled from "@emotion/styled";
import { DialogHistory, History } from "./useDialog";

const TranscriptContainer = styled.div({
  label: "TranscriptContainer",
  position: "fixed",
  top: 0,
  left: 0,
  width: 500,
  bottom: 0,
  padding: 10,

  overflowY: "hidden",
  overflowX: "hidden",
});

const CloseButton = styled.button`
  label: CloseButton;
  position: absolute;
  top: 17px;
  right: 5px;
  padding: 5px;
  border: none;
  background: none;
  color: #fff;
  font-size: 28px;
`;

export const DialogTranscript: React.FC<{
  history: History[];
  toggleTranscript?: () => void;
  currentPrompt: string;
}> = ({ history, toggleTranscript, currentPrompt }) => {
  return (
    <TranscriptContainer>
      <CloseButton onClick={toggleTranscript}>⨉</CloseButton>
      <div className="card-body">
        <Title>Transcript</Title>
        <TranscriptText>
          {history?.map((h: History, i: number) => (
            <div key={i}>
              {h.type === "dialog" && <PromptHistory history={h} />}
              {/* {h.type === "action" && <ActionHistoryLine history={h} />}
            {h.type === "condition" && <ConditionHistoryLine history={h} />} */}
            </div>
          ))}
          {currentPrompt && <Prompt>{currentPrompt}</Prompt>}
        </TranscriptText>
      </div>
    </TranscriptContainer>
  );
};

const TranscriptText = styled.div`
  overflow-y: auto;
  position: absolute;
  top: 80px;
  bottom: 0px;
  margin-right: 3px;
  padding-right: 5px;

  ::-webkit-scrollbar {
    background: none;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 16px;
    width: 12px;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
`;

const Response = styled.div`
  margin: 10px 0px 30px 0px;
  border-left: 2px solid #fff;
  padding-left: 10px;
`;

const Prompt = styled.div(({ color }) => ({
  color: "#FFF",
  fontFamily: "Noto Sans",
  fontSize: 18,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
}));

const PromptHistory: React.FC<{ history: DialogHistory }> = ({ history }) => {
  return (
    <>
      <Prompt>{history.prompt}</Prompt>
      <Response>
        <Prompt>{history.response}</Prompt>
      </Response>
    </>
  );
};

const Title = styled.div`
  color: #fff;
  font-family: "Noto Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0px;
`;
