export const DialogMenuButton = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" fill="#00171F" fillOpacity="0.75" />
      <rect x="22" y="22" width="20" height="2" fill="#D9D9D9" />
      <rect x="22" y="31" width="20" height="2" fill="#D9D9D9" />
      <rect x="22" y="40" width="20" height="2" fill="#D9D9D9" />
    </svg>
  );
};
