import { createAsyncThunk, current } from "@reduxjs/toolkit";
import produce from "immer";
import { RootState } from "../root-store";

import { simulationProgress } from "./simulation-slice";
import { Simulation } from "./simulation-types";
import { getBoardBuffs, getConstant, getCurrentAgeBand } from "../selectors";

interface AdvanceParams {}

export const advanceSimulation = createAsyncThunk(
  "sim/advance",
  async (_params: AdvanceParams, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { simulator } = state;

    const ageBandIndex = getCurrentAgeBand(state);

    // const maxHealthyImpact = getMaxImpact(state, 0, "healthy");
    // const yourHealthyImpact = getYourImpact(state, 0, "healthy");
    // const maxConditionImpact = getMaxImpact(state, 0, "frail");
    // const yourConditionImpact = getYourImpact(state, 0, "frail");

    const startingInsight =
      getConstant(state, "Starting Insight", "all", -1)?.value || 0;

    const startingTrust =
      getConstant(state, "Starting Trust", "all", -1)?.value || 0;

    const startingUptake =
      getConstant(state, "Starting Uptake", "all", -1)?.value || 0;

    const totalBuffs = getBoardBuffs(state);
    const insightTotal = (totalBuffs.Insight || 0) + startingInsight;
    const trustTotal = (totalBuffs.Trust || 0) + startingTrust;
    const uptakeTotal = (totalBuffs.Uptake || 0) + startingUptake;
    const impactTotal = Math.min(
      100,
      Math.round((insightTotal + trustTotal + uptakeTotal) / 3)
    );

    const maxImpact =
      getConstant(state, "Max Impact", "all", ageBandIndex)?.value || 1;

    // const userImpactRatioCondition = yourConditionImpact / maxConditionImpact;
    // const userImpactRatioHealthy = yourHealthyImpact / maxHealthyImpact;
    const userImpactRatioCondition = Math.max(
      0,
      Math.min(1, impactTotal / maxImpact)
    );
    const userImpactRatioHealthy = userImpactRatioCondition;

    console.info("Simulating using", {
      userImpactRatioCondition,
      userImpactRatioHealthy,
    });

    return produce(simulator, (draftSimulator) => {
      return new Promise<Simulation>((resolve) => {
        try {
          const draft = current(draftSimulator);
          console.info("Creating worker");
          const worker = new Worker(
            new URL("./simulation-worker.js", import.meta.url)
          );

          worker.onmessage = (event) => {
            console.info("WORKER MESSAGE", event.data.type);
            const { data } = event;
            switch (data.type) {
              case "progress":
                thunkAPI.dispatch(simulationProgress(data.value));
                break;
              case "complete":
                //saveGame(data.simulation);
                console.info("Resolving new sim data");
                resolve(data.simulation);
                worker.terminate();
                break;
            }
          };

          worker.postMessage({
            state: draft,
            userImpactRatioHealthy,
            userImpactRatioCondition,
          });
        } catch (error) {
          console.error(error);
        }
      });
    });
  }
);
