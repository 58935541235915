import React, { ReactNode, useMemo } from "react";
import "./firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import StyledFirebaseAuth from "./FirebaseAuth";
import { Navigate, useLocation } from "react-router-dom";

export const Login: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { pathname } = useLocation();
  const [currentUser, setCurrentUser] = React.useState<firebase.User | null>(
    null
  );

  const auth = useMemo(() => {
    const auth = firebase.auth();
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    auth.onAuthStateChanged((user) => setCurrentUser(user));
    return auth;
  }, []);

  if (currentUser) {
    console.info("Logged in");
    return <> {children}</>;
  }

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    //signInSuccessUrl: pathname,
    // We will display Google and Facebook as auth providers.
    // signInSuccess: (user: firebase.User) => {
    //   setCurrentUser(user);
    // },
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      //firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
  };

  return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />;
};
