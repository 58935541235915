import React from "react";
import { EMRSection } from "./EMRSection";
import styled from "@emotion/styled";
import { EMR } from "../data/emr";
import { Column, CommentsBox, DoubleColumns, Page, SubHead } from "./EMRStyles";
import { EMRToggle } from "./EMRToggle";
import { EMRRadio } from "./EMRRadio";
import EMRHighlight from "./EMRHighlight";

const Label = styled.div`
  color: #000;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const Content = styled.div`
  padding-left: 10px;
`;

export const EMRACP: React.FC<{ data: EMR }> = ({ data }) => {
  return (
    <Page>
      <EMRSection
        title="Advance Care Plan"
        style={{ minHeight: 0, flex: "1 1 0" }}
      >
        <DoubleColumns>
          <Column>
            <SubHead>ACP</SubHead>
            <Content>
              <EMRHighlight emrPath="acp.onFile">
                <Label>ACP on file</Label>
                <EMRToggle active={data.acp.onFile} />
              </EMRHighlight>

              <EMRHighlight emrPath="acp.healthcareProxy">
                <Label>Healthcare Proxy</Label>
                <p>
                  {data.acp.healthcareProxy.name}
                  <br />
                  {data.acp.healthcareProxy.contact}
                </p>
              </EMRHighlight>

              <EMRHighlight emrPath="acp.acpRequiresUpdate">
                <Label>Needs Updating</Label>
                <EMRToggle active={data.acp.acpRequiresUpdate} />
              </EMRHighlight>

            </Content>
            <SubHead>Comments</SubHead>
            <Content>
              <CommentsBox>
                <EMRHighlight emrPath="acp.comments">
                  {data.acp.comments}
                </EMRHighlight>
              </CommentsBox>
            </Content>
          </Column>
          <Column>
            <SubHead>POLST</SubHead>
            <Content>

              <EMRHighlight emrPath="acp.polstOnFile">
                <Label>POLST on file</Label>
                <EMRToggle active={data.acp.polstOnFile} />
              </EMRHighlight>

              <EMRHighlight emrPath="acp.dnrStatus">
                <Label>DNR status</Label>
                <EMRRadio active={data.acp.dnrStatus === "full"}>Full</EMRRadio>
                <EMRRadio active={data.acp.dnrStatus === "modified"}>
                  Modified
                </EMRRadio>
                <EMRRadio active={data.acp.dnrStatus === "no"}>No</EMRRadio>
              </EMRHighlight>


              <EMRHighlight emrPath="acp.dnrRequiresUpdate">
                <Label>Needs Updating</Label>
                <EMRToggle active={data.acp.dnrRequiresUpdate} />
              </EMRHighlight>
            </Content>
          </Column>
        </DoubleColumns>
      </EMRSection>
    </Page>
  );
};
