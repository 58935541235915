import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import { IconProblem } from "./IconProblem";
import { IconMonitor } from "./IconMonitor";
import { IconOK } from "./IconOK";
import { EMR, getSDOHQuestion } from "../../data/emr";
import { SDOHQuestionCategories } from "./EMRSDOH";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: space-around;
`;

export const SDOHSummary: React.FC<{ emr: EMR }> = ({ emr }) => {
  const { problem, monitor, ok, total } = calcSDOHScore(emr);
  return (
    <Row>
      <Item label="Problem" value={problem}>
        <IconProblem />
      </Item>
      <Item label="Monitor" value={monitor}>
        <IconMonitor />
      </Item>
      <Item label="No problem" value={ok}>
        <IconOK />
      </Item>
      <Col>
        <TotalScore>
          {Math.round((ok / total) * 100)}
        </TotalScore>
        <Label>SDOH Score</Label>
      </Col>
    </Row>
  );
};

const calcSDOHScore = (emr: EMR) => {
  const scores = {
    problem: 0,
    monitor: 0,
    ok: 0,
    total: 0,
  };
  SDOHQuestionCategories.forEach((category, catIndex) => {
    category.questions.forEach((question, questionIndex) => {
      const answer = getSDOHQuestion(catIndex, questionIndex, emr);
      const optionCount = question.options.length;
      scores.total += 1;
      if (answer === optionCount - 1) {
        scores.ok += 1;
      } else if (answer === 0) {
        scores.problem += 1;
      } else {
        scores.monitor += 1;
      }
    });
  });
  return scores;
};

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-top: 15px;
`;

const Item: React.FC<{ label: string; value: number; children: ReactNode }> = ({
  label,
  value,
  children,
}) => {
  return (
    <Col>
      {children}
      <Value>{value}</Value>
      <Label>{label}</Label>
    </Col>
  );
};

const TotalScore = styled.div`
  color: #000;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Value = styled.div`
  color: #000;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Label = styled.div`
  color: #000;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
