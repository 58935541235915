import styled from "@emotion/styled";
import { useInsight } from "../state/insight";
import { toast } from "react-toastify";
import { InsightIcon } from "./InsightIcon";

export const InsightDisplay: React.FC = () => {
  const { insight, addInsight, insightAnimation } = useInsight();

  window.addInsight = (message: string, value: number, notes?: string) => {
    addInsight(message, value);
  };

  const isAnimating = !!insightAnimation;

  return (
    <InsightContainer>
      <InsightIcon />

      <MeterContainer>
        <Labels>
          <InsightLabel>Insight</InsightLabel>
          {isAnimating && (
            <InsightAddition>
              +{insight.messages[insight.messages.length - 1]}
            </InsightAddition>
          )}
        </Labels>
        <Meter value={insight.score} color={isAnimating ? "#fff" : "#e8c547"} />
      </MeterContainer>
    </InsightContainer>
  );
};

const Labels = styled.div`
  label: Labels;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
`;

const MeterContainer = styled.div`
  label: MeterContainer;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InsightAddition = styled.div`
  label: InsightLabel;
  color: #fff;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
`;
const InsightLabel = styled.div`
  label: InsightLabel;
  color: #fff;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const InsightContainer = styled.div`
  label: InsightContainer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

declare global {
  interface Window {
    addInsight: (message: string, value: number) => void;
  }
}

const Meter = styled.div<{ value: number; color: string }>`
  width: 260px;
  height: 8px;
  background-color: #021d2a;
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  overflow: hidden;
  &:before {
    content: "";
    display: block;
    width: ${(props) => props.value}%;
    height: 100%;
    background-color: ${(props) => props.color};
    transition: width 1s;
  }
`;
