import styled from "@emotion/styled";
import { useEffect, useState } from "react";

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;
const Dot = styled.div<{ active: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${(props) => (props.active ? "#ffffff" : "#436780")};
  margin: 0 4px;
`;

interface AssessmentResultDotsProps {
  onComplete: () => void;
}
export const AssessmentResultDots: React.FC<AssessmentResultDotsProps> = ({ onComplete }) => {
  // Three dots that alternate color in a row to show a working status.
  const [activeDot, setActiveDot] = useState<number>(0);
  useEffect(() => {    
    const otherTimeout = setTimeout(onComplete, 2200);
    const interval = setInterval(() => {
      setActiveDot((prevActiveDot) => (prevActiveDot + 1) % 3);
    }, 200);
    return () => {
      clearInterval(interval);
      clearTimeout(otherTimeout);
    };
  }, []);
  return (
    <DotContainer>
      <Dot active={activeDot === 0} />
      <Dot active={activeDot === 1} />
      <Dot active={activeDot === 2} />
    </DotContainer>
  );
};
