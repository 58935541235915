import styled from "@emotion/styled";
import React, { ReactNode } from "react";

export const EMRRadio: React.FC<{ children: ReactNode; active: boolean }> = ({
  children,
  active,
}) => {
  const icon = active ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 18"
      fill="none"
    >
      <circle cx="8" cy="8.5" r="7.5" fill="white" stroke="#4D5061" />
      <circle cx="8" cy="8.5" r="5" fill="#5C80BC" />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 18"
      fill="none"
    >
      <circle cx="8" cy="8.5" r="7.5" fill="white" stroke="#4D5061" />
    </svg>
  );
  return (
    <Radio>
      {icon}
      {children}
    </Radio>
  );
};

const Radio = styled.div`
  display: flex;
  gap: 0.1em;
  align-items: center;
  margin-left: 10px;
`;
