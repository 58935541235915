import { RootState } from "./root-store";
import { BuffTypes, Treatment } from "./treatments";
import {
  CalcState,
  ConditionsOrHealthy,
  GameState,
  MaybeTreatment,
  Sim,
  SimTagLabelMap,
  Simulation,
} from "./simulation/simulation-types";
import { Constant, ConstantConditions, SimTagDefinition } from "./constants";
import { FiveMNames } from "./five-ms";
import { createSelector } from "@reduxjs/toolkit";
import { DialogDataTypes } from "./simulation/firedata";
import { getConditionSeverity } from "./simulation/sim";

type SimulatorPartialState = {
  simulator: Simulation;
};

export const getCurrentTreatmentOption = (
  state: SimulatorPartialState,
  condition: ConditionsOrHealthy,
  ageBand: number,
  treatmentIndex: number
): MaybeTreatment => {
  return state.simulator.treatmentSelections[condition][ageBand][
    treatmentIndex
  ];
};

export const getCurrentTreatmentOptionsFromSimulation = (
  simulation: Simulation,
  condition: ConditionsOrHealthy,
  ageBand: number
): Treatment[] => {
  return simulation.treatmentSelections[condition][ageBand].filter(
    (t) => !!t
  ) as Treatment[];
};

export const getCurrentTreatmentOptions = (
  state: SimulatorPartialState,
  condition: ConditionsOrHealthy,
  ageBand: number
): Treatment[] =>
  getCurrentTreatmentOptionsFromSimulation(state.simulator, condition, ageBand);

export const getBoardAssessmentIds = (state: SimulatorPartialState) =>
  state.simulator.treatmentIdBoardSelections;

export const getBoardAssessments = (state: SimulatorPartialState) =>
  state.simulator.treatmentIdBoardSelections
    .map((id) => state.simulator.treatments.find((t) => t.id === id))
    .filter((t) => !!t) as Treatment[];

export const getBoardAssessmentsInsight = (state: SimulatorPartialState) =>
  getBoardAssessments(state).reduce(
    (acc, t) => acc + (t.buffs.Insight || 0),
    0
  );

export const getBoardAssessmentsTrust = (state: SimulatorPartialState) =>
  getBoardAssessments(state).reduce((acc, t) => acc + (t.buffs.Trust || 0), 0);

export const getBoardAssessmentsUptake = (state: SimulatorPartialState) =>
  getBoardAssessments(state).reduce((acc, t) => acc + (t.buffs.Uptake || 0), 0);

export const getBoardAssessmentsImpact = (state: SimulatorPartialState) =>
  getBoardAssessments(state).reduce((acc, t) => acc + t.cost, 0);

export const getBoardAssessmentsCost = (state: SimulatorPartialState) =>
  getBoardAssessments(state).reduce((acc, t) => acc + t.cost, 0);

const doesSimMatchTags = (tags: string[]) => (sim: Sim) => {
  if (tags.includes("All")) return true;
  const ageBand = sim.ageBands[sim.ageBands.length - 1];
  const condition = getConditionSeverity(ageBand, "frail");

  if (tags.includes("Frail") && condition >= 3) return true;
  if (tags.includes("Pre-Frail") && condition >= 0) return true;

  const sharedTags = ageBand.tags.filter((t) => tags.includes(t.name));

  return sharedTags.length > 0;
};

const getPopulationBuffAppliesTo = (
  tags: string[],
  state: SimulatorPartialState
) => {
  const matchingSims = state.simulator.sims.filter(doesSimMatchTags(tags));
  return matchingSims.length / state.simulator.sims.length;
};

export const getBoardBuffs = (state: SimulatorPartialState) =>
  getBoardAssessments(state).reduce((acc, t) => {
    const tags = t.tags;

    const ratio = getPopulationBuffAppliesTo(tags, state);
    console.info("Ratio for ", t.name, ratio);

    Object.keys(t.buffs).forEach((key) => {
      acc[key as BuffTypes] =
        (acc[key as BuffTypes] || 0) + Math.ceil(ratio * t.buffs[key as BuffTypes]);
    });

    return acc;
  }, {} as Record<BuffTypes, number>);

export const getTreatments = (state: SimulatorPartialState): Treatment[] =>
  state.simulator.treatments;

export const getCurrentAgeBand = (state: SimulatorPartialState): number =>
  state.simulator.currentAgeBand;

export const getCalcState = (state: SimulatorPartialState): CalcState =>
  state.simulator.calcState;

export const getCalcProgress = (state: SimulatorPartialState): number =>
  state.simulator.progress;

const notFoundReported: Record<string, boolean> = {};
export const getConstantFromSimulation = (
  state: Simulation,
  name: string,
  condition: ConstantConditions = "all",
  ageBandIndex: number = -1,
  level: number | undefined = undefined
): Constant | undefined => {
  // const c = state.constants.find(
  //   (constant) =>
  //     constant.name === name &&
  //     constant.ageBandIndex === ageBandIndex &&
  //     constant.condition === condition &&
  //     constant.level === level
  // );
  const hash = `${name}-${ageBandIndex}-${condition}-${level}`;

  const c = state.constantsHash[hash];

  if (!c) {
    if (!notFoundReported[hash]) {
      notFoundReported[hash] = true;
      // console.warn("Constant not found ", hash, {
      //   name,
      //   condition,
      //   ageBandIndex,
      //   level,
      // });
    }
  }
  return c;
};

export const getConstantValue = (
  state: SimulatorPartialState,
  name: string,
  condition: ConstantConditions = "all",
  ageBandIndex: number = -1,
  level: number | undefined = undefined
): number | undefined =>
  getConstantFromSimulation(
    state.simulator,
    name,
    condition,
    ageBandIndex,
    level
  )?.value;

export const getConstant = (
  state: SimulatorPartialState,
  name: string,
  condition: ConstantConditions = "all",
  ageBandIndex: number = -1,
  level: number | undefined = undefined
): Constant | undefined =>
  getConstantFromSimulation(
    state.simulator,
    name,
    condition,
    ageBandIndex,
    level
  );

export const getTagLabelMap = (state: SimulatorPartialState): SimTagLabelMap =>
  state.simulator.tagLabels;

export const getTagLabel = (
  state: SimulatorPartialState,
  name: string,
  level: number
): string => {
  const label = state.simulator.tagLabels[`${name}-${level}`];
  return label ? label.label : `${name}/${level}`;
};

export const areConstantsLoaded = (state: SimulatorPartialState): boolean =>
  state.simulator.constants.length > 0 &&
  state.simulator.treatments.length > 0 &&
  state.simulator.simTags.length > 0;

export const isSimLoaded = (state: SimulatorPartialState): boolean =>
  areConstantsLoaded(state) && state.simulator.currentAgeBand >= 0;

export const getSimulation = (state: SimulatorPartialState): Simulation =>
  state.simulator;

export const getSims = (state: SimulatorPartialState): Sim[] =>
  state.simulator.sims;

const isAppleToAppleSim = (sim: Sim): boolean =>
  sim.userYears.length === sim.bauYears.length;

/**
 * Returns a subset of sims that all lived the same amount of time for doing apples-to-apples comparisons without
 * having mortality get in the way.
 */
export const getApplesToApplesSims = createSelector(
  getSims,
  (sims: Sim[]): Sim[] => sims.filter(isAppleToAppleSim)
);

type TreatmentScore = { best: Treatment | null; score: number };
export const getIdealTreatments = (
  state: SimulatorPartialState,
  ageBandIndex: number,
  condition: ConditionsOrHealthy
): Treatment[] => {
  const treatments = getTreatments(state);

  const fiveMCategories = Object.keys(FiveMNames);

  // Step 1 - find the max impact for each category
  return fiveMCategories.map((category) => {
    const best = treatments
      .filter((treatment) => treatment.category === category)
      .reduce<TreatmentScore>(
        (acc: TreatmentScore, treatment: Treatment) => {
          const score = treatment.impact[ageBandIndex][condition];
          if (acc.score > score) {
            return acc;
          }
          return {
            best: treatment,
            score,
          };
        },
        { best: null, score: 0 }
      );

    return best.best;
  }) as Treatment[];
};

export const getMaxImpact = (
  state: SimulatorPartialState,
  ageBandIndex: number,
  condition: ConditionsOrHealthy
): number => {
  const treatments = getTreatments(state);

  const fiveMCategories = Object.keys(FiveMNames);

  // Step 1 - find the max impact for each category
  const maxImpactPerCategory: number[] = fiveMCategories.map((category) => {
    return treatments
      .filter((treatment) => treatment.category === category)
      .reduce((max: number, treatment: Treatment) => {
        const v = isNaN(treatment.impact[ageBandIndex][condition])
          ? 0
          : treatment.impact[ageBandIndex][condition];
        return Math.max(v, max);
      }, 0);
  });

  // Step 2 - Sum them up
  return maxImpactPerCategory.reduce((sum: number, v: number) => {
    return sum + v;
  }, 0);
};

export const getYourImpact = (
  state: SimulatorPartialState,
  ageBandIndex: number,
  condition: ConditionsOrHealthy
): number => {
  const treatments = getCurrentTreatmentOptions(state, condition, ageBandIndex);
  const impacts = treatments.map((t) => t.impact[ageBandIndex][condition]);
  return impacts.reduce((s, v) => s + v, 0);
};

export const getImpactRatio = (
  state: SimulatorPartialState,
  ageBandIndex: number,
  condition: ConditionsOrHealthy
) =>
  getYourImpact(state, ageBandIndex, condition) /
  getMaxImpact(state, ageBandIndex, condition);

export const getGameState = (state: SimulatorPartialState): GameState =>
  state.simulator.gameState;

export const getShowIdeal = (state: RootState): boolean => state.game.showIdeal;

export const getDialogData = (
  state: RootState
): Record<string, DialogDataTypes> => state.game.dialogData;

export const isSimDirty = (state: RootState): boolean =>
  state.game.simulationDataDirty;

export const getTags = (state: RootState): Record<string, string> =>
  state.simulator.tags;

export const getSimTags = (state: RootState): SimTagDefinition[] =>
  state.simulator.simTags;
