/** Functions for dealing with a single sim */

import { ConditionsOrHealthy, SimAgeBand, SimYear } from "./simulation-types";

// Returns the age band according to ageBandIndex, or if that doesn't exist, the last age band that does
export const lastAgeBand = (ageBandIndex: number, bands: SimAgeBand[]) =>
  bands[ageBandIndex] || bands[bands.length - 1];

export const getConditionSeverity = (
  ageBand: SimAgeBand,
  condition: ConditionsOrHealthy
): number => {
  const c = ageBand?.activeConditions.find((c) => c.condition === condition);
  return c?.level || 0;
};

export const getConditionFromYear = (
  year: SimYear,
  condition: ConditionsOrHealthy
): number => {
  return (
    year.activeConditions.find((c) => c.condition === condition)?.level || 0
  );
};
