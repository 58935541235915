import styled from "@emotion/styled";
import React from "react";

const Label = styled.label`
  color: #fff;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const slideUpAnimation = `
  @keyframes slideUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const Banner = styled.div`
  ${slideUpAnimation}
  background-color: #d34343;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  height: 40px;
  position: relative;
  animation: slideUp 0.3s ease-out;
`;

const PointerTriangle = styled.div`
  background-color: #d34343;
  height: 20px;
  position: absolute;
  width: 20px;
  clippath: polygon(0 0, 100% 0, 0 100%);
  bottom: -8px;
  right: 20px;
  z-index: 1;
  left: 150px;
  transform: rotate(225deg);
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  outline: none;
  padding: 0;
`;

type GenFunc = () => void;

export const EMRBanner: React.FC<{ caption: string; onClose: GenFunc }> = ({
  caption,
  onClose,
}) => {
  return (
    <Banner>
      <Label>{caption}</Label>
      <CloseButton onClick={onClose}>×</CloseButton>
      <PointerTriangle />
    </Banner>
  );
};
