import React from "react";
import { Sim } from "../simulation/simulation-types";
import { getConditionSeverity } from "../simulation/sim";
import { useSelector } from "react-redux";
import { getTagLabelMap } from "../selectors";

export const DebugSimTags: React.FC<{
  sims: Sim[];
  ageBandIndex: number;
}> = ({ sims, ageBandIndex }) => {
  const labelMap = useSelector(getTagLabelMap);
  return (
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          <th></th>
          <th>BaU Frailty</th>
          <th>BaU Tags</th>
          <th>User Frailty</th>
          <th>User Tags</th>
        </tr>
      </thead>
      <tbody>
        {sims.map((sim, index) => {
          return (
            <tr key={index}>
              <td>Sim #{index}</td>
              <td>
                {getConditionSeverity(sim.bauAgeBands[ageBandIndex], "frail")}
              </td>
              <td>
                {sim.bauAgeBands[ageBandIndex].tags
                  .map(
                    (tag) =>
                      labelMap[`${tag.name}/${tag.level}`]?.label ||
                      `${tag.name}/${tag.level}`
                  )
                  .join(", ")}
              </td>
              <td>
                {getConditionSeverity(sim.ageBands[ageBandIndex], "frail")}
              </td>
              <td>
                {sim.ageBands[ageBandIndex].tags
                  .map(
                    (tag) =>
                      labelMap[`${tag.name}/${tag.level}`]?.label ||
                      `${tag.name}/${tag.level}`
                  )
                  .join(", ")}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
