import { atom } from "recoil";

export const npcNameAtom = atom<string | null>({
  key: "npcName",
  default: null,
});
export const npcTitleAtom = atom<string | null>({
  key: "npcTitle",
  default: null,
});
