import * as React from "react"

function CheckIcon(props: any) {
    return (
        <svg
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1526_9526)">
                <path
                    d="M16 32a16 16 0 100-32 16 16 0 000 32zm7.063-18.938l-8 8a1.494 1.494 0 01-2.12 0l-4-4a1.5 1.5 0 012.119-2.119L14 17.882l6.938-6.944a1.5 1.5 0 012.119 2.119l.005.007z"
                    fill="#4DA100"
                />
            </g>
            <defs>
                <clipPath id="clip0_1526_9526">
                    <path fill="#fff" d="M0 0H32V32H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CheckIcon
