import React, { useMemo } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AgeBands, Sim } from "../simulation/simulation-types";

// {
//     idealFourM
//     idealCondition
// }

const calculateData = (sim: Sim) => {
  // Per age band:
  return sim.idealAgeBands

    .map((ageBand, index) => ({
      age: AgeBands[index - 1],
      cumulativeIdealFourM: ageBand?.fourMCost || 0,
      cumulativeIdealTreatment: ageBand?.treatmentCost || 0,
      cumulativeUserFourM: sim.ageBands[index]?.fourMCost || 0,
      cumulativeUserTreatment: sim.ageBands[index]?.treatmentCost || 0,
      cumulativeBauFourM: 0,
      cumulativeBauTreatment: sim.bauAgeBands[index]?.treatmentCost || 0,

      idealFourM: sim.idealAgeBands[index]?.fourMCost
        ? (sim.idealAgeBands[index]?.fourMCost || 0) -
            sim.idealAgeBands[index - 1]?.fourMCost || 0
        : 0,
      idealTreatment: sim.idealAgeBands[index]?.treatmentCost
        ? (sim.idealAgeBands[index]?.treatmentCost || 0) -
          (sim.idealAgeBands[index - 1]?.treatmentCost || 0)
        : 0,
      userFourM: sim.ageBands[index]?.fourMCost
        ? (sim.ageBands[index]?.fourMCost || 0) -
          (sim.ageBands[index - 1]?.fourMCost || 0)
        : 0,
      userTreatment: sim.ageBands[index]?.treatmentCost
        ? (sim.ageBands[index]?.treatmentCost || 0) -
          (sim.ageBands[index - 1]?.treatmentCost || 0)
        : 0,
      bauFourM: 0,
      bauTreatment: sim.bauAgeBands[index]?.treatmentCost
        ? (sim.bauAgeBands[index]?.treatmentCost || 0) -
          (sim.bauAgeBands[index - 1]?.treatmentCost || 0)
        : 0,
    }))
    .filter((_, i) => i > 0);

  // Per year:
  //   return Array(30)
  //     .fill(0)
  //     .map((_, year) => ({
  //       age: year + 65,
  //       idealFourM: sim.idealYears[year]?.fourMCost || 0,
  //       idealTreatment: sim.idealYears[year]?.treatmentCost || 0,
  //       userFourM: sim.userYears[year]?.fourMCost || 0,
  //       userTreatment: sim.userYears[year]?.treatmentCost || 0,
  //       bauFourM: 0,
  //       bauTreatment: sim.bauYears[year]?.treatmentCost || 0,
  //     }));
};
/**
 *  Annual bar chart of costs with stacked bars for 4m + treatment cost
 */
export const DebugSimCost: React.FC<{ sim: Sim }> = ({ sim }) => {
  const data = useMemo(() => calculateData(sim), [sim]);

  return (
    <div>
      <h2>Cost</h2>

      <BarChart width={500} height={250} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="age" />
        <YAxis />
        <Legend />

        <Tooltip />
        {/* <Bar stackId="c" fill="#144b7f" label="bauFourM" dataKey="bauFourM" /> */}
        <Bar
          stackId="c"
          fill="#85a1bb"
          label="bauTreatment"
          dataKey="bauTreatment"
        />
        <Bar stackId="b" fill="#772acb" label="userFourM" dataKey="userFourM" />
        <Bar
          stackId="b"
          fill="#be9de2"
          label="userTreatment"
          dataKey="userTreatment"
        />

        {/* <Bar
          stackId="a"
          fill="#1866b4"
          label="idealFourM"
          dataKey="idealFourM"
        />
        <Bar
          stackId="a"
          fill="#93bfeb"
          label="idealTreatment"
          dataKey="idealTreatment"
        /> */}
      </BarChart>
    </div>
  );
};
