import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import React from "react";

import { DebugCostChart } from "./DebugCostChart";
import { Conditions, Sim } from "../simulation/simulation-types";
import { Aside } from "./Aside";
import { DebugPopulationCost } from "./DebugPopulationCost";
import { DebugSavingsChart } from "./DebugSavingsChart";

export const DebugCostTabs: React.FC<{
  condition: Conditions;
  sims: Sim[];
  ageBandIndex: number;
  showIdeal: boolean;
  appleToAppleSims: Sim[];
}> = ({ condition, sims, ageBandIndex, appleToAppleSims, showIdeal }) => {
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={2}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="first">Overall Cost</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="savings">Savings</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="second">Apples to Apples Cost</Nav.Link>
            </Nav.Item>
            {showIdeal && (
              <Nav.Item>
                <Nav.Link eventKey="third">Population Cost</Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </Col>

        <Col sm={10}>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <h2>Overall Cost</h2>

              <DebugCostChart
                ageBandIndex={ageBandIndex || 0}
                condition={condition}
                sims={sims}
              />
              <Aside>
                <h4>Mortality Note</h4>

                <p>
                  Because some patients live longer under 4M scenarios, the
                  total cost comparison between BaU and 4M scnearios can be
                  misleading.
                </p>
              </Aside>
            </Tab.Pane>

            <Tab.Pane eventKey="savings">
              <h2>Savings</h2>

              <DebugSavingsChart
                ageBandIndex={ageBandIndex || 0}
                condition={condition}
                sims={sims}
              />
              <Aside>
                <h4>Revenue Note</h4>

                <p>
                  Revenue is assuming an accountable care org and will tend to
                  be higher under 4M scenarios since people live longer.
                </p>
              </Aside>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <h2>Apples to Apples Cost</h2>

              <DebugCostChart
                ageBandIndex={ageBandIndex || 0}
                condition={condition}
                sims={appleToAppleSims}
              />
              <Aside>
                <h4>What is this?</h4>

                <p>
                  Only compares patients that have the same life span in
                  Business as Usual and our Four-M simulation. This is useful
                  for comparing costs without taking mortality differences into
                  account. This data consists of{" "}
                  <b>{appleToAppleSims.length}</b> simulated patients out of our{" "}
                  {sims.length} panel.
                </p>
              </Aside>
            </Tab.Pane>

            <Tab.Pane eventKey="third">
              <DebugPopulationCost />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};
