import React from "react";
import profilePic from "./profile-pic.png";
import cakePic from "./cake.png";
import {
  AGE_BAND_1,
  ALL_MS,
  CATEGORY_HEALTHY,
  CATEGORY_HYPERTENSIVE,
  getTreatments,
  showAgeBand,
  Treatment,
} from "./newData";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import FeatherIcon from "feather-icons-react";
import { adjust, clamp, is, sum } from "ramda";

function setLevelImpact(level) {
  return {
    ...level,
    healthyImpact: calculateImpact(
      level.visitId,
      level.ageBand,
      level,
      CATEGORY_HEALTHY
    ),
    hypertensiveImpact: calculateImpact(
      level.visitId,
      level.ageBand,
      level,
      CATEGORY_HYPERTENSIVE
    ),
  };
}

export const VisitScreen = () => {
  const [state, setState] = useState({
    currentLevel: 0,
    treatmentsLoader: { loaded: false, loading: false },
    levels: [AGE_BAND_1, AGE_BAND_1, AGE_BAND_1].map((ageBand, i) => ({
      id: i,
      visitId: i % 3,
      ageBand,
      healthyImpact: null,
      hypertensiveImpact: null,
      ageRange: showAgeBand(ageBand),
      assignedTreatments: {
        [CATEGORY_HEALTHY]: [],
        [CATEGORY_HYPERTENSIVE]: [],
      },
    })),
  });

  useEffect(() => {
    if (!state.treatmentsLoader.loading && !state.treatmentsLoader.loaded) {
      setState({
        ...state,
        treatmentsLoader: {
          loading: true,
        },
      });

      getTreatments().then((treatments) => {
        setState({
          ...state,
          treatmentsLoader: {
            loaded: true,
            treatments,
          },
        });
      });
    }
  }, [state, setState]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex pa4">
        <div className="flex flex-column pr5 w-60">
          <Title />
          <div className="flex">
            <ProfileCard />
            <WelcomePanel />
          </div>
          <Levels
            levels={state.levels}
            currentLevel={state.currentLevel}
            nextLevel={() => {
              console.log(state.levels);
              setState({
                ...state,
                levels: adjust(
                  state.currentLevel,
                  setLevelImpact,
                  state.levels
                ),
                currentLevel: state.currentLevel + 1,
              });
            }}
            mutateLevel={(levelId, mutator) => {
              const levels = state.levels.map((level) => {
                return level.id === levelId ? mutator(level) : level;
              });
              setState({ ...state, levels });
            }}
          />
        </div>

        <div className="flex flex-column w-40">
          <div className="pb4">
            <strong className="underline f4">
              4Ms Assessments Selection Tool
            </strong>
          </div>
          {!state.treatmentsLoader.loaded ? (
            <div>Loading treatments...</div>
          ) : (
            <InterventionPicker
              treatments={state.treatmentsLoader.treatments}
            />
          )}
          <StatsAndFeedback state={state} />
        </div>
      </div>
    </DndProvider>
  );
};

const healthyCohortSize = 4000;
const hypertensiveCohortSize = 66000;

function sumCosts(category) {
  return sum(category.map((treatment) => treatment.cost));
}

function sumImpacts(visitId, ageBand, category, categoryId) {
  return sum(
    category.map((treatment) => {
      return treatment.getImpactFactor(visitId, ageBand, categoryId);
    })
  );
}

function hasSameElements(a, b) {
  if (a.length !== b.length) return false;
  return a.every((an) => b.includes(an)) && b.every((bn) => a.includes(bn));
}

function hasAllFourMs(treatments) {
  const ms = deduplicate(treatments.map((it) => it.m));
  return hasSameElements(ms, ALL_MS);
}

function calculateImpact(visitId, ageBand, level, category) {
  let impact = sumImpacts(
    visitId,
    ageBand,
    level.assignedTreatments[category],
    category
  );
  if (hasAllFourMs(level.assignedTreatments[category])) {
    impact += 3;
  }
  return impact;
}

const budget = 12_000_000;
const maxImpact = 45;

function StatsAndFeedback({ state, currentRound: ageBand = AGE_BAND_1 }) {
  const levelBarData = state.levels.map((level) => {
    const cost =
      sumCosts(level.assignedTreatments[CATEGORY_HEALTHY]) * healthyCohortSize +
      sumCosts(level.assignedTreatments[CATEGORY_HYPERTENSIVE]) *
        hypertensiveCohortSize;
    const spentFraction = cost / budget;

    const healthyImpact = level.healthyImpact || 0;
    const hypertensiveImpact = level.hypertensiveImpact || 0;
    return {
      spentFraction,
      healthyImpactFraction: clamp(0, 1, healthyImpact / maxImpact),
      hypertensiveImpactFraction: clamp(0, 1, hypertensiveImpact / maxImpact),
    };
  });
  console.group("Stats");
  console.log(
    `spent percent`,
    levelBarData.map((it) => `${(it.spentFraction * 100).toFixed(0)}%`)
  );

  console.log(
    `healthy impact`,
    state.levels.map((level) =>
      calculateImpact(level.visitId, level.ageBand, level, CATEGORY_HEALTHY)
    )
  );
  console.log(
    `hypertensive impact`,
    state.levels.map((level) =>
      calculateImpact(
        level.visitId,
        level.ageBand,
        level,
        CATEGORY_HYPERTENSIVE
      )
    )
  );
  console.groupEnd();

  const msgs = [];
  if (state.currentLevel > 0) {
    const previousLevel = state.levels[state.currentLevel - 1];

    const all4health = hasAllFourMs(
      previousLevel.assignedTreatments[CATEGORY_HEALTHY]
    );
    const all4hypertensive = hasAllFourMs(
      previousLevel.assignedTreatments[CATEGORY_HYPERTENSIVE]
    );

    if (previousLevel.visitId === 2) {
      const metHealthyImpactThreshold =
        sum(levelBarData.map((it) => it.healthyImpactFraction)) > 0.8;
      const metHypertensiveImpactThreshold =
        sum(levelBarData.map((it) => it.hypertensiveImpactFraction)) > 0.8;
      const underBudget = sum(levelBarData.map((it) => it.spentFraction)) < 1;
      if (!metHypertensiveImpactThreshold && !metHealthyImpactThreshold) {
        msgs.push(
          "Your choices did not meet your impact goals for either group. Try again."
        );
      }

      if (metHealthyImpactThreshold && !metHypertensiveImpactThreshold) {
        msgs.push(
          "You prevented a significant number of healthy patients from becoming hypertensive by their mid 70s, thus reducing disease prevalence and costs in the next age band. However, your choices did not make enough difference on mortality and quality of life for your hypertensive patients."
        );
      }

      if (metHypertensiveImpactThreshold && !metHealthyImpactThreshold) {
        msgs.push(
          "You increased the quality of life and reduced mortality for your hypertensive patients, but there are too many healthy people developing hypertension by the next age band."
        );
      }

      if (metHypertensiveImpactThreshold && metHealthyImpactThreshold) {
        msgs.push(
          "Great work! Based on your choices, by the next age band you will have kept more people healthy, reduced the prevalence of HTN and overall cost, reduced mortality, and increased quality of life!"
        );

        if (underBudget) {
          msgs.push("And you kept within budget.");
        } else {
          msgs.push(
            "But you went over budget. There are less expensive options that are at least, if not more, effective. Still, great outcomes and the long-term cost savings will easily pay for the choices you made."
          );
        }
      }
    } else {
      if (all4health && !all4hypertensive) {
        msgs.push(
          "Great job applying the 4Ms as a framework to the Healthy group!"
        );
      }
      if (!all4health && all4hypertensive) {
        msgs.push(
          "Great job applying the 4Ms as a framework to the Hypertensive group!"
        );
      }
      if (all4health && all4hypertensive) {
        msgs.push(
          "Your use of the 4Ms as a framework for both Healthy and Hypertensive has maximized the impact. Good work!"
        );
      }
      if (!all4health && !all4hypertensive) {
        msgs.push(
          "Remember, the 4Ms are designed to be used as a framework, with one from each category applied during each visit. Neither the Healthy nor the Hypertensive groups benefited enough from your choices."
        );
      }
    }
  }
  return (
    <div>
      <h1 className="underline f3">Stats & Feedback</h1>
      <div>
        {msgs.map((msg) => (
          <b>{msg}</b>
        ))}
      </div>

      <div className="relative mt4">
        <div>
          <div className="mb3">
            <div className="f5 underline mb3">
              Cost of Implementing the 4M Assessments
            </div>
            <div className="f6 i">
              As you apply 4M assessments to your panel of patient, the costs of
              doing so will be reflected here. Note that as you achieve a
              positive impact on patient outcomes, your overall cost savings
              will far exceed the costs of the 4Ms.{" "}
            </div>
          </div>
          <Bar
            color={["#BA6025", "#c37d1c", "#cbb50b"]}
            value={levelBarData.map((it) => it.spentFraction)}
            backgroundColor="white"
            height="24px"
            label={`${(
              sum(levelBarData.map((it) => it.spentFraction)) * 100
            ).toFixed(0)}% Spent`}
          />
        </div>

        <div className="mt5">
          <div className="mb3">
            <div className="f5 underline mb3">
              Impact of 4M assessments on Healthy Patients
            </div>
            <div className="f6 i">
              The impact of the 4M assessments you apply to healthy patients
              will keep some of them from becoming hypertensive, thus reducing
              HTN prevalence associated cost to the health care system, while
              increasing quality of life scores.
            </div>
          </div>
          <Bar
            color={["#B7CA39", "#9cca39", "#6cca39"]}
            value={levelBarData.map((it) => it.healthyImpactFraction)}
            backgroundColor="white"
            height="24px"
            label={`${(
              sum(levelBarData.map((it) => it.healthyImpactFraction)) * 100
            ).toFixed(0)}% Improvement`}
          />
        </div>

        <div className="mt5">
          <div className="mb3">
            <div className="f5 underline mb3">
              Impact of 4M assessments on Hypertensive Patients
            </div>
            <div className="f6 i">
              The impact of the 4M assessments you apply to hypertensive
              patients will decrease disease progression and mortality, thus
              increasing the quality of life scores and the size of the panel in
              the next age band.
            </div>
          </div>
          <Bar
            color={["#B7CA39", "#9cca39", "#6cca39"]}
            value={levelBarData.map((it) => it.hypertensiveImpactFraction)}
            backgroundColor="white"
            height="24px"
            label={`${(
              sum(levelBarData.map((it) => it.hypertensiveImpactFraction)) * 100
            ).toFixed(0)}% Improvement`}
          />
        </div>
      </div>
      {state.currentLevel >= 3 ? (
        <button
          className="bn ph4 pv3 bg-light-red br3 mt4 white f3 pointer"
          onClick={() => window.location.reload(false)}
        >
          Restart
        </button>
      ) : null}
    </div>
  );
}

function Bar({ color, backgroundColor, value, label, height }) {
  if (is(Number, value)) {
    let fraction = (value * 100).toFixed(3);
    if (fraction > 100) fraction = 100;
    if (fraction < 0) fraction = 0;

    return (
      <div className="relative w-100 br3" style={{ height, backgroundColor }}>
        <div
          className="br3"
          style={{
            backgroundColor: color,
            height: "100%",
            width: `${fraction}%`,
          }}
        />
        <div
          className="absolute b"
          style={{ transform: "translate(50%, 50%)", right: "50%" }}
        >
          {label}
        </div>
      </div>
    );
  }

  if (is(Array, value)) {
    const positiveValues = value.filter((x) => x > 0);
    if (!is(Array, color)) throw new Error("Expected color array");
    return (
      <div
        className="relative w-100 br3 flex"
        style={{ height, backgroundColor }}
      >
        {positiveValues.map((v, i) => (
          <div
            key={i * v}
            style={{
              backgroundColor: color[i],
              height: "100%",
              width: `${clamp(0, 100, v * 100)}%`,
              borderTopRightRadius:
                i === positiveValues.length - 1 ? ".5rem" : "0",
              borderBottomRightRadius:
                i === positiveValues.length - 1 ? ".5rem" : "0",
              borderTopLeftRadius: i === 0 ? ".5rem" : "0",
              borderBottomLeftRadius: i === 0 ? ".5rem" : "0",
            }}
          />
        ))}
        <div
          className="absolute b"
          style={{
            right: 0,
            left: 0,
            textAlign: "center",
            top: "32px",
          }}
        >
          {label}
        </div>
      </div>
    );
  }
}

function Title() {
  return (
    <div>
      <div className="pb2">
        <strong className="mr3 f4">GeriPop (v 0.2)</strong>
        <i>
          A Game to showcase the value of the 4Ms Framework for the care of
          Geriatrics
        </i>
      </div>
      <div className="pb4 b">Age Band One: 65–74 Year Olds</div>
    </div>
  );
}

function ProfileCard() {
  return (
    <div
      className="bg-bluee pa2 white"
      style={{
        width: "270px",
        height: "178px",
      }}
    >
      <div className="flex justify-between bb-l b--white mb1 pb1">
        <div className="flex flex-column lh-copy-l">
          <div className="fw5">Elliot Fu</div>
          <div className="fw2 f6">Physician</div>
          <div className="fw3 f7">Elliot is a new user.</div>
        </div>
        <div className="ml3">
          <img style={{ width: "64px" }} src={profilePic} alt="" />
        </div>
      </div>
      <div className="flex justify-between">
        <div
          style={{}}
          className="flex flex-column justify-around items-center ml2"
        >
          <div className="f2 fw6">1</div>
          <div className="ttu f5 fw5">Level</div>
        </div>
        <div className="flex flex-column justify-around items-center mr2">
          <div>
            <img alt="cake" src={cakePic} />
          </div>
          <div className="ttu f5 fw5">New User</div>
        </div>
      </div>
    </div>
  );
}

function WelcomePanel() {
  return (
    <div className="ml4">
      <h1>Welcome Elliot!</h1>
      <button className="bg-black white bn br2 pa2 ph3">Log Out</button>
      <div className="mt3">
        <div>
          <b>Your goal is to apply 4Ms Assessments to the two groups below.</b>
        </div>
        <div>Drag from the 4Ms tabs on the right to the groupings below.</div>
      </div>
    </div>
  );
}

function InterventionPicker({ treatments }) {
  const tabs = useMemo(() => {
    const tabs = [];
    ALL_MS.forEach((m) => {
      tabs.push(m);
    });
    return tabs;
  }, []);

  const [selectedTab, setSelectedTab] = useState(() => tabs[0]);

  return (
    <div>
      <div className="flex">
        <div style={{ minWidth: "130px" }}>
          {tabs.map((tabName) => {
            const isSelected = selectedTab === tabName;
            return (
              <div
                key={tabName}
                style={{
                  background: isSelected ? "white" : "transparent",
                  cursor: "pointer",
                }}
                className="pa2 f7"
                onClick={() => setSelectedTab(tabName)}
              >
                {tabName}
              </div>
            );
          })}
        </div>
        <div
          className="bg-bluee white pa3 overflow-auto"
          style={{ maxHeight: "500px" }}
        >
          <div className="i fw5 mb4">
            Tab to a 4M Group. Then select a 4M from below and drag to available
            Healthy or HTN slots on the left.
          </div>

          {treatments.getTreatmentsForM(selectedTab).map((treatment) => {
            return (
              <DraggableInterventionCard
                key={treatment.name}
                treatment={treatment}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function DraggableInterventionCard({ treatment }) {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "intervention",
      item: { treatment },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [treatment]
  );

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div
      style={{
        backgroundColor: "#5283C5",
        cursor: "pointer",
        opacity: opacity,
      }}
      ref={drag}
      className="mv1 pa2 f6 flex justify-between"
    >
      <div>{treatment.name}</div>
      <div>{treatment.getCostSymbol()}</div>
    </div>
  );
}

function Levels({ levels, mutateLevel, nextLevel, currentLevel }) {
  return (
    <div>
      {levels.map((level) => (
        <LevelDropZones
          key={level.id}
          level={level}
          currentLevel={currentLevel}
          mutateLevel={mutateLevel}
          nextLevel={nextLevel}
        />
      ))}
    </div>
  );
}

function deduplicate(list, by) {
  if (!by) by = (x) => x;
  const l = [];
  const t = {};
  list.forEach((item) => {
    if (!t[by(item)]) {
      t[by(item)] = 1;
      l.push(item);
    }
  });
  return l;
}

function LevelDropZones({ level, currentLevel, mutateLevel, nextLevel }) {
  const addTreatment = useCallback(
    (category, treatment) => {
      mutateLevel(level.id, (l) => {
        const assignedTreatments = {
          [CATEGORY_HEALTHY]: [...l.assignedTreatments[CATEGORY_HEALTHY]],
          [CATEGORY_HYPERTENSIVE]: [
            ...l.assignedTreatments[CATEGORY_HYPERTENSIVE],
          ],
        };
        if (assignedTreatments[category].length >= 4) return l;
        assignedTreatments[category].push(treatment);
        assignedTreatments[category] = deduplicate(
          assignedTreatments[category],
          (x) => x.name
        );
        return {
          ...l,
          assignedTreatments,
        };
      });
    },
    [mutateLevel, level]
  );

  const removeTreatment = useCallback(
    (category, treatment) => {
      mutateLevel(level.id, (l) => {
        const assignedTreatments = {
          [CATEGORY_HEALTHY]: [...l.assignedTreatments[CATEGORY_HEALTHY]],
          [CATEGORY_HYPERTENSIVE]: [
            ...l.assignedTreatments[CATEGORY_HYPERTENSIVE],
          ],
        };

        assignedTreatments[category] = assignedTreatments[category].filter(
          (x) => x.name !== treatment.name
        );
        return { ...l, assignedTreatments };
      });
    },
    [level, mutateLevel]
  );
  const isActiveLevel = currentLevel === level.id;
  return (
    <div
      style={{
        opacity: isActiveLevel ? 1 : 0.3,
        pointerEvents: isActiveLevel ? "inherit" : "none",
      }}
    >
      <h1 className="fw4 f3 underline mb2 mt4">Visit {level.id + 1}</h1>
      <div className="flex">
        <div className="w-50 pr3">
          <DropCategory
            isActive={isActiveLevel}
            name="Healthy"
            cohort={healthyCohortSize}
            removeItem={(t) => removeTreatment(CATEGORY_HEALTHY, t)}
            data={level.assignedTreatments[CATEGORY_HEALTHY]}
            onDrop={(t) => addTreatment(CATEGORY_HEALTHY, t.treatment)}
          />
        </div>
        <div className="w-50 pl3">
          <DropCategory
            isActive={isActiveLevel}
            name="Hypertension"
            cohort={hypertensiveCohortSize}
            removeItem={(t) => removeTreatment(CATEGORY_HYPERTENSIVE, t)}
            data={level.assignedTreatments[CATEGORY_HYPERTENSIVE]}
            onDrop={(t) => addTreatment(CATEGORY_HYPERTENSIVE, t.treatment)}
          />
        </div>
      </div>
      <button
        className="fr bn pv2 ph3 br2 mt2 bg-bluee white"
        onClick={nextLevel}
      >
        Submit
      </button>
    </div>
  );
}

function DropCategory({ name, data, cohort, removeItem, onDrop, isActive }) {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "intervention",
      drop: (item) => {
        if (isActive) onDrop(item);
        return { name: "Dustbin" };
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onDrop]
  );

  const isThreatened = canDrop && isOver && isActive;
  let outline = "none";
  if (isThreatened) {
    outline = "4px solid black";
  } else if (canDrop && isActive) {
    outline = "3px dashed black";
  }

  const items = ["Choice A", "Choice B", "Choice C", "Choice D"];

  data.forEach((x, i) => {
    items[i] = x;
  });

  return (
    <div ref={drop}>
      <h1 className="f3">
        {name} <span className="fw1">({cohort.toLocaleString()})</span>
      </h1>
      <div
        className="bg-white shadow-3 br2 relative"
        style={{
          outline,
        }}
      >
        {items.map((item) => {
          const name = is(String, item) ? item : item.name;
          return (
            <DropBucket
              key={name}
              name={name}
              treatment={item}
              isFilled={data.includes(item)}
              clearSelection={() => removeItem(item)}
            />
          );
        })}
        {isThreatened && (
          <div
            className="absolute absolute--fill"
            style={{
              pointerEvents: "none",
              backgroundColor: "green",
              opacity: 0.1,
            }}
          />
        )}
      </div>
    </div>
  );
}

function DropBucket({ name, isFilled, clearSelection, treatment }) {
  const treatmentCost = is(Treatment, treatment) && treatment.getCostSymbol();
  return (
    <div
      className="drop-slot f5 flex"
      style={{
        backgroundColor: isFilled ? "#5181C2" : "inherit",
        color: isFilled ? "white" : "inherit",
        fontWeight: isFilled ? "700" : "100",
        fontStyle: isFilled ? "normal" : "italic",
      }}
    >
      {name}
      {treatment && <div className="ml4">{treatmentCost}</div>}
      {isFilled ? (
        <div onClick={clearSelection} className="ml-auto mr3 pointer">
          <FeatherIcon icon="trash" size={15} />
        </div>
      ) : null}
    </div>
  );
}

export default VisitScreen;
