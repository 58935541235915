export const StopwatchIcon = () => (
  <svg
    width="25"
    height="29"
    viewBox="0 0 25 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1275_5323)">
      <path
        d="M7 1.375C7 0.89375 7.39375 0.5 7.875 0.5H16.625C17.1063 0.5 17.5 0.89375 17.5 1.375C17.5 1.85625 17.1063 2.25 16.625 2.25H13.125V5.78281C15.8266 5.99062 18.2656 7.13906 20.1086 8.9L22.132 6.87656C22.4711 6.5375 23.0289 6.5375 23.368 6.87656C23.707 7.21562 23.707 7.77344 23.368 8.1125L21.2789 10.2016C22.75 12.1266 23.625 14.5219 23.625 17.125C23.625 23.4086 18.5336 28.5 12.25 28.5C5.96641 28.5 0.875 23.4086 0.875 17.125C0.875 11.1367 5.50156 6.23125 11.375 5.78281V2.25H7.875C7.39375 2.25 7 1.85625 7 1.375ZM2.625 17.125C2.625 19.6777 3.63906 22.1259 5.4441 23.9309C7.24914 25.7359 9.69729 26.75 12.25 26.75C14.8027 26.75 17.2509 25.7359 19.0559 23.9309C20.8609 22.1259 21.875 19.6777 21.875 17.125C21.875 14.5723 20.8609 12.1241 19.0559 10.3191C17.2509 8.51406 14.8027 7.5 12.25 7.5C9.69729 7.5 7.24914 8.51406 5.4441 10.3191C3.63906 12.1241 2.625 14.5723 2.625 17.125ZM13.125 11.875V18C13.125 18.4813 12.7312 18.875 12.25 18.875C11.7688 18.875 11.375 18.4813 11.375 18V11.875C11.375 11.3938 11.7688 11 12.25 11C12.7312 11 13.125 11.3938 13.125 11.875Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1275_5323">
        <rect
          width="24.5"
          height="28"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
