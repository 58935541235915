import styled from "@emotion/styled";
import React from "react";
import { StopwatchIcon } from "./StopwatchIcon";

export const DialogTimeElapsed: React.FC<{ label: string }> = ({ label }) => {
  return (
    <ElapsedContainer>
      <StopwatchIcon />
      <TimeContainer>
        <ElapsedLabel>Time Elapsed</ElapsedLabel>
        <TimeLabel>{label}</TimeLabel>
      </TimeContainer>
    </ElapsedContainer>
  );
};

const TimeLabel = styled.div`
  color: #fff;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ElapsedLabel = styled.div`
  color: #fff;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ElapsedContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

declare global {
  interface Window {
    addInsight: (message: string, value: number) => void;
  }
}
