import React from "react";

export const SpeakerTail: React.FC = () => (
  <svg
    width="40"
    height="29"
    viewBox="0 0 40 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.698975 0C7.2614 15.8161 22.2117 27.2731 40 28.8213C33.813 20.8623 30.1289 10.8613 30.1289 0H0.698975Z"
      fill="#00171F"
      fillOpacity="0.5"
    />
  </svg>
);
