import Airtable from "airtable";

export class Impact {
  healthy;
  hypertensive;

  constructor(healthy, hypertensive) {
    this.healthy = healthy;
    this.hypertensive = hypertensive;
  }
}

export class AgeBandStats {
  impact;
  priority;

  constructor(impact, priority) {
    this.impact = impact;
    this.priority = priority;
  }
}

export const AGE_BAND_1 = "AGE_BAND_1";
export const AGE_BAND_2 = "AGE_BAND_2";
export const AGE_BAND_3 = "AGE_BAND_3";

export const AGE_BANDS = [AGE_BAND_1, AGE_BAND_2, AGE_BAND_3];

export function showAgeBand(ageBand) {
  switch (ageBand) {
    case AGE_BAND_1:
      return "65-74";
    case AGE_BAND_2:
      return "75-84";
    case AGE_BAND_3:
      return "85+";
    default:
      throw new Error(`unknown age band '${ageBand}'`);
  }
}

export const CATEGORY_HEALTHY = "healthy";
export const CATEGORY_HYPERTENSIVE = "hypertensive";

export const CATEGORIES = [CATEGORY_HEALTHY, CATEGORY_HYPERTENSIVE];

export class Treatment {
  m;
  name;
  cost;
  ageBand1Stats;
  ageBand2Stats;
  ageBand3Stats;

  constructor({
    m,
    name,
    cost,
    healthy1,
    htn1,
    healthy2,
    htn2,
    healthy3,
    htn3,
    phealthy1 = 0,
    phtn1 = 0,
    phealthy2 = 0,
    phtn2 = 0,
    phealthy3 = 0,
    phtn3 = 0,
  }) {
    this.m = m;
    this.name = name;
    this.cost = cost;
    this.ageBand1Stats = new AgeBandStats(
      new Impact(healthy1, htn1),
      new Impact(phealthy1, phtn1)
    );
    this.ageBand2Stats = new AgeBandStats(
      new Impact(healthy2, htn2),
      new Impact(phealthy2, phtn2)
    );
    this.ageBand3Stats = new AgeBandStats(
      new Impact(healthy3, htn3),
      new Impact(phealthy3, phtn3)
    );
  }

  clone() {
    console.log("clone it");
    return new Treatment({
      m: this.m,
      name: this.name,
      cost: this.cost,
      healthy1: this.ageBand1Stats.impact.healthy,
      htn1: this.ageBand1Stats.impact.hypertensive,
      healthy2: this.ageBand2Stats.impact.healthy,
      htn2: this.ageBand2Stats.impact.hypertensive,
      healthy3: this.ageBand3Stats.impact.healthy,
      htn3: this.ageBand3Stats.impact.hypertensive,
      phealthy1: this.ageBand1Stats.priority.healthy,
      phtn1: this.ageBand1Stats.priority.hypertensive,
      phealthy2: this.ageBand2Stats.priority.healthy,
      phtn2: this.ageBand2Stats.priority.hypertensive,
      phealthy3: this.ageBand3Stats.priority.healthy,
      phtn3: this.ageBand3Stats.priority.hypertensive,
    });
  }

  getImpact(ageBand, category) {
    console.assert(AGE_BANDS.includes(ageBand));
    console.assert(CATEGORIES.includes(category));
    return this.getStats(ageBand).impact[category];
  }

  getPriority(ageBand, category) {
    console.assert(AGE_BANDS.includes(ageBand));
    console.assert(CATEGORIES.includes(category));
    return this.getStats(ageBand).priority[category];
  }

  getImpactFactor(visitId, ageBand, category) {
    console.assert(AGE_BANDS.includes(ageBand), ageBand);
    console.assert(CATEGORIES.includes(category), category);
    const impactMap = {
      0: {
        0: -2,
        1: 5,
        2: 4,
        3: 0,
      },
      1: {
        0: -1,
        1: 4,
        2: 3,
        3: 1,
      },
      2: {
        0: -1,
        1: 3,
        2: 2,
        3: 1,
      },
    };
    const n = this.getPriority(ageBand, category);
    return impactMap[visitId][n];
  }

  getStats(ageBand) {
    console.assert(AGE_BANDS.includes(ageBand));
    switch (ageBand) {
      case AGE_BAND_1:
        return this.ageBand1Stats;
      case AGE_BAND_2:
        return this.ageBand2Stats;
      case AGE_BAND_3:
        return this.ageBand3Stats;
      default:
        throw new Error(`Unknown age band '${ageBand}'`);
    }
  }

  getCostSymbol() {
    const costMap = {
      5: "$",
      10: "$$",
      20: "$$$",
      25: "$$$",
      35: "$$$$",
      50: "$$$$$",
    };

    let treatmentCost = costMap[this.cost];
    if (!treatmentCost) {
      let closest = Object.keys(costMap)
        .map((it) => Math.abs(it - this.cost))
        .sort()[0];
      console.log(this.name, "closest is", closest);
      treatmentCost = costMap[closest];
    }
    return treatmentCost;
  }
}

export const WHAT_MATTERS = "What Matters";
export const MOBILITY = "Mobility";
export const MENTATION = "Mentation";
export const MEDICATION = "Medication";

export const ALL_MS = [WHAT_MATTERS, MOBILITY, MENTATION, MEDICATION];

function loadTreatments(base) {
  return new Promise((resolve, reject) => {
    base("Treatment/Assessment")
      .select({ view: "Grid view" })
      .all((err, records) => {
        if (err) {
          console.error(err);
          // window.alert(
          //   "Error fetching data from Airtable! See console for details."
          // );
          reject();
          return;
        }
        // console.log(
        //   `fetched ${records.length} Treatment/Assessment rows`,
        //   records
        // );

        resolve(
          records.map(
            (record) =>
              new Treatment({
                m: record.get("5Ms"),
                name: record.get("Name"),
                cost: record.get("Associated Costs"),
                healthy1: record.get("65-74 Impact (Healthy)"),
                htn1: record.get("65-74 Impact (HTN)"),
                phealthy1: record.get("65-74 Priority (Healthy)"),
                phtn1: record.get("65-74 Priority (HTN)"),
              })
          )
        );
      });
  });
}

export async function getTreatments() {
  Airtable.configure({ apiKey: "keyUxhK8awACWuuYl" });
  const base = Airtable.base("app5mk7rppiK0lNOT");
  const treatments = await loadTreatments(base);
  return {
    getTreatmentsForM: function (m) {
      console.assert(ALL_MS.includes(m));
      return treatments.filter((t) => t.m === m);
    },

    getTreatmentByName: function (name) {
      return treatments.filter((t) => t.name === name)[0];
    },
  };
}
