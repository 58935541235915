import { airtableBase } from "./airtable";
import { SimTagLabel, SimTagLabelMap } from "./simulation/simulation-types";

export interface Tag {
  name: string;
  description: string;
}

const mapTagDescription = (record: any): Tag => ({
  name: record.get("Name"),
  description: record.get("Description"),
});

export const loadTagDescriptions = (): Promise<Tag[]> =>
  new Promise<Tag[]>((resolve, reject) => {
    airtableBase("Tag Descriptions")
      .select({ view: "Grid view" })
      .all((err, records) => {
        if (err || !records) {
          console.error(err);
          window.alert(
            "Error fetching data from Airtable! See console for details."
          );
          reject();
          return;
        }

        resolve(records.map(mapTagDescription));
      });
  });

const mapTagLabel = (record: any): SimTagLabel => ({
  name: record.get("Tag Name"),
  level: record.get("Level"),
  label: record.get("Label"),
  description: record.get("Description"),
});

export const loadTagLabels = (): Promise<SimTagLabelMap> =>
  new Promise<SimTagLabelMap>((resolve, reject) => {
    airtableBase("TagLabels")
      .select({ view: "Grid view" })
      .all((err, records) => {
        if (err || !records) {
          console.error(err);
          window.alert(
            "Error fetching data from Airtable! See console for details."
          );
          reject();
          return;
        }

        resolve(
          records
            .map(mapTagLabel)
            .reduce((acc: SimTagLabelMap, curr: SimTagLabel) => {
              acc[`${curr.name}/${curr.level}`] = curr;
              return acc;
            }, {})
        );
      });
  });
