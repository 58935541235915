// Generated from SVG to Code Figma Plugin
import React from "react";

export const IconDanger = () => (
  <svg
    height="16"
    width="16"
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.10952 5.09998C7.94444 5.09998 7.80806 5.15382 7.70038 5.2615C7.59219 5.36969 7.5381 5.50632 7.5381 5.6714V8.33807C7.5381 8.49045 7.59219 8.62379 7.70038 8.73807C7.80806 8.85236 7.94444 8.9095 8.10952 8.9095C8.2746 8.9095 8.41124 8.85236 8.51943 8.73807C8.62711 8.62379 8.68095 8.49045 8.68095 8.33807V5.6714C8.68095 5.50632 8.62711 5.36969 8.51943 5.2615C8.41124 5.15382 8.2746 5.09998 8.10952 5.09998Z"
      fill="#D34343"
    />
    <path
      d="M8.10952 10.8905C8.2873 10.8905 8.43333 10.8333 8.54762 10.719C8.6619 10.6047 8.71905 10.4587 8.71905 10.2809C8.71905 10.1032 8.6619 9.95712 8.54762 9.84283C8.43333 9.72855 8.2873 9.6714 8.10952 9.6714C7.93175 9.6714 7.78571 9.72855 7.67143 9.84283C7.55714 9.95712 7.5 10.1032 7.5 10.2809C7.5 10.4587 7.55714 10.6047 7.67143 10.719C7.78571 10.8333 7.93175 10.8905 8.10952 10.8905Z"
      fill="#D34343"
    />
    <path
      d="M6.29994 1.14288C5.76664 1.14288 5.25574 1.35509 4.87889 1.73194L1.73188 4.87895C1.35503 5.2558 1.14282 5.7667 1.14282 6.30001V9.70005C1.14282 10.2333 1.35503 10.7443 1.73188 11.1211L4.87889 14.2681C5.25574 14.645 5.76664 14.8572 6.29994 14.8572H9.69998C10.2333 14.8572 10.7442 14.645 11.121 14.2681L14.2681 11.1211C14.6449 10.7443 14.8571 10.2333 14.8571 9.70005V6.30001C14.8571 5.7667 14.6449 5.2558 14.2681 4.87895L11.121 1.73194C10.7442 1.35509 10.2333 1.14288 9.69998 1.14288H6.29994ZM5.63353 2.48658C5.81001 2.3101 6.05021 2.21113 6.29994 2.21113H9.69998C9.94972 2.21113 10.1899 2.3101 10.3664 2.48658L13.5134 5.63359C13.6899 5.81007 13.7889 6.05027 13.7889 6.30001V9.70005C13.7889 9.94979 13.6899 10.19 13.5134 10.3665L10.3664 13.5135C10.1899 13.6899 9.94972 13.7889 9.69998 13.7889H6.29994C6.05021 13.7889 5.81001 13.6899 5.63353 13.5135L2.48652 10.3665C2.31004 10.19 2.21107 9.94979 2.21107 9.70005V6.30001C2.21107 6.05027 2.31004 5.81007 2.48652 5.63359L5.63353 2.48658Z"
      fill="#D34343"
      fillRule="evenodd"
    />
  </svg>
);
