import React, { useEffect } from "react";
import "./firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export const Logout = () => {
  useEffect(() => {
    const auth = firebase.auth();
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    auth.onAuthStateChanged((user) => {
      if (user) {
        auth.signOut();
      }
    });
  }, []);

  return <div>Logged out</div>;
};
