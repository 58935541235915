import React, { useCallback, useEffect, useState } from "react";
import { Dialog } from "./Dialog";

import { useDialog } from "./useDialog";
import styled from "@emotion/styled";

import { DialogContext, initDialogContext } from "./DialogContext";
import { DialogLog } from "./DialogLog";
import { Slide } from "./Slide";
import { DialogPrompt } from "./DialogPrompt";
import { DialogEMR } from "./DialogEMR";
import { Assessment } from "./Assessment";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { InsightDisplay } from "./InsightDisplay";
import { NPCVideo } from "./NPCVideo";
import { PromptFrame } from "./PromptFrame";
import { useToggle } from "usehooks-ts";
import { DialogTranscript } from "./DialogTranscript";
import { useRecoilState } from "recoil";
import { npcNameAtom, npcTitleAtom } from "./dialog-state";
import { emrAtom, emrVisibleAtom } from "../state/emrAtom";
import { insightStateAtom } from "../state/insight";

const NPCTitle = styled.div`
  position: absolute;
  left: 549px;
  bottom: 40px;
  color: #fff;

  font-family: Noto Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.85);
`;
const NPCInfo = styled.div`
  position: absolute;
  left: 549px;
  bottom: 20px;
  color: #fff;

  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.85);
`;

const Fullscreen = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  alignItems: "center",
  zIndex: 1000,
});

const nurseIdleOverride = "/dialog_videos/nurse/nurse_idle.mp4";

export const DialogPlayer: React.FC<{
  dialog: Dialog;
  showLog?: boolean;
  onCompleted?: (context: DialogContext) => void;
  initialContext?: DialogContext;
  showPromptButtons: boolean;
  skipAllVoiceovers?: boolean;
  idleSrc: string;
  show4MEmrTab?: boolean;
}> = ({
  dialog,
  showLog,
  onCompleted,
  initialContext,
  skipAllVoiceovers,
  showPromptButtons,
  idleSrc,
  show4MEmrTab,
}) => {
  const [mode, setMode] = useState("cmo");

  const [npcName, setNpcName] = useRecoilState(npcNameAtom);
  const [npcTitle, setNpcTitle] = useRecoilState(npcTitleAtom);
  const [emr] = useRecoilState(emrVisibleAtom);

  const [insight] = useRecoilState(insightStateAtom);

  const onDialogComplete = useCallback(
    (context: DialogContext) => onCompleted && onCompleted(context),
    [onCompleted]
  );

  const [transcriptOpen, toggleTranscript] = useToggle(false);

  useEffect(() => {
    window.setMode = (mode: string) => {
      setMode(mode);
    };
    window.setNPCName = (name: string, title?: string) => {
      setNpcName(name);
      title && setNpcTitle(title);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    history,
    prompt,
    generalResponses,
    categoryResponses,
    npc,
    video,
    dialogCompleted,
    selectResponse,
    context,
    elapsedTime,
    promptOpen,
    addAssessmentTime,
    onVideoEnded,
    secondaryVideo,
    currentNodeId,
    goToNode,
  } = useDialog(
    dialog,
    initDialogContext(dialog, insight.score, 0),
    onDialogComplete,
    "/dialog_vo/ceo",
    !!skipAllVoiceovers
  );

  const effectiveIdleSrc =
    currentNodeId === "nurse" ? nurseIdleOverride : idleSrc;

  return (
    <Fullscreen>
      <Slide />

      <DialogEMR onClose={goToNode} show4MEmrTab={show4MEmrTab} />
      <NPCVideo
        key="primaryVideo"
        src={`/dialog_videos/${video}`}
        idleSrc={effectiveIdleSrc}
        thumbnail={
          !!emr || (!!prompt.action && prompt.action.includes("showSlide"))
        }
        autoPlay
        visible={!dialogCompleted}
        onEnded={onVideoEnded}
      />

      <NPCVideo
        key="secondaryVideo"
        src={`/dialog_videos/${secondaryVideo}`}
        idleSrc={effectiveIdleSrc}
        thumbnail={true}
        autoPlay
        visible={!!secondaryVideo && !dialogCompleted}
        onEnded={onVideoEnded}
      />

      <PromptFrame open={promptOpen}>
        {transcriptOpen || (
          <Assessment
            dialog={dialog}
            onResponse={selectResponse}
            prompt={prompt}
            responses={generalResponses}
            onAssessmentComplete={addAssessmentTime}
          >
            <DialogPrompt
              prompt={prompt}
              generalResponses={generalResponses}
              categoryResponses={categoryResponses}
              open={promptOpen}
              elapsedTime={elapsedTime}
              onResponse={selectResponse}
              showOptions={showPromptButtons && mode === "patient"}
              toggleTranscript={toggleTranscript}
            />
          </Assessment>
        )}

        {transcriptOpen && history && (
          <DialogTranscript
            history={history}
            toggleTranscript={toggleTranscript}
            currentPrompt={prompt.txt}
          />
        )}
      </PromptFrame>

      <NPCTitle>{npcName}</NPCTitle>
      <NPCInfo>{npcTitle}</NPCInfo>

      {showLog && history && <DialogLog history={history} context={context} />}

      <ToastContainer position="bottom-center" />
    </Fullscreen>
  );
};

DialogPlayer.defaultProps = {
  showLog: false,
};

declare global {
  interface Window {
    setMode: (mode: string) => void;
    setNPCName: (name: string, title?: string) => void;
  }
}
