import { getConstantFromSimulation } from "../selectors";
import { applyInitialSimulationTags } from "./simulation-tagging";
import {
  CalcState,
  conditions,
  GameState,
  Sim,
  SimAgeBand,
  Simulation,
} from "./simulation-types";
import { v4 } from "uuid";

const createEmptyAgeBand = (index: number): SimAgeBand => ({
  fourMCost: 0,
  treatmentCost: 0,
  qolScore: 0,
  conditionYears: {},
  activeConditions: [],
  alive: true,
  age: 65,
  receptivity: 0, // TODO, use the new refusniks tag. This used to be: [-1, 0, 0, 0, 1][index % 5], // 20%=-1 60%=0 20%=1
  tags: [],
});

// We want repeatable rolls for a given sim, so we're going to pre-generate
const createRolls = (): number[] =>
  Array(25)
    .fill(0)
    .map(() => Math.random());

const createEmptySim = (_: any, index: number): Sim => ({
  id: v4(),
  ageBands: [createEmptyAgeBand(index)],
  bauAgeBands: [createEmptyAgeBand(index)],
  idealAgeBands: [createEmptyAgeBand(index)],
  rolls: createRolls(),
  bauYears: [],
  idealYears: [],
  userYears: [],
});

export const createEmptySimulation = (simCount: number): Simulation => {
  return {
    progress: 0,
    calcState: CalcState.IDLE,
    id: v4(),
    round: 0,
    treatmentIdBoardSelections: [],
    sims: Array(simCount).fill(null).map(createEmptySim),
    currentAgeBand: -1,
    tags: {},
    tagLabels: {},
    treatmentSelections: {
      healthy: [[], [], []],
      frail: [[], [], []],
    },
    treatments: [],
    constants: [],
    simTags: [],
    constantsHash: {},
    gameState: GameState.NOT_INITIALIZED,
  };
};

export const initializeSims = (state: Simulation): Sim[] => {
  const targetCount =
    getConstantFromSimulation(state, "Initial Panel Size", "all")?.value || 0;

  // Create a completely healthy population
  const population: Sim[] = Array(targetCount).fill(1).map<Sim>(createEmptySim);

  conditions.forEach((condition) => {
    // Grab the % of the population in each level
    const levelPercents = [
      getConstantFromSimulation(state, "Initial Occurrence", condition, -1, 1)
        ?.value || 0,
      getConstantFromSimulation(state, "Initial Occurrence", condition, -1, 2)
        ?.value || 0,
      getConstantFromSimulation(state, "Initial Occurrence", condition, -1, 3)
        ?.value || 0,
      getConstantFromSimulation(state, "Initial Occurrence", condition, -1, 4)
        ?.value || 0,
    ];

    const levelBoundaries = levelPercents
      .map((p) => Math.min((targetCount * p) / 100)) // Convert from percents to counts
      .map((n, index, arr) => arr.slice(0, index + 1).reduce((a, b) => a + b)); // Convert from counts to array indexes

    const healthy = targetCount - levelBoundaries[levelBoundaries.length - 1];

    levelBoundaries.forEach((end, index, a) => {
      const start = a[index - 1] || 0;

      //console.info({ start, end, index });

      // App
      population.slice(healthy + start, healthy + end).forEach((sim) => {
        sim.ageBands[0].activeConditions.push({
          condition,
          level: index + 1,
        });
        sim.bauAgeBands[0].activeConditions.push({
          condition,
          level: index + 1,
        });
        sim.idealAgeBands[0].activeConditions.push({
          condition,
          level: index + 1,
        });
      });
    });
  });

  applyInitialSimulationTags(state.simTags, population, 0);

  return population;
};
