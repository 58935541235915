import React, { useCallback, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { AgeBandProp, Conditions, Sim } from "../simulation/simulation-types";
import { useSelector } from "react-redux";
import { getShowIdeal, getSims } from "../selectors";
import styled from "@emotion/styled";
import { lastAgeBand } from "../simulation/sim";
import { DebugConditionChart } from "./DebugConditionChart";
import { DebugSimDetails } from "./DebugSimDetails";
import { DebugQoLChart } from "./DebugQoLChart";
import { DebugCostChart } from "./DebugCostChart";
import { Button, ButtonGroup } from "react-bootstrap";

const Table = styled.table({
  minWidth: 800,
  borderCollapse: "collapse",
  th: { textAlign: "left" },
  td: {
    border: "1px solid #ddd",
    padding: 3,
  },

  "tr:nth-of-type(even)": {
    backgroundColor: "#f3f3f3",
  },
});

const width = 1000;
const diameter = 5;
const gridSize = diameter + 1;

interface SimElement {
  sim: Sim;
  color: string;
  x: number;
  y: number;
}

const colorForCondition = (condition: Conditions, level: number) =>
  ["#eee", "#f7b538", "#db7c26", "#d8572a", "#c32f27"][level] || "#000";

const deceasedColor = "#000";
const mortalityColor = (
  sim: Sim,
  ageBandIndex: number = 0,
  ageBandProp: AgeBandProp
) => {
  if (!sim[ageBandProp][ageBandIndex]?.alive) {
    return deceasedColor;
  }
  return null;
};

const grid = (
  arr: Sim[],
  startX: number,
  width: number,
  condition: Conditions,
  ageBandIndex: number,
  ageBandProp: AgeBandProp
): SimElement[] => {
  const columns = Math.floor(width / gridSize);
  let x = diameter + startX;
  let y = 0;
  return arr.map((element, index) => {
    if (index % columns === 0) {
      x = diameter + startX;
      y += gridSize;
    } else {
      x += gridSize;
    }
    return {
      sim: element,
      color:
        mortalityColor(element, ageBandIndex, ageBandProp) ||
        colorForCondition(
          condition,
          conditionLevel(element, condition, ageBandIndex, ageBandProp)
        ),
      x,
      y,
    };
  });
};

const conditionLevel = (
  sim: Sim,
  condition: Conditions,
  ageBandIndex: number,
  ageBandProp: AgeBandProp
): number => {
  return (
    sim[ageBandProp][ageBandIndex]?.activeConditions.find(
      (c) => c.condition === condition
    )?.level || 0
  );
};

const sortByCondition =
  (condition: Conditions, ageBandIndex: number, ageBandProp: AgeBandProp) =>
  (a: Sim, b: Sim) => {
    const aLevel = conditionLevel(a, condition, ageBandIndex, ageBandProp);
    const bLevel = conditionLevel(b, condition, ageBandIndex, ageBandProp);
    const isAliveA = !!a[ageBandProp][ageBandIndex]?.alive;
    const isAliveB = !!b[ageBandProp][ageBandIndex]?.alive;

    if (isAliveA && !isAliveB) {
      return -1;
    }
    if (!isAliveA && isAliveB) {
      return 1;
    }
    if (!isAliveA && !isAliveB) {
      return 0;
    }

    return aLevel - bLevel;
  };

const formatData = (
  sims: Sim[],
  animationStage: number,
  condition: Conditions,
  ageBandIndex: number,
  ageBandProp: AgeBandProp
): SimElement[] => {
  switch (animationStage) {
    case 0:
      return grid(
        sims
          .concat()
          .sort(
            sortByCondition(
              condition,
              Math.max(ageBandIndex - 1, 0),
              ageBandProp
            )
          ),
        0,
        width,
        condition,
        Math.max(0, ageBandIndex - 1),
        ageBandProp
      );
    case 1:
      // Our mid-transition where the dots change color
      return grid(
        sims,
        0,
        width,
        condition,
        Math.max(0, ageBandIndex),
        ageBandProp
      );
    case 2:
      return grid(
        sims
          .concat()
          .sort(sortByCondition(condition, ageBandIndex, ageBandProp)),
        0,
        width,
        condition,
        Math.max(0, ageBandIndex),
        ageBandProp
      );
    default:
      return grid(
        sims,
        0,
        width,
        condition,
        Math.max(0, ageBandIndex),
        ageBandProp
      );
  }
};

export const DebugPopulation: React.FC<{
  condition: Conditions;
  ageBandIndex: number;
  setCurrent: (sim: Sim) => void;
}> = React.memo(({ condition, ageBandIndex, setCurrent }) => {
  const initialAgeBandIndex = ageBandIndex || 0;
  const svgRef = useRef<SVGSVGElement>(null);
  const [data, setData] = useState<SimElement[]>([]);
  const sims = useSelector(getSims);
  const [animationStage, setAnimationStage] = useState(0);
  const [ageBandProp, setAgeBandProp] = useState<AgeBandProp>("bauAgeBands");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setData(
      formatData(
        sims,
        animationStage,
        condition,
        initialAgeBandIndex,
        ageBandProp
      )
    );
  }, [ageBandProp, animationStage, condition, initialAgeBandIndex, sims]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    //setAnimationStage(0);
    setTimeout(() => setAnimationStage(1), 1000);
    setTimeout(() => setAnimationStage(2), 2000);
  }, []);

  const render = useCallback(() => {
    const svg = svgRef.current;
    if (!svg) return;

    // d3.select(svg)
    //   .selectAll(".interesting-point")
    //   // .data(data.filter(isInteresting))
    //   .join(
    //     (enter) =>
    //       enter
    //         .append("rect")
    //         .attr("class", "interesting-point")
    //         .attr("opacity", (d) => Math.min(1, d.interestingScore / 10))
    //         .attr("width", diameter + 2)
    //         .attr("height", diameter + 2)
    //         .attr("stroke", "#d5de71")
    //         .attr("transform", (d, i) => `translate(${d.x - 1},${d.y - 1})`),
    //     (update) =>
    //       update.attr(
    //         "transform",
    //         (d, i) => `translate(${d.x - 1},${d.y - 1})`
    //       ),
    //     (exit) => exit.remove()
    //   );

    d3.select(svg)
      .selectAll(".sim-point")
      .data(data)
      .join(
        (enter) =>
          enter
            .append("rect")
            .attr("class", "sim-point")
            .attr("width", diameter)
            .attr("height", diameter)
            .attr("fill", (d) => d.color)
            .attr("transform", (d, i) => `translate(${d.x},${d.y})`)
            .on("click", (e, d) => setCurrent(d.sim)),
        (update) =>
          update
            .transition()
            .attr("fill", (d) => d.color)
            .duration(300)
            .delay((d) => Math.random() * 500),
        (exit) => exit.remove()
      );

    // .on("mouseover", function (d, i) {
    //   d3.select(this)
    //     .transition()
    //     .duration(50)
    //     .attr("width", diameter * 5)
    //     .attr("height", diameter * 5);
    // })
    // .on("mouseout", function (d, i) {
    //   d3.select(this)
    //     .transition()
    //     .duration(50)
    //     .attr("width", diameter)
    //     .attr("height", diameter);
    // });
  }, [data]);

  const showIdeal = useSelector(getShowIdeal);

  useEffect(() => {
    render();
  }, [render]);

  return (
    <div>
      <svg ref={svgRef} width={width + 100} height={400} />

      {ageBandIndex > 0 && (
        <>
          <br />
          <ButtonGroup>
            <Button
              variant={
                ageBandProp === "bauAgeBands" ? "primary" : "outline-primary"
              }
              onClick={() => setAgeBandProp("bauAgeBands")}
            >
              BaU
            </Button>
            <Button
              variant={
                ageBandProp === "ageBands" ? "primary" : "outline-primary"
              }
              onClick={() => setAgeBandProp("ageBands")}
            >
              User
            </Button>
            {showIdeal && (
              <Button
                variant={
                  ageBandProp === "idealAgeBands"
                    ? "primary"
                    : "outline-primary"
                }
                onClick={() => setAgeBandProp("idealAgeBands")}
              >
                Ideal
              </Button>
            )}
          </ButtonGroup>
        </>
      )}
    </div>
  );
});
