import { atom } from "recoil";
import { EMR, EMRData } from "../data/emr";

export const emrInteractiveAtom = atom<boolean>({
  key: "emrInteractive",
  default: true,
});

export const emrVisibleAtom = atom<boolean>({
  key: "emrVisible",
  default: false,
});

export const emrAtom = atom<EMR>({
  key: "emr",
  default: EMRData[0],
});

export const emrUpdateAtom = atom<string | null>({
  key: "emrUpdate",
  default: null,
});

export const emrSelectedTabAtom = atom<
  "snapshot" | "sdoh" | "history" | "acp" | "4ms"
>({
  key: "emrSelectedTab",
  default: "snapshot",
});

export const emrHighlightedSectionAtom = atom<
  string | null
>({
  key: "emrHighlightedSection",
  default: null,
});

export const emrLastUpdateAtom = atom<string | null>({
  key: "emrLastUpdate",
  default: null,
}); 