import useSWR from "swr";
import { airtableBase } from "./airtable";
import { FiveMPlusTraining, FiveMPlusTrainingCodes } from "./five-ms";
import { ConditionsOrHealthy } from "./simulation/simulation-types";

//impact[ageBandIndex][condition]
//priority[ageBandIndex][condition]

const emptyImpact = (): Record<
  number,
  Record<ConditionsOrHealthy, number>
> => ({
  0: {
    frail: 0,
    healthy: 0,
  },
  1: {
    frail: 0,
    healthy: 0,
  },
  2: {
    frail: 0,
    healthy: 0,
  },
});

export type BuffTypes = "Trust" | "Insight" | "Uptake";

export interface Treatment {
  id: string;
  name: string;
  tier: number;
  category: FiveMPlusTraining;
  cost: number;
  impact: Record<number, Record<ConditionsOrHealthy, number>>;
  priority: Record<number, Record<ConditionsOrHealthy, number>>;
  description: string;
  interventions: string;

  video: string;
  buffs: Record<BuffTypes, number>;
  tags: string[];
}

const mapTreatment = (record: any): Treatment => {
  const result: Treatment = {
    id: record.id,
    name: record.get("Name"),
    category: FiveMPlusTrainingCodes[record.get("5Ms")],
    cost: record.get("Associated Costs"),
    impact: emptyImpact(),
    priority: emptyImpact(),
    tier: 1,
    interventions: record.get("Interventions"),
    description: record.get("Description"),
    video: record.get("Instruction Video"),
    buffs: {} as Record<BuffTypes, number>,
    tags: record.get("Tags"),
  };

  result.impact[0]["healthy"] = record.get("65-74 Impact (Healthy)");
  result.impact[0]["frail"] = record.get("65-74 Impact (Frail)");
  result.priority[0]["healthy"] = record.get("65-74 Priority (Healthy)");
  result.priority[0]["frail"] = record.get("65-74 Priority (Frail)");

  result.impact[1]["healthy"] = record.get("75-84 Impact (Healthy)");
  result.impact[1]["frail"] = record.get("75-84 Impact (Frail)");
  result.priority[1]["healthy"] = record.get("75-84 Priority (Healthy)");
  result.priority[1]["frail"] = record.get("75-84 Priority (Frail)");

  result.impact[2]["healthy"] = record.get("85-100 Impact (Healthy)");
  result.impact[2]["frail"] = record.get("85-100 Impact (Frail)");
  result.priority[2]["healthy"] = record.get("85-100 Priority (Healthy)");
  result.priority[2]["frail"] = record.get("85-100 Priority (Frail)");

  const buffs = (record.get("Buffs") as BuffTypes[]) || [];
  const buffValues = (record.get("Values") || "")
    .split(",")
    .map((v: string) => parseInt(v));

  result.buffs = buffs.reduce((acc, buff, index) => {
    acc[buff] = buffValues[index];
    return acc;
  }, {} as Record<BuffTypes, number>);

  result.tier = record.get("Tier");

  return result;
};

export function loadTreatments(): Promise<Treatment[]> {
  return new Promise<Treatment[]>((resolve, reject) => {
    airtableBase("Treatment/Assessment")
      .select({ view: "Grid view" })
      .all((err, records) => {
        if (err || !records) {
          console.error(err);
          window.alert(
            "Error fetching data from Airtable! See console for details."
          );
          reject();
          return;
        }

        // console.log(
        //   `fetched ${records.length} Treatment/Assessment rows`,
        //   records
        // );

        resolve(records.map(mapTreatment));
      });
  });
}

export const useTreatments = () => useSWR("treatment-options", loadTreatments);
