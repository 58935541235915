import styled from "@emotion/styled";
import { dialogOptionsBackgroundGradient } from "./dialog-styles";

export const PromptFrame = styled.div<{ open: boolean }>`
  ${dialogOptionsBackgroundGradient};
  display: flex;
  width: 500px;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  box-shadow: 24px 0px 24px 0px rgba(0, 23, 31, 0.25);

  font-family: Noto Sans;
  line-height: normal;

  transform: ${(props) =>
    props.open ? "translateX(0)" : "translateX(-590px)"};
  transition: transform 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  color: #fff;
`;
