export const IconProblem: React.FC = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1170_8838)">
        <path
          d="M1.31875 18.25C0.512503 16.8563 0.512503 15.1438 1.31875 13.75L6.8375 4.19376C7.64375 2.80001 9.125 1.94376 10.7375 1.94376H21.7688C23.375 1.94376 24.8625 2.80001 25.6688 4.19376L31.1813 13.75C31.9875 15.1438 31.9875 16.8563 31.1813 18.25L25.6625 27.8063C24.8563 29.2 23.375 30.0563 21.7625 30.0563H10.7313C9.125 30.0563 7.6375 29.2 6.83125 27.8063L1.31875 18.25ZM16.25 8.00001C15.4188 8.00001 14.75 8.66876 14.75 9.50001V16.5C14.75 17.3313 15.4188 18 16.25 18C17.0813 18 17.75 17.3313 17.75 16.5V9.50001C17.75 8.66876 17.0813 8.00001 16.25 8.00001ZM18.25 22C18.25 21.4696 18.0393 20.9609 17.6642 20.5858C17.2891 20.2107 16.7804 20 16.25 20C15.7196 20 15.2109 20.2107 14.8358 20.5858C14.4607 20.9609 14.25 21.4696 14.25 22C14.25 22.5304 14.4607 23.0391 14.8358 23.4142C15.2109 23.7893 15.7196 24 16.25 24C16.7804 24 17.2891 23.7893 17.6642 23.4142C18.0393 23.0391 18.25 22.5304 18.25 22Z"
          fill="#D34343"
        />
      </g>
      <defs>
        <clipPath id="clip0_1170_8838">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
