import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import menuBackground from "./MenuBackground.svg";
import stripes from "./black-twill.png";
import CheckIcon from "./CheckIcon";
import LockIcon from "./LockIcon";
import LibraryIcon from "./LibraryIcon";
import LevelDetails from "./LevelDetails";
import OpenCloseIcon from "./OpenCloseIcon";
import { useRecoilState } from "recoil";
import { gameStateAtom } from "../../state/gameStateAtom";
import { useSavedGame } from "../../simulation/useSavedGames";
import { useNavigate } from "react-router-dom";
import geriactLogo from "./geriact_splash_screen.png";
import StartIcon from "./StartIcon";
import CheatCodePopup from "../CheatCodePopup";
import { gameStages } from "../GameStages";
import { saveGameState } from "../../state/saveGameService";

interface MenuItem {
  id: string;
  label: string;
  description: string;
  image: string;
  section: "core" | "elective";
}

const menuItems: MenuItem[] = [
  // Core items
  {
    id: "core1",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    label: "Introduction to the Geriatric 4Ms",
    image: "/images/core1.png",
    section: "core",
  },
  {
    id: "core2",
    description:
      "Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
    label: "Asking What Matters",
    image: "/images/core2.png",
    section: "core",
  },
  {
    id: "core3",
    description:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing.",
    label: "Social Determinants of Health",
    image: "/images/core3.png",
    section: "core",
  },
  {
    id: "core4",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore.",
    label: "Multi-complexity",
    image: "/images/core4.png",
    section: "core",
  },
  {
    id: "core5",
    description:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem.",
    label: "Population Health",
    image: "/images/core5.png",
    section: "core",
  },
  // { id: 'core6', label: 'Core 6',  description: 'Core activity 6 description', image: '/images/core6.png', section: 'core' },
  // Elective items
  {
    id: "elective1",
    label: "Elective 1",
    description:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias.",
    image: "/images/elective1.png",
    section: "elective",
  },
  {
    id: "elective2",
    label: "Elective 2",
    description:
      "Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit.",
    image: "/images/elective2.png",
    section: "elective",
  },
  {
    id: "elective3",
    label: "Elective 3",
    description:
      "Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.",
    image: "/images/elective3.png",
    section: "elective",
  },
  {
    id: "elective4",
    label: "Elective 4",
    description:
      "Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.",
    image: "/images/elective4.png",
    section: "elective",
  },
];

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(${menuBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const SplashImage = styled.img`
  width: 50%;
  max-width: 800px;
  height: auto;
  margin-bottom: 16px;
  margin-left: 40px;
`;

const MenuContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

const MenuSection = styled.div`
  width: 480px;
  margin-right: 32px;
`;

const AccordionSection = styled.div`
  margin-bottom: 16px;
`;

const AccordionHeader = styled.button`
  width: 100%;
  text-align: left;
  padding: 16px;
  background-color: #012f50;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
`;

const AccordionContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  background-color: #011e2d;
`;

const MenuItem = styled.button<{
  isLocked: boolean;
  isCompleted: boolean;
  isSelected: boolean;
}>`
  display: flex;
  width: 100%;
  color: ${(props) => (props.isSelected ? "#00171f" : "white")};
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  justify-content: space-between;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  background-color: ${(props) => (props.isSelected ? "#e8c547" : "#011e2d")};
  border: none;
  cursor: ${(props) => (props.isLocked ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.isLocked ? 0.5 : 1)};
  padding: 16px;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const ItemIcon = styled.span`
  margin-right: 8px;
`;

const DetailSection = styled.div``;

const AccordionHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 16px;
`;

const GameMenuScreen: React.FC = () => {
  const { loading } = useSavedGame();
  const navigate = useNavigate();
  const [openSections, setOpenSections] = useState({
    core: true,
    elective: false,
  });
  const [menuItemsState, setMenuItemsState] = useState(menuItems);
  const [gameState, setGameState] = useRecoilState(gameStateAtom);
  const [showCheatPopup, setShowCheatPopup] = useState(false);

  const isLocked = (item: MenuItem) => {
    return (
      gameState.stages.find((stage) => stage.id === item.id)?.locked || false
    );
  };

  const isCompleted = (item: MenuItem) => {
    return (
      gameState.stages.find((stage) => stage.id === item.id)?.completed || false
    );
  };

  const [selectedItem, setSelectedItem] = useState<MenuItem | null>(
    menuItems.find((item) => !isLocked(item) && !isCompleted(item)) ||
      menuItems[0]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.altKey && event.key === "l") {
        console.log("Unlocking all items");
        setGameState((state) => ({
          ...state,
          stages: state.stages.map((item) => ({
            ...item,
            locked: false,
          })),
        }));
      } else if (event.altKey && event.key === "k") {
        setShowCheatPopup((prev) => !prev);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleItemClick = (item: MenuItem) => {
    setGameState((state) => ({
      ...state,
      currentStage: item.id,
    }));
    setSelectedItem(item);
  };

  const toggleSection = (section: "core" | "elective") => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const handleCheatStepSelect = (stageId: string, stepIndex: number) => {
    const newGameState = {
      ...gameState,
      currentStage: stageId,
      stages: gameState.stages.map((stage) =>
        stage.id === stageId
          ? { ...stage, locked: false, currentStep: stepIndex }
          : stage
      ),
    };
    saveGameState(newGameState).then(() => {
      setGameState(newGameState);
    });
    setShowCheatPopup(false);
    navigate("/play");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const icon = (item: MenuItem) =>
    isLocked(item) ? (
      <LockIcon />
    ) : item.id === selectedItem?.id ? (
      <StartIcon transform="scale(1.3)" onClick={onStart(item)} />
    ) : isCompleted(item) ? (
      <CheckIcon style={{ left: "5px", position: "relative" }} />
    ) : null;

  const onStart = (item: MenuItem) => () => {
    setGameState((state) => ({
      ...state,
      currentStage: item.id,
    }));
    setTimeout(() => {
      navigate("/play");
    });
  };

  return (
    <>
      <BackgroundWrapper />
      <Container>
        <SplashImage src={geriactLogo} alt="GeriACT Splash Screen" />
        <MenuContainer>
          <MenuSection>
            <AccordionSection>
              <AccordionHeader onClick={() => toggleSection("core")}>
                <LibraryIcon />
                <AccordionHeaderContent>
                  <div>CORE</div>
                  <OpenCloseIcon open={openSections.core} />
                </AccordionHeaderContent>
              </AccordionHeader>
              <AccordionContent isOpen={openSections.core}>
                {menuItemsState
                  .filter((item) => item.section === "core")
                  .map((item, index) => (
                    <MenuItem
                      isSelected={item.id === selectedItem?.id}
                      key={item.id}
                      onClick={() => handleItemClick(item)}
                      isLocked={isLocked(item)}
                      isCompleted={isCompleted(item)}
                    >
                      {item.label}
                      <ItemIcon>{icon(item)}</ItemIcon>
                    </MenuItem>
                  ))}
              </AccordionContent>
            </AccordionSection>
            <AccordionSection>
              <AccordionHeader onClick={() => toggleSection("elective")}>
                <AccordionHeaderContent>
                  <div>ELECTIVES</div>
                  <OpenCloseIcon open={openSections.elective} />
                </AccordionHeaderContent>
              </AccordionHeader>
              <AccordionContent isOpen={openSections.elective}>
                {menuItemsState
                  .filter((item) => item.section === "elective")
                  .map((item, index) => (
                    <MenuItem
                      isSelected={item.id === selectedItem?.id}
                      key={item.id}
                      onClick={() => handleItemClick(item)}
                      isLocked={isLocked(item)}
                      isCompleted={isCompleted(item)}
                    >
                      {item.label}
                      <ItemIcon>{icon(item)}</ItemIcon>
                    </MenuItem>
                  ))}
              </AccordionContent>
            </AccordionSection>
          </MenuSection>

          <DetailSection>
            {selectedItem ? (
              <LevelDetails
                locked={isLocked(selectedItem)}
                title={selectedItem.label}
                description={selectedItem.description}
                onStart={onStart(selectedItem)}
              />
            ) : (
              <p>Select an activity to view details</p>
            )}
          </DetailSection>
        </MenuContainer>
        {showCheatPopup && (
          <CheatCodePopup
            onClose={() => setShowCheatPopup(false)}
            onStepSelect={handleCheatStepSelect}
            gameStages={gameStages}
          />
        )}
      </Container>
    </>
  );
};

export default GameMenuScreen;
