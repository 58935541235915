import { Constant, SimTagDefinition } from "../constants";
import { Treatment } from "../treatments";

export const conditions = ["frail"] as const;

export type Conditions = (typeof conditions)[number];
export type ConditionsOrHealthy = Conditions | "healthy";
export interface ConditionLevel {
  condition: Conditions;
  level: number;
}

export interface SimYear {
  age: number;
  activeConditions: ConditionLevel[];
  qol: number;
  treatmentCost: number;
  fourMCost: number;
}

/**
 * Represents one person in the simulation.
 */
export interface Sim {
  id: string;
  /**
   * What has this sim been simulated through?
   */
  ageBands: SimAgeBand[];
  bauAgeBands: SimAgeBand[];
  idealAgeBands: SimAgeBand[];

  bauYears: SimYear[];
  idealYears: SimYear[];
  userYears: SimYear[];

  // We want to re-use the same rolls for a user in all the agebands, so we're going to
  // pre-generate those rolls at init time.
  rolls: number[];
}

export type AgeBandProp = "ageBands" | "bauAgeBands" | "idealAgeBands";
export type YearProp = "userYears" | "bauYears" | "idealYears";

export const BandToYearProp: { [key in AgeBandProp]: YearProp } = {
  ageBands: "userYears",
  bauAgeBands: "bauYears",
  idealAgeBands: "idealYears",
};

type ConditionYearMap = Record<string, number>;
/**
 * State of a single sim in a single age band.
 */
export interface SimAgeBand {
  fourMCost: number;
  treatmentCost: number;

  qolScore: number;
  conditionYears: ConditionYearMap;
  activeConditions: ConditionLevel[];
  //treatments: Treatment[];
  alive: boolean;
  age: number;
  receptivity: number;

  tags: SimTag[];
}

export interface SimTagLabel {
  name: string;
  level: number;
  label: string;
  description: string;
}

export interface SimTagLabelMap {
  [key: string]: SimTagLabel;
}

export interface SimTag {
  name: string;
  level: number;
}

export type MaybeTreatment = Treatment | null;
export type AgeBandTreatmentSelections = MaybeTreatment[];

export type TreatmentSelections = AgeBandTreatmentSelections[];

export enum GameState {
  NOT_INITIALIZED = 0,
  INITIALIZED,
  SIMULATING,
  LOADING,
  SAVING,
}

export enum CalcState {
  IDLE = 0,
  WORKING = 1,
}
export interface Simulation {
  progress: number;
  calcState: CalcState;
  id: string;
  userId?: string;
  round: number;
  tags: Record<string, string>;

  sims: Sim[];

  treatmentIdBoardSelections: string[]; // List of currently "on" treatment ID's

  // The user's current selection of treatments for each condition & age band.
  treatmentSelections: Record<ConditionsOrHealthy, TreatmentSelections>;

  currentAgeBand: number;

  // Some app constants
  treatments: Treatment[];
  constants: Constant[];
  simTags: SimTagDefinition[];
  tagLabels: SimTagLabelMap;
  constantsHash: Record<string, Constant>;

  gameState: GameState;
}

export const AgeBands = ["65-74", "75-84", "85+"];
