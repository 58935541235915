import styled from "@emotion/styled";
import { NavButton } from "./NavButton";
import { IconSnapshot } from "./IconSnapshot";
import { IconSDOH } from "./IconSDOH";
import { IconHistory } from "./IconHistory";
import { IconACP } from "./IconACP";
import { IconExit } from "./IconExit";
import { EMRInfo } from "./EMRInfo";
import { EMRSnapshot } from "./snapshot/EMRSnapshot";
import { useState } from "react";
import { EMRSDOH } from "./sdoh/EMRSDOH";
import { Icon4M } from "./Icon4M";
import { EMRACP } from "./EMRAcp";
import { EMRWhatMatters } from "./EMRWhatMatters";
import { useRecoilState } from "recoil";
import { emrAtom, emrHighlightedSectionAtom, emrInteractiveAtom, emrSelectedTabAtom } from "../state/emrAtom";
import { EMRHistory } from "./EMRHistory";

const Header = styled.div`
  label: Header;
  background: #f3e2a3;
  display: flex;
  flex-direction: row;
`;
const PatientName = styled.div`
  label: PatientName;
  color: #000;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  padding: 12px 24px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  display: inline-block;

  background: #e8c547;
`;

const MainColumns = styled.div`
  label: MainColumns;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: 100%;
  flex: 1;
  background: #ebebec;
  position: relative;
`;

const Navbar = styled.div`
  label: Navbar;
  background: #aec0de;
  width: 100px;
  flex: 0 0 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const EMRContainer = styled.div<{ interactive: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0;

  position: absolute;
  top: 20px;
  left: 520px;
  right: 20px;
  bottom: 250px;
  min-height: 720px;
  pointer-events: ${({ interactive }) => interactive ? "auto" : "none"};
`;

const toBirthdate = (age: number, month: number, day: number) => {
  const today = new Date();
  const isAfterBirthday =
    today.getMonth() >= month ||
    (today.getMonth() + 1 === month && today.getDate() >= day);

  const year = today.getFullYear() - age - (isAfterBirthday ? 1 : 0);

  return `${day}/${month}/${year}`;
};

export const EMR: React.FC<{ onClose: () => void, show4MEmrTab?: boolean }> = ({ onClose, show4MEmrTab }) => {
  const [tab, setTab] = useRecoilState(emrSelectedTabAtom);
  const [emrInteractive] = useRecoilState(emrInteractiveAtom);
  const [highlightedTab] = useRecoilState(emrHighlightedSectionAtom);
  // useState<
  //   "snapshot" | "sdoh" | "history" | "acp" | "4ms"
  // >("snapshot");
  const [data] = useRecoilState(emrAtom);
  if (!data) return null;

  return (
    <EMRContainer interactive={emrInteractive}>
      <Header>
        <PatientName>{data.name}</PatientName>
        <EMRInfo label="DOB">
          {toBirthdate(data.age, data.birthday.day, data.birthday.month)}
        </EMRInfo>
        <EMRInfo label="Age">{data.age}</EMRInfo>
        <EMRInfo label="Sex">{data.sex}</EMRInfo>
        <EMRInfo label="Pronouns">{data.pronouns}</EMRInfo>
        <EMRInfo label="Allergies">{data.allergies}</EMRInfo>
      </Header>
      <MainColumns>
        <Navbar>
          <NavButton
            highlighted={highlightedTab === "snapshot"}
            label="Snapshot"
            active={tab === "snapshot"}
            onClick={() => setTab("snapshot")}
          >
            <IconSnapshot />
          </NavButton>

          {/* <NavButton
            label="SDOH"
            active={tab === "sdoh"}
            onClick={() => setTab("sdoh")}
          >
            <IconSDOH />
          </NavButton> */}

          <NavButton
            label="History"
            highlighted={highlightedTab === "history"}
            active={tab === "history"}
            onClick={() => setTab("history")}
          >
            <IconHistory />
          </NavButton>

          <NavButton
            label="ACP"
            highlighted={highlightedTab === "acp"}
            active={tab === "acp"}
            onClick={() => setTab("acp")}
          >
            <IconACP />
          </NavButton>

          {show4MEmrTab && (
            <NavButton
              label="4Ms"
              highlighted={highlightedTab === "4ms"}
              active={tab === "4ms"}
              onClick={() => setTab("4ms")}
            >
              <Icon4M />
            </NavButton>
          )}

          <div style={{ flexGrow: 1 }}></div>

          {emrInteractive && (
            <NavButton label="Exit" active={false} highlighted={false} onClick={onClose}>
              <IconExit />
            </NavButton>
          )}
        </Navbar>

        {tab === "history" && <EMRHistory data={data} />}
        {tab === "snapshot" && <EMRSnapshot data={data} />}
        {tab === "sdoh" && <EMRSDOH data={data} />}
        {tab === "acp" && <EMRACP data={data} />}
        {tab === "4ms" && <EMRWhatMatters data={data} />}
      </MainColumns>
    </EMRContainer>
  );
};
