import medicationIcon from "./assets/icon_medication.png";
import mobilityIcon from "./assets/icon_mobility.png";
import mattersIcon from "./assets/icon_what_matters.png";
import mentationIcon from "./assets/icon_mentation.png";

// TODO: FIX THIS - What the hell was I thinking when I typed "Five" instead of "Four"
export type FiveM = "matters" | "mobility" | "mentation" | "medication";
export type FiveMPlusTraining = FiveM | "training";

export const FiveMNames: Record<FiveM, string> = {
  matters: "What Matters",
  mobility: "Mobility",
  mentation: "Mentation",
  medication: "Medication",
};

export const FiveMPlusTrainingNames: Record<FiveMPlusTraining, string> = {
  matters: "What Matters",
  mobility: "Mobility",
  mentation: "Mentation",
  medication: "Medication",
  training: "Training",
};

export const FiveMIcons: Record<FiveMPlusTraining, string> = {
  matters: mattersIcon,
  mobility: mobilityIcon,
  mentation: mentationIcon,
  medication: medicationIcon,
  training: "",
};

export const FiveMCodes: Record<string, FiveM> = {
  "What Matters": "matters",
  Mobility: "mobility",
  Mentation: "mentation",
  Medication: "medication",
};

export const FiveMPlusTrainingCodes: Record<string, FiveMPlusTraining> = {
  "What Matters": "matters",
  Mobility: "mobility",
  Mentation: "mentation",
  Medication: "medication",
  Training: "training",
};
