import React from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export const DebugHeader = () => {
  return (
    <Navbar bg="primary" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="#home">Geripop</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/oldgame">Old game</Nav.Link>
            <Nav.Link href="/debug">Debug</Nav.Link>

            <NavDropdown
              className="justify-content-end"
              title="Account"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/saved">Saved Games</NavDropdown.Item>
              <NavDropdown.Item href="/account">
                Account Settings
              </NavDropdown.Item>

              <NavDropdown.Divider />
              <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
