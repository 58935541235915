import { firestore } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { GameState } from './gameStateAtom';
import { auth } from '../firebase';

export const saveGameState = async (gameState: GameState): Promise<void> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error('No user logged in');
    }

    const userDocRef = doc(firestore, 'savedGames', user.uid);

    try {
        await setDoc(userDocRef, gameState);
        console.log('Game state saved successfully');
    } catch (error) {
        console.error('Error saving game state:', error);
        throw error;
    }
};

export const loadGameState = async (): Promise<GameState | null> => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error('No user logged in');
    }

    const userDocRef = doc(firestore, 'savedGames', user.uid);

    try {
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
            return docSnap.data() as GameState;
        } else {
            console.log('No saved game state found');
            return null;
        }
    } catch (error) {
        console.error('Error loading game state:', error);
        throw error;
    }
};
