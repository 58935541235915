import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBDsNyTjVOpIy4xgF2aGxzo2sG65BmwjNs",
  authDomain: "geripop-d0747.firebaseapp.com",
  projectId: "geripop-d0747",
  storageBucket: "geripop-d0747.appspot.com",
  messagingSenderId: "1067533276448",
  appId: "1:1067533276448:web:e7a21b075efaf258d87345",
};

export const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);

analytics.app.automaticDataCollectionEnabled = true;
logEvent(analytics, "app_open");
