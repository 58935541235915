import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
} from "recharts";
import { RootState } from "../root-store";
import { getConstant, getShowIdeal } from "../selectors";
import { lastAgeBand } from "../simulation/sim";
import { Conditions, Sim } from "../simulation/simulation-types";
import { Box } from "./Box";
import { Aside } from "./Aside";
import { ageBands } from "../constants";

interface ChartDatapoint {
  label: string;
  bau: number;
  ideal: number;
  diff: number;
  user: number;
}

// type DatapointMap = Record<string, ChartDatapoint>;

// const initKey = (acc: DatapointMap, key: string) =>
//   acc[key] || {
//     name: key,
//     bau: 0,
//     ideal: 0,
//     user: 0,
//   };

export const DebugQoLChart: React.FC<{
  sims: Sim[];
  condition: Conditions;
  ageBandIndex: number;
}> = React.memo(({ sims, condition, ageBandIndex }) => {
  const showIdeal = useSelector(getShowIdeal);

  const data = useMemo<ChartDatapoint[]>(() => {
    // instead of at ageBandIndex, want array from 0->ageBandIndex
    const bands = [];
    for (let i = 0; i <= ageBandIndex; i++) {
      const mapped: ChartDatapoint = sims.reduce(
        (acc: ChartDatapoint, curr: Sim) => {
          acc.bau += lastAgeBand(i, curr.bauAgeBands).qolScore;
          acc.user += lastAgeBand(i, curr.ageBands).qolScore;
          acc.ideal += lastAgeBand(i, curr.idealAgeBands).qolScore;
          acc.diff = Math.round(acc.user - acc.bau);

          return acc;
        },
        {
          label: ["", ...ageBands][i],
          bau: 0,
          user: 0,
          ideal: 0,
          diff: 0,
        }
      );
      bands.push(mapped);
    }

    return bands;
  }, [ageBandIndex, sims]);

  const ratios = [
    useSelector(
      (state: RootState) =>
        getConstant(state, "QoL Ratio", "frail", -1, 1)?.value || 0
    ),
    useSelector(
      (state: RootState) =>
        getConstant(state, "QoL Ratio", "frail", -1, 2)?.value || 0
    ),
    useSelector(
      (state: RootState) =>
        getConstant(state, "QoL Ratio", "frail", -1, 3)?.value || 0
    ),
    useSelector(
      (state: RootState) =>
        getConstant(state, "QoL Ratio", "frail", -1, 4)?.value || 0
    ),
  ];

  return (
    <div>
      <Box>
        +{Math.round(data[ageBandIndex].user - data[ageBandIndex].bau)}
        <small>user</small>
      </Box>
      {showIdeal && (
        <Box>
          +{Math.round(data[ageBandIndex].ideal - data[ageBandIndex].bau)}
          <small>ideal</small>
        </Box>
      )}
      <br />
      <small>
        BaU: {data[ageBandIndex].bau}, User: {data[ageBandIndex].user}
        {showIdeal && <>, Ideal: {data[ageBandIndex].ideal}</>}
      </small>
      <br />
      <Aside>
        <h4>Assumptions:</h4>
        <ul>
          <li>Patients without Frailty receive 1 QALY per year alive.</li>
          {ratios.map((r: number, i: number) => (
            <li key={i}>
              Patients with Frail-{i + 1} receive {r} QALY per year alive.
            </li>
          ))}
        </ul>
      </Aside>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="diff"
          stroke="#8884d8"
          name="QALY Years Added compared to BaU"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </div>
  );
});
