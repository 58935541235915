import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DebugAgeBand } from "./DebugAgeBand";
import { getCalcState, getCurrentAgeBand, getTreatments } from "../selectors";
import { DebugDataViz } from "./DebugDataViz";
import styled from "@emotion/styled";
import { CalcState } from "../simulation/simulation-types";
import { WorkingMessage } from "./WorkingMessage";
import { toggleIdeal } from "../game/game-slice";
import { DebugHeader } from "../DebugHeader";

const Debug = styled.div({ backgroundColor: "#fff" });
//import { useDebugSimulation } from "./simulation/__simulation";

export const DebugSimulator = () => {
  const treatments = useSelector(getTreatments);
  const currentAgeBand = useSelector(getCurrentAgeBand);
  // const gameState = useSelector(getGameState);
  const calcState = useSelector(getCalcState);
  const dispatch = useDispatch();

  useEffect(() => {
    const handler = (e: any) => {
      console.info(e);
      if (e.ctrlKey && e.key === "i") {
        e.preventDefault();
        dispatch(toggleIdeal());
      }
    };
    document.addEventListener("keyup", handler);

    return () => document.removeEventListener("keyup", handler);
  }, [dispatch]);

  // if (gameState === GameState.NOT_INITIALIZED) {
  //   return <SavedGames />;
  // }

  return (
    <>
      <DebugHeader />
      <Debug>
        {calcState === CalcState.WORKING && <WorkingMessage />}
        <DebugDataViz />
        <hr />

        <DebugAgeBand
          treatments={treatments}
          ageBandIndex={0}
          showGrid={currentAgeBand > 0}
          disabled={currentAgeBand !== 0}
        />

        <DebugAgeBand
          treatments={treatments}
          ageBandIndex={1}
          showGrid={currentAgeBand > 1}
          disabled={currentAgeBand !== 1}
        />

        <DebugAgeBand
          treatments={treatments}
          ageBandIndex={2}
          showGrid={currentAgeBand > 2}
          disabled={currentAgeBand !== 2}
        />
      </Debug>
    </>
  );
};
