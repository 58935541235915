import React, { useMemo } from "react";
import { Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
} from "recharts";
import { getShowIdeal } from "../selectors";
import styled from "@emotion/styled";
import {
  AgeBands,
  Conditions,
  Sim,
  SimYear,
  YearProp,
} from "../simulation/simulation-types";

/*  Target data shape =
    [
        { // One of these per year
            bauDead
            bauLevel4
            bauLevel3
            bauLevel2
            bauLevel1
            bauLevel0

            userDead
            userLevel4
            userLevel3
            userLevel2
            userLevel1
            userLevel0
        }
    ]
*/

type PartialBucket =
  | "Dead"
  | "Level4"
  | "Level3"
  | "Level2"
  | "Level1"
  | "Level0";

type Bucket =
  | "bauDead"
  | "bauLevel4"
  | "bauLevel3"
  | "bauLevel2"
  | "bauLevel1"
  | "bauLevel0"
  | "userDead"
  | "userLevel4"
  | "userLevel3"
  | "userLevel2"
  | "userLevel1"
  | "userLevel0";

const simBucket = (
  sim: Sim,
  year: number,
  yearType: YearProp,
  condition: Conditions = "frail"
): PartialBucket => {
  if (sim[yearType][year] === undefined) return "Dead";
  const activeYear: SimYear = sim[yearType][year];

  const activeCondition = activeYear.activeConditions.find(
    (c) => c.condition === condition
  );

  if (!activeCondition) return "Level0";

  return `Level${activeCondition.level}` as PartialBucket;
};

const formatData = (sims: Sim[], maxYear: number) => {
  const rv = [];

  for (let year = 0; year < maxYear; year++) {
    rv.push(
      sims.reduce(
        (acc, sim: Sim) => {
          const bauBucket = ("bau" +
            simBucket(sim, year, "bauYears", "frail")) as Bucket;
          const userBucket = ("user" +
            simBucket(sim, year, "userYears", "frail")) as Bucket;
          acc[bauBucket] += 1;
          acc[userBucket] += 1;
          return acc;
        },
        {
          band: AgeBands[Math.floor(year / 10)],
          year: 65 + year,
          bauDead: 0,
          bauLevel4: 0,
          bauLevel3: 0,
          bauLevel2: 0,
          bauLevel1: 0,
          bauLevel0: 0,
          userDead: 0,
          userLevel4: 0,
          userLevel3: 0,
          userLevel2: 0,
          userLevel1: 0,
          userLevel0: 0,
        }
      )
    );
  }

  return rv;
};

const LegendBlock = styled.span(({ bg }: { bg: string }) => ({
  color: "#fff",
  padding: 10,
  margin: 5,
  minWidth: 100,
  display: "inline-block",
  textAlign: "center",
  backgroundColor: bg,
}));

export const DebugConditionOverTime: React.FC<{
  sims: Sim[];
  years: number;
}> = ({ sims, years }) => {
  const data = useMemo(() => formatData(sims, years), [sims, years]);
  const showIdeal = useSelector(getShowIdeal);

  return (
    <>
      <h2>Condition progression over time</h2>
      <BarChart
        width={1000}
        height={600}
        data={data.filter((d) => d.year % 10 === 4)}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="band" />
        <YAxis />
        <Tooltip />

        <Bar dataKey="bauDead" name="Deceased" stackId="bau" fill="#555">
          <LabelList fill="#eee" position="inside">
            Deceased
          </LabelList>
        </Bar>
        <Bar dataKey="bauLevel4" stackId="bau" fill="#5f3d94">
          <LabelList fill="#eee" position="inside">
            Frail-4
          </LabelList>
        </Bar>
        <Bar dataKey="bauLevel3" stackId="bau" fill="#8a3ffc">
          <LabelList fill="#eee" position="inside">
            Frail-3
          </LabelList>
        </Bar>
        <Bar dataKey="bauLevel2" stackId="bau" fill="#bb8eff">
          <LabelList fill="#eee" position="inside">
            Frail-2
          </LabelList>
        </Bar>
        <Bar dataKey="bauLevel1" stackId="bau" fill="#e6d6ff">
          <LabelList fill="#000" position="inside">
            Frail-1
          </LabelList>
        </Bar>
        <Bar dataKey="bauLevel0" stackId="bau" fill="#eee">
          <LabelList position="inside">Healthy</LabelList>
        </Bar>

        <Bar dataKey="userDead" stackId="user" fill="#555">
          <LabelList fill="#eee" position="inside">
            Deceased
          </LabelList>
        </Bar>
        <Bar dataKey="userLevel4" stackId="user" fill="#01615e">
          <LabelList fill="#eee" position="inside">
            Frail-4
          </LabelList>
        </Bar>
        <Bar dataKey="userLevel3" stackId="user" fill="#007d79">
          <LabelList fill="#eee" position="inside">
            Frail-3
          </LabelList>
        </Bar>
        <Bar dataKey="userLevel2" stackId="user" fill="#01bab6">
          <LabelList fill="#eee" position="inside">
            Frail-2
          </LabelList>
        </Bar>
        <Bar dataKey="userLevel1" stackId="user" fill="#87eded">
          <LabelList fill="#000" position="inside">
            Frail-1
          </LabelList>
        </Bar>
        <Bar dataKey="userLevel0" stackId="user" fill="#eee">
          <LabelList position="inside">Healthy</LabelList>
        </Bar>
      </BarChart>
      <LegendBlock bg="#bb8eff">
        <>BaU</>
      </LegendBlock>
      <LegendBlock bg="#01bab6">
        <>With 4Ms</>
      </LegendBlock>

      {showIdeal && (
        <>
          <h2>Business as usual</h2>
          <BarChart
            width={1000}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />

            <Bar dataKey="bauDead" stackId="bau" fill="#555" />
            <Bar dataKey="bauLevel4" stackId="bau" fill="#412270" />
            <Bar dataKey="bauLevel3" stackId="bau" fill="#8a3ffc" />
            <Bar dataKey="bauLevel2" stackId="bau" fill="#bb8eff" />
            <Bar dataKey="bauLevel1" stackId="bau" fill="#e6d6ff" />
            <Bar dataKey="bauLevel0" stackId="bau" fill="#eee" />
          </BarChart>

          <h2>With 4M</h2>
          <BarChart
            width={1000}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />

            <Bar dataKey="userDead" stackId="user" fill="#555" />
            <Bar dataKey="userLevel4" stackId="user" fill="#084c54" />
            <Bar dataKey="userLevel3" stackId="user" fill="#007d79" />
            <Bar dataKey="userLevel2" stackId="user" fill="#01bab6" />
            <Bar dataKey="userLevel1" stackId="user" fill="#87eded" />
            <Bar dataKey="userLevel0" stackId="user" fill="#eee" />
          </BarChart>

          <BarChart
            width={1000}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />

            <Bar dataKey="bauDead" stackId="bau" fill="#555" />
            <Bar dataKey="bauLevel4" stackId="bau" fill="#412270" />
            <Bar dataKey="bauLevel3" stackId="bau" fill="#8a3ffc" />
            <Bar dataKey="bauLevel2" stackId="bau" fill="#bb8eff" />
            <Bar dataKey="bauLevel1" stackId="bau" fill="#e6d6ff" />
            <Bar dataKey="bauLevel0" stackId="bau" fill="#eee" />

            <Bar dataKey="userDead" stackId="user" fill="#555" />
            <Bar dataKey="userLevel4" stackId="user" fill="#084c54" />
            <Bar dataKey="userLevel3" stackId="user" fill="#007d79" />
            <Bar dataKey="userLevel2" stackId="user" fill="#01bab6" />
            <Bar dataKey="userLevel1" stackId="user" fill="#87eded" />
            <Bar dataKey="userLevel0" stackId="user" fill="#eee" />
          </BarChart>
        </>
      )}
    </>
  );
};
