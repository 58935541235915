import { atom } from "recoil";
import { DialogPlayer } from "../dialog/DialogPlayer";
import { DialogLoader } from "../dialog/DialogLoader";

export interface StageDefinition {
  id: string;
  locked: boolean;
  completed: boolean;
  currentStep: number;
}

export interface GameState {
  currentStage: string;
  stages: StageDefinition[];
}

export const initialStages: StageDefinition[] = [
  { id: "core1", locked: false, completed: false, currentStep: 0 },
  { id: "core2", locked: true, completed: false, currentStep: 0 },
  { id: "core3", locked: true, completed: false, currentStep: 0 },
  { id: "core4", locked: true, completed: false, currentStep: 0 },
  { id: "core5", locked: true, completed: false, currentStep: 0 },
  { id: "elective1", locked: true, completed: false, currentStep: 0 },
  { id: "elective2", locked: true, completed: false, currentStep: 0 },
  { id: "elective3", locked: true, completed: false, currentStep: 0 },
  { id: "elective4", locked: true, completed: false, currentStep: 0 },
];

export interface GameStep {
  id: string;
  label: string;
  component: (onComplete: () => void) => React.ReactNode;
}

export interface GameStage {
  id: string;
  label: string;
  steps: GameStep[];
}

export const initialGameState: GameState = {
  currentStage: "core1",
  stages: initialStages,
};

export const gameStateAtom = atom<GameState>({
  key: "gameState",
  default: initialGameState,
});
