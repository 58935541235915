import { useCallback, useEffect, useMemo, useState } from "react";
import { Dialog, DialogPrompt, DialogResponse } from "./Dialog";
import { DialogContext } from "./DialogContext";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
import { Howl, Howler } from "howler";
import sha1 from "sha1";
import { FiveM } from "../five-ms";
import { set } from "lodash";
import { useRecoilState } from "recoil";
import { emrInteractiveAtom } from "../state/emrAtom";
import { insightStateAtom } from "../state/insight";

export type CategoryResponses = Partial<Record<FiveM, DialogResponse[]>>;

export interface DialogHistory {
  npc: string;
  nodeId: string;
  prompt: string;
  response: string;
  type: "dialog";
}

export interface ActionHistory {
  action: string;
  error: string;
  type: "action";
}

export interface ConditionHistory {
  condition: string;
  result: boolean;
  type: "condition";
}

export type History = DialogHistory | ActionHistory | ConditionHistory;

const evalCondition = (
  condition: string,
  context: DialogContext,
  appendConditionHistory: (condition: string, result: boolean) => void
) => {
  if (!condition || condition.trim() === "") {
    return true;
  }


  const finalCondition = condition.includes("return ")
    ? condition
    : `return ${condition}`;

  console.info("Evaluating condition", finalCondition);
  // eslint-disable-next-line no-new-func
  const f = Function("Flags", "GameFlags", "insight", "trust", finalCondition);

  try {
    const result = f(context.DialogGameFlags, context.GameFlags, context.insight, context.trust);
    console.info("Evaluated!", result);
    appendConditionHistory(finalCondition, result);
    return result;
  } catch (e) {
    console.error(e);
    appendConditionHistory("ERROR! " + finalCondition, true);
    return false;
  }
};

const runActions = (action: string, context: DialogContext) => {
  if (!action || action.length === 0) {
    return;
  }
  console.info("Running action", action);
  // eslint-disable-next-line no-new-func
  const f = Function("Flags", "GameFlags", action + ";");
  f(context.DialogGameFlags, context.GameFlags);
  console.info("Completed running actions", context);
};

interface DialogReturnVal {
  npc: string;
  prompt: DialogPrompt;
  generalResponses: DialogResponse[];
  categoryResponses: CategoryResponses;
  dialogCompleted: boolean;
  selectResponse: (response: DialogResponse) => void;
  history: History[];
  context: DialogContext;
  promptOpen: boolean;
  elapsedTime: number;
  video?: string;
  secondaryVideo?: string;
  currentNodeId: string;
  onVideoEnded?: () => void;
  skipVoiceover: () => void;
  addAssessmentTime: () => void;
  goToNode: (nodeId: string) => void;
}

export const useDialog = (
  dialog: Dialog,
  initialContext: DialogContext,
  onCompleted: (context: DialogContext) => void,
  voiceoverRoot: string,
  skipAllVoiceovers: boolean,
  allowedTime: number = 14
): DialogReturnVal => {
  const [emrInteractive] = useRecoilState<boolean>(emrInteractiveAtom);
  const [insight] = useRecoilState(insightStateAtom);
  const [elapsedTime, setElapsedTime] = useState(1);
  const [currentNodeId, setCurrentNodeId] = useState(dialog.firstNode);
  const [dialogCompleted, setDialogCompleted] = useState(false);
  const [history, setHistory] = useState<History[]>([]);
  const [context, setContext] = useState<DialogContext>(initialContext);
  const [videoCompleted, setVideoCompleted] = useState(false);
  const currentNode = dialog.nodes[currentNodeId];

  const startupDialog = useCallback(() => {
    logEvent(analytics, "dialog started", { dialog: dialog.id });
    // Play initial voiceover
    window.hideEMR();
  }, []);

  useEffect(startupDialog, [dialog.id]);

  const addAssessmentTime = useCallback(() => {
    setElapsedTime((t) => t + 2);
  }, []);

  const skipVoiceover = useCallback(() => {
    Howler.stop();
    setPromptOpen(true);
  }, []);

  const playVoiceover = useCallback(
    (voiceover: string, hasVideo: boolean) => {
      if (skipAllVoiceovers || !voiceover || voiceover === "" || hasVideo) {
        return;
      }
      const hash = sha1(voiceover);
      const voiceoverPath = `${voiceoverRoot}/${hash}.mp3`;
      console.info("Playing voiceover", voiceoverPath);
      const sound = new Howl({
        src: [voiceoverPath],
        volume: hasVideo ? 0 : 1,
      });

      sound.once("loaderror", () => {
        console.error("Failed to load voiceover", voiceoverPath);
        setPromptOpen(true);
      });
      sound.once("end", () => {
        console.info("Voiceover finished");
        setPromptOpen(true);
      });
      sound.once("load", function () {
        console.info("Voiceover loaded");
        sound.play();
      });
    },
    [skipAllVoiceovers, voiceoverRoot]
  );

  const appendHistory = useCallback(
    (h: History) => setHistory((history) => [...history, h]),
    [setHistory]
  );

  const appendDialogHistory = useCallback(
    (npc: string, prompt: string, response: string, nodeId: string) => {
      appendHistory({
        npc,
        prompt,
        nodeId,
        response,
        type: "dialog",
      });
    },
    [appendHistory]
  );

  const appendActionHistory = useCallback(
    (action: string, error: string = "") => {
      appendHistory({
        action,
        error,
        type: "action",
      });
    },
    [appendHistory]
  );

  const appendConditionHistory = useCallback(
    (condition: string, result: boolean) => {
      appendHistory({
        condition,
        result,
        type: "condition",
      });
    },
    [appendHistory]
  );

  const promptForNode = useCallback(
    (nodeId: string) => {
      return dialog.nodes[nodeId]?.prompts.find((prompt) =>
        evalCondition(prompt.condition, context, appendConditionHistory)
      )!;
    },
    [appendConditionHistory, context, dialog.nodes]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialPrompt = useMemo(() => promptForNode(dialog.firstNode), []);

  useEffect(() => {
    logEvent(analytics, "dialog started", { dialog: dialog.id });
    // Play initial voiceover
    Howler.stop();
    setVideoCompleted(skipAllVoiceovers);
    currentNode && playVoiceover(initialPrompt.txt, !!currentNode.video);
  }, [
    currentNode.video,
    dialog.id,
    initialPrompt.txt,
    playVoiceover,
    skipAllVoiceovers,
  ]);

  useEffect(() => {
    // The first prompt needs it's action run on startup
    try {
      runActions(initialPrompt.action, context);
      setContext(context);

      appendActionHistory(initialPrompt.action);
    } catch (e) {
      appendActionHistory(initialPrompt.action, String(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPrompt.action]);

  const responsesForNode = useCallback(
    (nodeId: string) => {
      return dialog.nodes[nodeId]?.responses.filter((response) =>
        evalCondition(response.condition, context, appendConditionHistory)
      );
    },
    [appendConditionHistory, context, dialog.nodes]
  );

  const onVideoEnded = useCallback(() => {
    setPromptOpen(true);
    setVideoCompleted(true);
  }, []);

  const mapResponses = (responses: DialogResponse[]) => {
    return {
      general: responses.filter((r) => !r.category),
      category: responses.reduce((acc, r) => {
        if (r.category) {
          set(acc, r.category, [...(acc[r.category] || []), r]);
        }
        return acc;
      }, {} as CategoryResponses),
    };
  };

  const initialResponses = useMemo(
    () => mapResponses(responsesForNode(dialog.firstNode)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [prompt, setPrompt] = useState<DialogPrompt>(initialPrompt);
  const [promptOpen, setPromptOpen] = useState(skipAllVoiceovers);

  const [categoryResponses, setCategoryResponses] = useState<CategoryResponses>(
    initialResponses.category
  );

  const [generalResponses, setGeneralResponses] = useState<DialogResponse[]>(
    initialResponses.general
  );

  const setResponses = (responses: DialogResponse[]) => {
    const mapped = mapResponses(responses);
    setGeneralResponses(mapped.general);
    setCategoryResponses(mapped.category);
  };

  const goToNode = useCallback(
    (nodeId: string) => {
      setElapsedTime(elapsedTime + 1);

      if (elapsedTime >= allowedTime && Object.keys(dialog.nodes).includes("nurse")) {
        setElapsedTime(0);
        nodeId = "nurse";
      }

      setCurrentNodeId(nodeId);
      const nextPrompt = promptForNode(nodeId);
      if (nextPrompt?.action) {
        try {
          context.insight = insight.score;
          runActions(nextPrompt.action, context);
          setContext(context);
          appendActionHistory(nextPrompt.action);
        } catch (e) {
          appendActionHistory(nextPrompt.action, String(e));
        }
      }
      setPrompt(nextPrompt);
      setPromptOpen(skipAllVoiceovers);
      setVideoCompleted(skipAllVoiceovers);

      playVoiceover(nextPrompt.txt, !!dialog.nodes[nodeId]?.video);
      const responses = responsesForNode(nodeId);
      setResponses(responses);
    },
    [
      appendActionHistory,
      context,
      dialog.nodes,
      elapsedTime,
      playVoiceover,
      promptForNode,
      responsesForNode,
      setResponses,
      skipAllVoiceovers,
      insight,
    ]
  );

  const selectResponse = useCallback(
    (response: DialogResponse) => {
      console.info("Selecting response", response);

      // Close the EMR if it's open because the user opened it (ie, it's emrInteractive is true)
      if (emrInteractive && typeof window.hideEMR === 'function') {
        window.hideEMR();
      }

      appendDialogHistory(
        currentNode.npc,
        prompt.txt,
        response.txt,
        currentNode.id
      );

      window.showSlide(null); // Reset the slide
      window.resetAssessment();

      if (response.action) {
        try {
          runActions(response.action, context);
          setContext(context);
          appendActionHistory(response.action);
        } catch (e) {
          appendActionHistory(response.action, String(e));
        }
      }

      if (response.nextNode === "END") {
        setDialogCompleted(true);
        logEvent(analytics, "dialog completed", {
          dialog: dialog.id,
          response: response.txt,
          currentNodeId: currentNodeId,
        });
        onCompleted && onCompleted(context);
        return;
      } else if (response.nextNode.length > 0) {
        logEvent(analytics, "dialog response", {
          dialog: dialog.id,
          response: response.txt,
          nextNodeId: response.nextNode,
          currentNodeId: currentNodeId,
        });
        goToNode(response.nextNode);
      }
    },
    [
      appendActionHistory,
      appendDialogHistory,
      context,
      currentNode.id,
      currentNode.npc,
      currentNodeId,
      dialog.id,
      goToNode,
      onCompleted,
      prompt.txt,
    ]
  );

  if (!dialog.nodes[currentNodeId]) {
    return {
      prompt: {
        txt: "ERROR: Invalid node ID " + currentNodeId,
        action: "",
        condition: "",
      },
      currentNodeId: "",
      addAssessmentTime,
      secondaryVideo: undefined,
      promptOpen: true,
      generalResponses: [],
      categoryResponses: {},
      elapsedTime,
      history: [],
      context: {
        DialogGameFlags: {},
        GameFlags: {},
        insight: 0,
        trust: 0,
      },
      dialogCompleted: true,
      npc: "YOUR COMPUTER",
      selectResponse,
      skipVoiceover,
      goToNode,
    };
  }

  const isSecondaryVideo = dialog.nodes[currentNodeId].video?.startsWith("#");
  const video = isSecondaryVideo
    ? undefined
    : videoCompleted
      ? undefined
      : dialog.nodes[currentNodeId].video;
  const secondaryVideo = isSecondaryVideo
    ? dialog.nodes[currentNodeId].video?.substring(1)
    : undefined;

  return {
    onVideoEnded,
    video,
    currentNodeId,
    secondaryVideo,
    npc: dialog.nodes[currentNodeId].npc,
    prompt,
    categoryResponses,
    generalResponses,
    dialogCompleted,
    elapsedTime,
    addAssessmentTime,
    selectResponse,
    history,
    context,
    promptOpen,
    skipVoiceover,
    goToNode,
  };
};
