import styled from "@emotion/styled";
import { useState } from "react";
import { EMR } from "../../emr/EMR";

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
`;

export const DebugEMR: React.FC = () => {
  const [emr, setEmr] = useState<number | null>(null);

  const onLoadEMR = (emr: number) => () => {
    setEmr(emr);
  };

  if (emr === null) {
    return (
      <div>
        <h1>Debug EMR</h1>
        <Buttons>
          <button className="btn btn-primary" onClick={onLoadEMR(0)}>
            Load EMR 0
          </button>
          <button className="btn btn-primary" onClick={onLoadEMR(0)}>
            Load EMR 1
          </button>
        </Buttons>
      </div>
    );
  }

  return (
    <div>
      <h1>Debug EMR</h1>
      <EMR onClose={() => setEmr(null)} />
    </div>
  );
};
