import { EMRSection } from "../EMRSection";
import { EMRProblemList } from "../snapshot/EMRProblemList";
import { EMRScreenings } from "../snapshot/EMRScreenings";
import { EMRMedications } from "../snapshot/EMRMedications";
import { EMRComments } from "../EMRComments";
import styled from "@emotion/styled";
import { EMR } from "../../data/emr";
import EMRHighlight from "../EMRHighlight";

const SectionGrid = styled.div`
  label: SectionGrid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "problem-list problem-list progress-notes"
    "medications screenings progress-notes";
  gap: 2rem 2rem;
  grid-auto-flow: row;
  width: 100%;
  padding: 1rem;
`;

export const EMRSnapshot: React.FC<{ data: EMR }> = ({ data }) => {
  return (
    <SectionGrid>
      <EMRSection title="Problem List" gridArea="problem-list">
        <EMRHighlight emrPath="problems">
          <EMRProblemList problems={data.problems} />
        </EMRHighlight>
      </EMRSection>
      <EMRSection title="Medications" gridArea="medications">
        <EMRHighlight emrPath="medications">
          <EMRMedications medications={data.medications} />
        </EMRHighlight>
      </EMRSection>
      <EMRSection title="Screenings & Results" gridArea="screenings">
        <EMRHighlight emrPath="screenings">
          <EMRScreenings screenings={data.screenings} />
        </EMRHighlight>
      </EMRSection>
      <EMRSection title="Progress Notes" gridArea="progress-notes">
        <EMRHighlight emrPath="comments">
          <EMRComments comments={data.comments} />
        </EMRHighlight>
      </EMRSection>
    </SectionGrid>
  );
};
