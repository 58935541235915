export const IconOK: React.FC = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1170_8830)">
        <path
          d="M16.25 32C20.4935 32 24.5631 30.3143 27.5637 27.3137C30.5643 24.3131 32.25 20.2435 32.25 16C32.25 11.7565 30.5643 7.68687 27.5637 4.68629C24.5631 1.68571 20.4935 0 16.25 0C12.0065 0 7.93687 1.68571 4.93629 4.68629C1.93571 7.68687 0.25 11.7565 0.25 16C0.25 20.2435 1.93571 24.3131 4.93629 27.3137C7.93687 30.3143 12.0065 32 16.25 32ZM23.3125 13.0625L15.3125 21.0625C14.725 21.65 13.775 21.65 13.1938 21.0625L9.19375 17.0625C8.60625 16.475 8.60625 15.525 9.19375 14.9438C9.78125 14.3625 10.7312 14.3563 11.3125 14.9438L14.25 17.8813L21.1875 10.9375C21.775 10.35 22.725 10.35 23.3062 10.9375C23.8875 11.525 23.8937 12.475 23.3062 13.0562L23.3125 13.0625Z"
          fill="#4DA100"
        />
      </g>
      <defs>
        <clipPath id="clip0_1170_8830">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
