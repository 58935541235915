import React from "react";

export const Icon4M: React.FC = () => (
  <svg
    width="40"
    height="33"
    viewBox="0 0 40 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0.5C23.3137 0.5 26 3.18629 26 6.5C26 6.75362 25.9843 7.00357 25.9537 7.24892C27.2737 8.10019 28.3998 9.22628 29.2511 10.5463C29.4964 10.5157 29.7464 10.5 30 10.5C33.3137 10.5 36 13.1863 36 16.5C36 19.8137 33.3137 22.5 30 22.5C29.7464 22.5 29.4964 22.4843 29.2511 22.4537C28.3998 23.7737 27.2737 24.8998 25.9537 25.7511C25.9843 25.9964 26 26.2464 26 26.5C26 29.8137 23.3137 32.5 20 32.5C16.6863 32.5 14 29.8137 14 26.5C14 26.2464 14.0157 25.9964 14.0463 25.7511C12.7263 24.8998 11.6002 23.7737 10.7489 22.4537C10.5036 22.4843 10.2536 22.5 10 22.5C6.68629 22.5 4 19.8137 4 16.5C4 13.1863 6.68629 10.5 10 10.5C10.2536 10.5 10.5036 10.5157 10.7489 10.5463C11.6002 9.22628 12.7263 8.10019 14.0463 7.24892C14.0157 7.00357 14 6.75362 14 6.5C14 3.18629 16.6863 0.5 20 0.5ZM12.7502 21.834C14.6805 20.8367 16 18.8225 16 16.5C16 14.1775 14.6805 12.1633 12.7502 11.166C13.2888 10.4352 13.9352 9.7888 14.666 9.25022C15.6633 11.1805 17.6775 12.5 20 12.5C22.3225 12.5 24.3367 11.1805 25.334 9.25022C26.0648 9.7888 26.7112 10.4352 27.2498 11.166C25.3195 12.1633 24 14.1775 24 16.5C24 18.8225 25.3195 20.8367 27.2498 21.834C26.7112 22.5648 26.0648 23.2112 25.334 23.7498C24.3367 21.8195 22.3225 20.5 20 20.5C17.6775 20.5 15.6633 21.8195 14.666 23.7498C13.9352 23.2112 13.2888 22.5648 12.7502 21.834ZM20 10.5C22.2091 10.5 24 8.70914 24 6.5C24 4.29086 22.2091 2.5 20 2.5C17.7909 2.5 16 4.29086 16 6.5C16 8.70914 17.7909 10.5 20 10.5ZM20 30.5C22.2091 30.5 24 28.7091 24 26.5C24 24.2909 22.2091 22.5 20 22.5C17.7909 22.5 16 24.2909 16 26.5C16 28.7091 17.7909 30.5 20 30.5ZM30 20.5C32.2091 20.5 34 18.7091 34 16.5C34 14.2909 32.2091 12.5 30 12.5C27.7909 12.5 26 14.2909 26 16.5C26 18.7091 27.7909 20.5 30 20.5ZM10 20.5C12.2091 20.5 14 18.7091 14 16.5C14 14.2909 12.2091 12.5 10 12.5C7.79086 12.5 6 14.2909 6 16.5C6 18.7091 7.79086 20.5 10 20.5Z"
      fill="black"
    />
  </svg>
);
