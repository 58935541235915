import * as React from "react"

function LockIcon(props: any) {
    return (
        <svg
            width={22}
            height={24}
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1526_9535)">
                <path
                    d="M6.5 6v3h9V6a4.501 4.501 0 00-9 0zM5 9V6a6 6 0 1112 0v3h.75a3.749 3.749 0 013.75 3.75v7.5A3.749 3.749 0 0117.75 24H4.25A3.749 3.749 0 01.5 20.25v-7.5A3.749 3.749 0 014.25 9H5zm-3 3.75v7.5a2.25 2.25 0 002.25 2.25h13.5A2.25 2.25 0 0020 20.25v-7.5a2.25 2.25 0 00-2.25-2.25H4.25A2.25 2.25 0 002 12.75z"
                    fill="#fff"
                />
            </g>
            <defs>
                <clipPath id="clip0_1526_9535">
                    <path fill="#fff" transform="translate(.5)" d="M0 0H21V24H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LockIcon
