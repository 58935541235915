import { uniq } from "lodash";
import { Problem } from "../../data/emr";
import styled from "@emotion/styled";

const CategoryName = styled.div`
  color: #4d5061;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-bottom: 1px solid #cdd1c4;
  padding: 0.45rem 1rem;
`;

const Item = styled.div`
  color: #4a6696;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.45rem 1rem;
  border-bottom: 1px solid #cdd1c4;
`;

export const EMRProblemList: React.FC<{ problems: Problem[] }> = ({
  problems,
}) => {
  const categories = uniq(problems.map((problem) => problem.category));
  // Display each category of problem and then the problems under it.
  return (
    <>
      {categories.map((category, index) => (
        <div key={index}>
          <CategoryName>{category}</CategoryName>

          {problems
            .filter((p) => p.category === category)
            .map((problem, index) => (
              <Item key={index}>{problem.name}</Item>
            ))}
        </div>
      ))}
    </>
  );
};
