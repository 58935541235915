import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createEmptySimulation, initializeSims } from "./initial-state";
import {
  CalcState,
  ConditionsOrHealthy,
  MaybeTreatment,
  SimTagLabelMap,
  Simulation,
} from "./simulation-types";
import { Treatment } from "../treatments";
import { Constant, SimTagDefinition } from "../constants";
import { advanceSimulation } from "./simulate-worker-thunk";
import { Tag } from "../tags";

export const simulatorSlice = createSlice({
  name: "simulator",
  initialState: createEmptySimulation(1000),
  extraReducers: (builder) => {
    builder.addCase(advanceSimulation.pending, (state, action) => {
      state.calcState = CalcState.WORKING;
      state.progress = 0;
    });

    builder.addCase(advanceSimulation.fulfilled, (state, action) => {
      const newState: any = action.payload;
      console.info("DONE!");
      return {
        ...newState,
        progress: 100,
        calcState: CalcState.IDLE,
      };
    });
  },
  reducers: {
    goToNextAgeBand: (state, action: PayloadAction<undefined>) => {
      state.currentAgeBand++;
    },

    initializeDebugSimulation: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      state.sims = initializeSims(state);
      state.currentAgeBand = 0;
      state.id = action.payload.id;
    },

    copyBoardTreatmentsToSimulation: (
      state,
      action: PayloadAction<{ ageBandIndex: number }>
    ) => {
      const { ageBandIndex } = action.payload;
      const treatments = state.treatmentIdBoardSelections
        .map((id) => state.treatments.find((t) => t.id === id))
        .filter((t) => t !== undefined) as Treatment[];
      state.treatmentSelections["healthy"][ageBandIndex] = treatments;
      state.treatmentSelections["frail"][ageBandIndex] = treatments;
    },

    initializeSimulation: (state) => {
      state.sims = initializeSims(state);
      state.currentAgeBand = 0;
    },
    treatmentsLoaded: (state, action: PayloadAction<Treatment[]>) => {
      state.treatments = action.payload;
    },
    tagsLoaded: (
      state,
      action: PayloadAction<{ tags: Tag[]; labels: SimTagLabelMap }>
    ) => {
      state.tagLabels = action.payload.labels;
      state.tags = action.payload.tags.reduce((acc, tag) => {
        return {
          ...acc,
          [tag.name]: tag.description,
        };
      }, {} as Record<string, string>);
    },
    simTagsLoaded: (state, action: PayloadAction<SimTagDefinition[]>) => {
      state.simTags = action.payload;
    },

    constantsLoaded: (state, action: PayloadAction<Constant[]>) => {
      state.constants = action.payload;
      state.constantsHash = action.payload.reduce((acc, constant) => {
        return {
          ...acc,
          [`${constant.name}-${constant.ageBandIndex}-${constant.condition}-${constant.level}`]:
            constant,
        };
      }, {});
    },
    setBoardAssessments: (
      state,
      action: PayloadAction<{ assessments: string[] }>
    ) => {
      state.treatmentIdBoardSelections = action.payload.assessments;
    },
    toggleBoardAssessment: (state, action: PayloadAction<{ id: string }>) => {
      if (state.treatmentIdBoardSelections.includes(action.payload.id)) {
        state.treatmentIdBoardSelections =
          state.treatmentIdBoardSelections.filter(
            (id) => id !== action.payload.id
          );
      } else {
        state.treatmentIdBoardSelections.push(action.payload.id);
      }
    },
    simulationProgress: (state: Simulation, action: PayloadAction<number>) => {
      state.progress = action.payload;
    },
    makeTreatmentSelection: (
      state: Simulation,
      action: PayloadAction<{
        condition: ConditionsOrHealthy;
        ageBand: number;
        treatmentIndex: number;
        treatment: MaybeTreatment;
      }>
    ) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const { ageBand, condition, treatmentIndex, treatment } = action.payload;
      state.treatmentSelections[condition][ageBand][treatmentIndex] = treatment;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  makeTreatmentSelection,
  constantsLoaded,
  goToNextAgeBand,
  simTagsLoaded,
  tagsLoaded,
  treatmentsLoaded,
  initializeSimulation,
  initializeDebugSimulation,
  toggleBoardAssessment,
  setBoardAssessments,
  simulationProgress,
  copyBoardTreatmentsToSimulation,
} = simulatorSlice.actions;

export default simulatorSlice.reducer;
