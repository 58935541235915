import React from "react";
import { EMRSection } from "../EMRSection";
import { EMR, getSDOHQuestion } from "../../data/emr";

import { SDOHSummary } from "./SDOHSummary";
import {
  Button,
  ButtonBar,
  ButtonContainer,
  ButtonGradientBar,
  CategoryName,
  DoubleColumns,
  Page,
  QuestionPrompt,
  SDOHButton,
  SDOHCategoryContainer,
} from "../EMRStyles";

interface Question {
  prompt: string;
  options: string[];
}

interface QuestionCategory {
  name: string;
  questions: Question[];
}

export const SDOHQuestionCategories: QuestionCategory[] = [
  {
    name: "PHYSICAL ACTIVITY",
    questions: [
      {
        prompt:
          "On average, how many days per week do you engage in moderate to strenuous exercise (like a brisk walk)?",
        options: ["0 days", "1-3 days", "4+ days"],
      },
      {
        prompt:
          "On average, how many minutes do you engage in exercise at this level?",
        options: ["0-10 mins", "20-40 mins", "50-60 mins", "60+ mins"],
      },
    ],
  },
  {
    name: "FINANCIAL RESOURCE STRAIN",
    questions: [
      {
        prompt:
          "How hard is it for you to pay for the very basics like food, housing, medical care, and heating?",
        options: ["Very Hard", "Hard", "Somewhat Hard", "Not Hard"],
      },
    ],
  },
  {
    name: "HOUSING STABILITY",
    questions: [
      {
        prompt: "Need question here.",
        options: ["Option 1", "Option 2", "Option 3"],
      },
    ],
  },
];

export const EMRSDOH: React.FC<{
  data: EMR;
}> = ({ data }) => {
  return (
    <Page>
      <EMRSection
        title="Social Determinants of Health"
        style={{ minHeight: 0, flex: "1 1 0" }}
      >
        {SDOHQuestionCategories.map((category, index) => (
          <SDOHQuestionCategory
            category={category}
            key={index}
            emr={data}
            categoryIndex={index}
          />
        ))}
      </EMRSection>

      <DoubleColumns>
        <EMRSection title="Summary">
          <SDOHSummary emr={data} />
        </EMRSection>
        <EMRSection title="Care Suggestions">
          TODO: Need data for here.
          <ul>
            <li>TODO</li>
            <li>TODO</li>
          </ul>
        </EMRSection>
      </DoubleColumns>
    </Page>
  );
};

const SDOHQuestionCategory: React.FC<{
  category: QuestionCategory;
  categoryIndex: number;
  emr: EMR;
}> = ({ category, emr, categoryIndex }) => {
  return (
    <SDOHCategoryContainer>
      <CategoryName>{category.name}</CategoryName>
      {category.questions.map((question, index) => (
        <SDOHQuestion
          question={question}
          key={index}
          emr={emr}
          questionIndex={index}
          categoryIndex={categoryIndex}
        />
      ))}
    </SDOHCategoryContainer>
  );
};

const SDOHQuestion: React.FC<{
  question: Question;
  emr: EMR;
  categoryIndex: number;
  questionIndex: number;
}> = ({ question, emr, categoryIndex, questionIndex }) => {
  const activeSelection = getSDOHQuestion(categoryIndex, questionIndex, emr);
  return (
    <>
      <QuestionPrompt>{question.prompt}</QuestionPrompt>
      <ButtonContainer>
        <ButtonBar>
          {question.options.map((option, index) => (
            <SDOHButton active={activeSelection === index} key={index}>
              {option}
            </SDOHButton>
          ))}
        </ButtonBar>
        <ButtonGradientBar />
      </ButtonContainer>{" "}
      <span>
        <SDOHButton active={activeSelection === -1}>No Answer</SDOHButton>
      </span>
    </>
  );
};
