import React from "react";
import { Link } from "react-router-dom";

const knownDocs = [
  {
    title: "Geripop Dialogs",
    id: "1Uvp3d3FUr8ED1eBHhN67RAqAGVZ9M7KAnDHhi7uubWc",
  },
  {
    title: "Geripop Development Dialogs",
    id: "1ybjAAXcNfpHQTPWd-7Wo32IaTGd4dNeYkYx34_uFSgY",
  },
  {
    title: "GERI TEST M7_P2_Unity_dialogs (latest update: 03-14-22)",
    id: "1Uvp3d3FUr8ED1eBHhN67RAqAGVZ9M7KAnDHhi7uubWc",
  },
];

export const DialogDebugConfigDoc: React.FC<{
  setId: (s: any) => any;
  sheetId: string;
}> = ({ sheetId, setId: setDocID }) => {
  return (
    <div>
      <div className="form-group">
        <label>Document to preview</label>
        <ul>
          {knownDocs.map((doc) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <li key={doc.id}>
              <Link to={doc.id}>
                <button className="btn btn-link">{doc.title}</button>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="form-group">
        <label>Enter any doc ID:</label>
        <input
          className="form-control"
          type="text"
          value={sheetId}
          onChange={(e) => setDocID(e.target.value)}
        />
      </div>
      <Link to={sheetId}>
        <button className="btn btn-primary">Preview</button>
      </Link>
    </div>
  );
};
