import { useEffect } from "react";
import "firebaseui/dist/firebaseui.css";
import "tachyons";
import "./App.css";
import VisitScreen from "./VisitScreen";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { DebugSimulator } from "./debug/DebugSimulator";
import { Login } from "./Login";
import { Provider, useDispatch, useSelector } from "react-redux";

import { store } from "./root-store";

import { loadTreatments } from "./treatments";
import { loadConstants, loadSimTags } from "./constants";

import {
  treatmentsLoaded,
  constantsLoaded,
  tagsLoaded,
  simTagsLoaded,
} from "./simulation/simulation-slice";
import { areConstantsLoaded } from "./selectors";
import { Logout } from "./Logout";
import { StartScreen } from "./game/start/StartScreen";
import { GamePlay } from "./game/GamePlay";
import { DialogDebugger } from "./dialog/debug/DialogDebugger";
import { DebugMenu } from "./DebugMenu";
import { loadTagDescriptions, loadTagLabels } from "./tags";
import { DebugEMR } from "./dialog/debug/DebugEMR";
import { RecoilRoot } from "recoil";
import GameMenuScreen from "./game/menu/GameMenuScreen";

const StaticLoader = (props) => {
  const loaded = useSelector(areConstantsLoaded);
  const dispatch = useDispatch();
  useEffect(() => {
    console.info("Loading constants...");
    loadTreatments()
      .then((treatments) => {
        console.info("Treatments loaded");
        dispatch(treatmentsLoaded(treatments));
      })
      .catch((e) => {
        console.error(e);
      });

    loadSimTags().then((simTags) => {
      console.info("Sim tags loaded");
      dispatch(simTagsLoaded(simTags));
    });

    loadConstants()
      .then((constants) => {
        console.info("Constants loaded");
        dispatch(constantsLoaded(constants));
      })
      .catch((e) => {
        console.error(e);
      });

    Promise.all([loadTagDescriptions(), loadTagLabels()]).then(
      ([tagDescriptions, tagLabelMap]) => {
        console.info("Tag descriptions loaded");
        dispatch(tagsLoaded({ tags: tagDescriptions, labels: tagLabelMap }));
      }
    );
  }, [dispatch]);

  return loaded ? props.children : `Loading constants...`;
};

const LoggedInRoutes = () => (
  <Login>
    <Routes>
      <Route path="/debug/emr" element={<DebugEMR />} />
      <Route path="/debug" element={<DebugSimulator />} />
      <Route path="/oldgame" element={<VisitScreen />} />
      <Route path="/menu" element={<GameMenuScreen />} />
      <Route path="/" element={<StartScreen />} />
      <Route path="/play/*" element={<GamePlay />} />
      <Route path="/dialog/*" element={<DialogDebugger />} />
    </Routes>
  </Login>
);

const App = () => {
  return (
    <RecoilRoot>
      <Provider store={store}>
        <StaticLoader>
          <BrowserRouter>
            <Routes>
              <Route path="/logout" element={<Logout />} />
              <Route path="*" element={<LoggedInRoutes />} />
            </Routes>
            <DebugMenu />
          </BrowserRouter>
        </StaticLoader>
      </Provider>
    </RecoilRoot>
  );
};

export default App;
