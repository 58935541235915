import { ReactNode } from "react";

import styled from "@emotion/styled";

const Container = styled.div`
  label: Container;
  display: flex;
  flex-direction: column;
  
  padding: 0.5rem;
  justify-content: flex-start;  
  margin: 0 1rem;
`;

const Body = styled.div`
  label: Body;
  color: #000;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Label = styled.div`
  label: Label;
  color: #000;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const EMRInfo: React.FC<{ label: string; children: ReactNode }> = ({
  label,
  children,
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Body>{children}</Body>
    </Container>
  );
};
