import { useState } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { DebugPopulation } from "./DebugPopulation";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  getApplesToApplesSims,
  getCurrentAgeBand,
  getShowIdeal,
  getSims,
} from "../selectors";
import { Sim } from "../simulation/simulation-types";
import { DebugInterestingPeople } from "./DebugInterestingPeople";
import { DebugSimDetails } from "./DebugSimDetails";
import { DebugConditionChart } from "./DebugConditionChart";
import { DebugQoLChart } from "./DebugQoLChart";

import { DebugPrevalanceTable } from "./DebugPrevalanceTable";
import { DebugConditionOverTime } from "./DebugConditionOverTime";
import { Aside } from "./Aside";
import { DebugProgressionTable } from "./DebugProgressionTable";
import { DebugCostTabs } from "./DebugCostTabs";
import { DebugSimTags } from "./DebugSimTags";
import { DebugSimTagsDistribution } from "./DebugSimTagDistribution";

const Container = styled.div({ margin: 10 });
const Grid = styled.div({
  gap: 25,
  display: "flex",
  width: "100%",
  flexWrap: "wrap",
});

const startedAtLevel = (level: number) => (sim: Sim) => {
  if (level === 0) {
    return !sim.ageBands[0].activeConditions.find(
      (c) => c.condition === "frail"
    );
  }
  return sim.ageBands[0].activeConditions.find(
    (c) => c.condition === "frail" && c.level === level
  );
};

export const DebugDataViz = () => {
  const [current, setCurrent] = useState<Sim | null>(null);
  const ageBandIndex = useSelector(getCurrentAgeBand);
  const condition = "frail";
  const sims = useSelector(getSims);
  const appleToAppleSims = useSelector(getApplesToApplesSims);

  console.info("ageBandIndex", ageBandIndex);

  const showIdeal = useSelector(getShowIdeal);

  return (
    <Container>
      <Tabs
        mountOnEnter={true}
        unmountOnExit={true}
        onSelect={() => setCurrent(null)}
        defaultActiveKey="prevalance"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="prevalance" title="Prevalence Grid">
          <DebugPopulation
            ageBandIndex={ageBandIndex}
            condition="frail"
            setCurrent={setCurrent}
          />
        </Tab>
        <Tab eventKey="prevalancetable" title="Prevalence Table">
          <DebugPrevalanceTable
            ageBandIndex={ageBandIndex}
            condition="frail"
            sims={sims}
          />
          <h2>Progression</h2>
          <Aside>
            <h4>What is this?</h4>
            <p>
              Counts the number of times that someone with a given condition
              progresses to the next condition. A single patient can be counted
              in this table multiple times if they've progressed multiple times
              (such as from frail-1 to frail-2 and then later to frail-3)
            </p>
          </Aside>
          <DebugProgressionTable
            ageBandIndex={ageBandIndex}
            condition="frail"
            sims={sims}
          />
        </Tab>

        <Tab eventKey="conditionChart" title="Condition Chart">
          <h2>Number of people with condition</h2>
          <DebugConditionChart
            ageBandIndex={ageBandIndex || 0}
            condition={condition}
            sims={sims}
          />
          <hr />
          <Grid>
            <div>
              <h4>Patients who started at frail-0</h4>
              <DebugConditionChart
                width={350}
                ageBandIndex={ageBandIndex || 0}
                condition={condition}
                sims={sims.filter(startedAtLevel(0))}
              />
            </div>
            <div>
              <h4>Patients who started at frail-1</h4>
              <DebugConditionChart
                width={350}
                ageBandIndex={ageBandIndex || 0}
                condition={condition}
                sims={sims.filter(startedAtLevel(1))}
              />
            </div>
            <div>
              <h4>Patients who started at frail-2</h4>
              <DebugConditionChart
                width={350}
                ageBandIndex={ageBandIndex || 0}
                condition={condition}
                sims={sims.filter(startedAtLevel(2))}
              />
            </div>
            <div>
              <h4>Patients who started at frail-3</h4>
              <DebugConditionChart
                width={350}
                ageBandIndex={ageBandIndex || 0}
                condition={condition}
                sims={sims.filter(startedAtLevel(3))}
              />
            </div>
            <div>
              <h4>Patients who started at frail-4</h4>
              <DebugConditionChart
                width={350}
                ageBandIndex={ageBandIndex || 0}
                condition={condition}
                sims={sims.filter(startedAtLevel(4))}
              />
            </div>
          </Grid>
        </Tab>

        <Tab eventKey="tags" title="Sim Tag Table">
          <DebugSimTags sims={sims} ageBandIndex={ageBandIndex} />
        </Tab>

        <Tab eventKey="tagsdist" title="Sim Tag Distribution">
          <DebugSimTagsDistribution sims={sims} ageBandIndex={ageBandIndex} />
        </Tab>

        <Tab eventKey="conditionTime" title="Condition Over Time">
          <DebugConditionOverTime sims={sims} years={ageBandIndex * 10} />
        </Tab>

        <Tab eventKey="qolYears" title="QOL Years">
          <h2>Quality-adjusted life-years (QALY)</h2>
          <DebugQoLChart
            ageBandIndex={ageBandIndex || 0}
            condition={condition}
            sims={sims}
          />
        </Tab>
        <Tab eventKey="costChart" title="Costs">
          <DebugCostTabs
            showIdeal={showIdeal}
            condition={condition}
            sims={sims}
            ageBandIndex={ageBandIndex}
            appleToAppleSims={appleToAppleSims}
          />
        </Tab>
        <Tab eventKey="interesting" title="Interesting People">
          <DebugInterestingPeople setCurrent={setCurrent} sims={sims} />
        </Tab>
      </Tabs>
      {current && <DebugSimDetails sim={current} />}
    </Container>
  );
};
