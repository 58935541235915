import styled from "@emotion/styled";

export const Box = styled.div({
  borderRadius: 3,
  minWidth: 120,
  backgroundColor: "#ddd",
  fontSize: 26,
  display: "inline-block",
  padding: 10,
  margin: 10,
  fontWeight: "bold",
  textAlign: "center",
  small: {
    marginTop: 10,
    fontWeight: "normal",
    display: "block",
    fontSize: 10,
  },
});
