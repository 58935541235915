import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { initializeSimulation } from "../../simulation/simulation-slice";
import { useSavedGame } from "../../simulation/useSavedGames";

import geriactBackground from "./geriact-title-screen.jpg";

const preview = require("./preview.png");

const FullScreenDiv = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${geriactBackground});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  padding-top: 100px;
  overflow: hidden;
`;

export const StartScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const savedGames = useSavedGame();

  const play = useCallback(() => {
    //dispatch(initializeSimulation());
    navigate("/menu");
  }, [dispatch, navigate]);

  const continueGame = useCallback(() => {
    // TODO - handle continue game
    dispatch(initializeSimulation());
    navigate("/play");
  }, [dispatch, navigate]);

  if (savedGames.loading) {
    return <div>Loading start screen...</div>;
  }

  const NewGameButton = styled.button`
    background-color: rgba(2, 17, 27, 0.8);
    padding: 15px 50px; // Reduced height, increased width
    color: white;
    border-radius: 3px;
    font-size: 3em;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease; // Smooth transition for hover effect

    &:hover {
      background-color: rgba(
        4,
        34,
        54,
        1
      ); // Slightly lighter and more opaque on hover
    }
  `;

  return (
    <FullScreenDiv>
      <NewGameButton onClick={play} type="button">
        Start
      </NewGameButton>
    </FullScreenDiv>
  );
};
