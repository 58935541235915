import { Screening } from "../../data/emr";
import styled from "@emotion/styled";
import { Iconcheck } from "../IconCheck";
import { IconWarning } from "../IconWarning";
import { IconDanger } from "../IconDanger";


const Item = styled.div`
  
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.45rem 1rem;
  border-bottom: 1px solid #cdd1c4;
  svg {
    position: relative;
    top: -2px;
    margin-right: 0.5rem;
  }
`;

const Icons = {
  check: Iconcheck,
  warning: IconWarning,
  danger: IconDanger,
} as const;

export const EMRScreenings: React.FC<{ screenings: Screening[] }> = ({
  screenings,
}) => {
  return (
    <>
      {screenings.map((problem, index) => {
        const Icon = Icons[problem.icon];
        return <Item key={index}><Icon /> {problem.name}</Item>;
      })}
    </>
  );
};
