import React, { useCallback, useMemo, useState } from "react";
import {
  AgeBandProp,
  Sim,
  SimTagLabelMap,
} from "../simulation/simulation-types";
import { lastAgeBand } from "../simulation/sim";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getTagLevel } from "../simulation/simulation-tagging";
import { useSelector } from "react-redux";
import { getSimTags, getTagLabelMap } from "../selectors";
import { uniq } from "lodash";

interface ChartDatapoint {
  name: string;
  bau: number;
  ideal: number;
  user: number;
}

const getTagKey = (
  sim: Sim,
  ageBand: AgeBandProp,
  tagName: string,
  ageBandIndex: number,
  tagLabels: SimTagLabelMap
) => {
  const band = lastAgeBand(ageBandIndex, sim[ageBand]);
  if (!band.alive) return "deceased";
  const severity = getTagLevel(band.tags, tagName); //getConditionSeverity(band, condition);

  if (severity === 0) {
    return "None";
  }

  const label = tagLabels[`${tagName}/${severity}`]?.label;

  return label ? label : `${tagName}-${severity}`;
};

type DatapointMap = Record<string, ChartDatapoint>;

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const initKey = (acc: DatapointMap, key: string) =>
  acc[key] || {
    name: key,
    bau: 0,
    ideal: 0,
    user: 0,
  };

interface ChartData {
  all: ChartDatapoint[];
  physical: ChartDatapoint[];
  cognitive: ChartDatapoint[];
}

const createChartData = (
  sims: Sim[],
  tagName: string,
  ageBandIndex: number,
  labelMap: SimTagLabelMap
) => {
  const mapped: DatapointMap = sims.reduce((acc: DatapointMap, curr: Sim) => {
    let key = getTagKey(curr, "bauAgeBands", tagName, ageBandIndex, labelMap);
    acc[key] = initKey(acc, key);
    acc[key].bau += 1;

    key = getTagKey(curr, "ageBands", tagName, ageBandIndex, labelMap);
    acc[key] = initKey(acc, key);
    acc[key].user += 1;

    key = getTagKey(curr, "idealAgeBands", tagName, ageBandIndex, labelMap);
    acc[key] = initKey(acc, key);
    acc[key].ideal += 1;

    return acc;
  }, {});

  return Object.values(mapped).sort((a, b) => {
    if (a.name === "deceased") return 1;
    if (b.name === "deceased") return -1;
    return a.name.localeCompare(b.name);
  });
};

export const DebugSimTagsDistribution: React.FC<{
  sims: Sim[];
  ageBandIndex: number;
}> = ({ sims, ageBandIndex }) => {
  const width = 1200;
  const [tagName, setTagName] = useState("Pain & Inflamation");
  const tagDefs = useSelector(getSimTags);
  const labelMap = useSelector(getTagLabelMap);

  const tags: string[] = [
    "Physical Frailty",
    "Cognitive Frailty",
    ...uniq(tagDefs.map((tag) => tag.name)),
  ];

  const filterSim = useCallback(
    (frailtyType: string) => (sim: Sim) => {
      const band = lastAgeBand(ageBandIndex, sim.ageBands);
      const severity = getTagLevel(band.tags, frailtyType);
      return severity > 0;
    },
    [ageBandIndex]
  );

  const data = useMemo<ChartData>(() => {
    return {
      all: createChartData(sims, tagName, ageBandIndex, labelMap),
      physical: createChartData(
        sims.filter(filterSim("Physical Frailty")),
        tagName,
        ageBandIndex,
        labelMap
      ),
      cognitive: createChartData(
        sims.filter(filterSim("Cognitive Frailty")),
        tagName,
        ageBandIndex,
        labelMap
      ),
    };
  }, [sims, tagName, ageBandIndex, labelMap, filterSim]);

  return (
    <div>
      <select value={tagName} onChange={(e) => setTagName(e.target.value)}>
        {tags.map((tag) => (
          <option key={tag} value={tag}>
            {tag}
          </option>
        ))}
      </select>
      <h2>All Patients:</h2>
      <BarChart
        width={width}
        height={300}
        data={data.all}
        margin={{
          top: 15,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="bau" fill="#073b4c" name="BaU" label="hi" />
        <Bar dataKey="user" fill="#06d6a0" name="Player 4M Choices" />
      </BarChart>
      <h2>Physically Frail Patients:</h2>
      BAU:
      <PieChart width={300} height={300}>
        <Pie
          legendType="line"
          data={data.physical}
          dataKey="bau"
          fill="#073b4c"
          nameKey="name"
          label
        >
          {COLORS.map((color, index) => (
            <Cell key={index} fill={color} />
          ))}
        </Pie>
        <Legend />
      </PieChart>
      User:
      <PieChart width={300} height={300}>
        <Pie
          legendType="line"
          data={data.physical}
          dataKey="user"
          fill="#073b4c"
          nameKey="name"
          label
        >
          {COLORS.map((color, index) => (
            <Cell key={index} fill={color} />
          ))}
        </Pie>
        <Legend />
      </PieChart>
      <h2>Cognitively Frail Patients:</h2>
      BAU:
      <PieChart width={300} height={300}>
        <Pie
          legendType="line"
          data={data.cognitive}
          dataKey="bau"
          fill="#073b4c"
          nameKey="name"
          label
        >
          {COLORS.map((color, index) => (
            <Cell key={index} fill={color} />
          ))}
        </Pie>
        <Legend />
      </PieChart>
      User:
      <PieChart width={300} height={300}>
        <Pie
          legendType="line"
          data={data.cognitive}
          dataKey="user"
          fill="#073b4c"
          nameKey="name"
          label
        >
          {COLORS.map((color, index) => (
            <Cell key={index} fill={color} />
          ))}
        </Pie>
        <Legend />
      </PieChart>
    </div>
  );
};
