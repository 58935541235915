import styled from "@emotion/styled";
import * as DialogTypes from "./Dialog";
import React, { useEffect, useState } from "react";
import { PromptFrame } from "./PromptFrame";
import { AssessmentProgress } from "./AssessmentProgress";
import { AssessmentResultDots } from "./AssessmentResultDots";
import { ResponseBox } from "./ResponseBox";

interface AssessmentProps {
  dialog: DialogTypes.Dialog;
  onResponse: (response: DialogTypes.DialogResponse, idx: number) => void;
  children: React.ReactNode;
  prompt: DialogTypes.DialogPrompt;
  responses: DialogTypes.DialogResponse[];
  onAssessmentComplete: () => void;
}

enum AssessmentMode {
  NotActive,
  PerformingTest,
  ResultPending,
  ResultDisplayed,
}

const ResultPending = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
`;

const AssessmentTitle = styled.h1`
  padding: 20px;
  color: #fff;
  font-family: "Noto Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  width: 100%;
`;

const AssessmentResults = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  #promptBubble {
    display: none;
  }
`;

export const Assessment: React.FC<AssessmentProps> = ({
  dialog,
  children,
  prompt,
  onResponse,
  onAssessmentComplete,
}) => {
  const [assessmentMode, setAssessmentMode] = useState<AssessmentMode>(
    AssessmentMode.NotActive
  );

  useEffect(() => {
    window.assessment = () => {
      setAssessmentMode(AssessmentMode.PerformingTest);
    };
    window.resetAssessment = () => {
      setAssessmentMode(AssessmentMode.NotActive);
    };
  }, []);

  if (!assessmentMode) {
    return <>{children}</>;
  }

  const parts = prompt.txt.split("\n");
  const [assessmentName, ...results] = parts;

  return (
    <>
      <AssessmentTitle>{assessmentName}</AssessmentTitle>
      <AssessmentProgress
        onCompletion={() =>
          assessmentMode === AssessmentMode.PerformingTest &&
          setAssessmentMode(AssessmentMode.ResultPending)
        }
      />
      {assessmentMode === AssessmentMode.ResultPending && (
        <ResultPending>
          Result:
          <AssessmentResultDots
            onComplete={() => {
              setAssessmentMode(AssessmentMode.ResultDisplayed);
              onAssessmentComplete();
            }}
          />
        </ResultPending>
      )}
      {assessmentMode === AssessmentMode.ResultDisplayed && (
        <>
          <AssessmentResults>
            {results.map((result, idx) => (
              <div key={`result${idx}`}>{result}</div>
            ))}
          </AssessmentResults>
          {children}
        </>
      )}
    </>
  );
};

declare global {
  interface Window {
    assessment: () => void;
    resetAssessment: () => void;
  }
}
