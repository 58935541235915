import { cloneDeep, shuffle } from "lodash";
import { SimTagDefinition, SimTagPopulationTypes } from "../constants";
import { getConditionSeverity } from "./sim";
import { AgeBandProp, Sim, SimAgeBand, SimTag } from "./simulation-types";

export const applyInitialSimulationTags = (
  simTags: SimTagDefinition[],
  sims: Sim[],
  ageBandIndex: number
) => {
  sims.forEach((sim, index) =>
    setupFrailtyTags(sim, ageBandIndex, "ageBands", index)
  );
  simTags.forEach((simTag) => applyTag(simTag, sims, ageBandIndex, "ageBands"));
  copyTags(sims, ageBandIndex, "ageBands", "bauAgeBands");
};

export const applyConditionAdvancedSimulationTags = (
  simTags: SimTagDefinition[],
  sim: Sim,
  ageBandIndex: number
) => {
  setupFrailtyTags(sim, ageBandIndex, "ageBands", 0);

  simTags.forEach((simTag) =>
    applyTag(simTag, [sim], ageBandIndex, "ageBands")
  );
};

const copyTags = (
  sims: Sim[],
  ageBandIndex: number,
  from: AgeBandProp,
  to: AgeBandProp
) => {
  sims.forEach((sim) => {
    const fromTags = sim[from][ageBandIndex].tags;
    sim[to][ageBandIndex].tags = cloneDeep(fromTags);
  });
};

const setupFrailtyTags = (
  sim: Sim,
  ageBandIndex: number,
  ageBandType: AgeBandProp,
  idx: number
) => {
  const physicalFrailtyPercent = 0.9;
  const cognitiveFrailtyPercent = 0.07; // some overlap with physical frailty

  const frailtyLevel = getConditionSeverity(
    sim[ageBandType][ageBandIndex],
    "frail"
  );
  if (frailtyLevel === 0) return;
  const cognitiveFrailty = Math.random() < cognitiveFrailtyPercent;
  const physicalFrailty =
    !cognitiveFrailty || Math.random() < physicalFrailtyPercent; // They must have at least one.

  // If a patient has both, the physical will be=frailty level, but the mental could be less
  const cognitiveFrailtyLevel =
    cognitiveFrailty && physicalFrailty
      ? Math.ceil(Math.random() * frailtyLevel)
      : frailtyLevel;

  if (cognitiveFrailty) {
    applyTagLevel(
      sim[ageBandType][ageBandIndex].tags,
      "Cognitive Frailty",
      cognitiveFrailtyLevel
    );
  }
  if (physicalFrailty) {
    applyTagLevel(
      sim[ageBandType][ageBandIndex].tags,
      "Physical Frailty",
      frailtyLevel
    );
  }
};

const hasTagLevel = (tags: SimTag[], name: string, level: number) => {
  if (!tags) return false;
  const tag = tags.find((tag) => tag.name === name);
  if (level === 0) {
    // For level 0, we don't want the tag present at all
    return !tag;
  }
  return tag && tag.level === level;
};

/*
  A sim tag definition will specify a target percent of a sub-population to tag.

  For example: We might tag 30% of the physically frail/1 population as having pain & inflamation/1
  This function matches sims to sub populations for us.

  1. General Frailty = the condition severity
  2. Independant = it's an independant tag that could affect anyone
  3. A specific tag name = the tag must be present at the specified level
*/
const doesSimMatch = (
  band: SimAgeBand,
  outOf: SimTagPopulationTypes,
  outOfLevel: number
) => {
  if (!band) return false;
  switch (outOf) {
    case "General Frailty":
      const severity = getConditionSeverity(band, "frail");
      return outOfLevel === severity;
    case "Independent":
      return true;
    default:
      return hasTagLevel(band.tags, outOf, outOfLevel);
  }
};

/*
 Finds the sim sub-population that matches a tag definition.
 Gives us 2 lists, one for BAU and one for user choices.
*/
const findPopulation = (
  sims: Sim[],
  ageBandIndex: number,
  outOf: SimTagPopulationTypes,
  outOfLevel: number
): {
  bau: Sim[];
  user: Sim[];
} => {
  return {
    user: sims.filter((sim) => {
      const band = sim.ageBands[ageBandIndex];
      return doesSimMatch(band, outOf, outOfLevel);
    }),
    bau: sims.filter((sim) => {
      const band = sim.bauAgeBands[ageBandIndex];
      return doesSimMatch(band, outOf, outOfLevel);
    }),
  };
};

const applyTag = (
  simTag: SimTagDefinition,
  sims: Sim[],
  ageBandIndex: number,
  ageBandType: AgeBandProp
) => {
  const { name, level, percentPopulation, outOf, outOfLevel } = simTag;
  const candidates = findPopulation(sims, ageBandIndex, outOf, outOfLevel);

  console.info({ name, level, candidates });

  applyTagTo(
    candidates.user,
    name,
    level,
    percentPopulation,
    ageBandType,
    ageBandIndex
  );
};

const getTag = (tags: SimTag[], name: string) => {
  return tags.find((tag) => tag.name === name);
};
export const getTagLevel = (tags: SimTag[], name: string) => {
  const tag = getTag(tags, name);
  return tag ? tag.level : 0;
};

const applyTagTo = (
  sims: Sim[],
  name: string,
  level: number,
  percentPopulation: number,
  ageBandType: "ageBands" | "bauAgeBands" | "idealAgeBands",
  ageBandIndex: number
) => {
  const count = Math.round((sims.length * percentPopulation) / 100);
  const shuffledSims = shuffle(sims);

  shuffledSims.slice(0, count).forEach((sim) => {
    const tags = sim[ageBandType][ageBandIndex].tags;
    applyTagLevel(tags, name, level);
  });

  console.info(`Applying tags ${name}/${level}`, {
    populationCount: sims.length,
    percentage: percentPopulation,
    count,
    ageBandType,
  });
};

const applyTagLevel = (tags: SimTag[], name: string, level: number) => {
  const existingTag = getTag(tags, name);

  if (existingTag) {
    existingTag.level = Math.max(level, existingTag.level);
  } else {
    tags.push({
      name,
      level,
    });
  }
};
