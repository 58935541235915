import { atom, useRecoilState } from "recoil";

interface InsightState {
  score: number;
  messages: string[];
}

export const insightStateAtom = atom<InsightState>({
  key: "insightState",
  default: {
    score: 0,
    messages: [],
  },
});

export const insightAnimationAtom = atom<{ start: number; end: number } | null>(
  {
    key: "insightAnimation",
    default: null,
  }
);

export const useInsight = () => {
  const [insight, setInsight] = useRecoilState(insightStateAtom);
  const [insightAnimation, setInsightAnimation] =
    useRecoilState(insightAnimationAtom);

  const addInsight = (message: string, value: number) => {
    setTimeout(() => {
      setInsightAnimation({ start: insight.score, end: insight.score + value });
      setTimeout(() => {
        setInsightAnimation(null);
      }, 5000);
      setInsight((prev) => {
        return {
          score: prev.score + value,
          messages: [...prev.messages, message],
        };
      });
    }, 500);
  };

  const clearInsightAnimation = () => {
    setInsightAnimation(null);
  };

  return {
    insight,
    addInsight,
    insightAnimation,
    clearInsightAnimation,
  };
};
