import styled from "@emotion/styled";
import { useEffect, useState } from "react";

interface AssessmentProgressProps {
  onCompletion: () => void;
}

const ProgressContainer = styled.div`
  width: calc(100% - 40px);
  height: 12px;
  position: relative;
  background: rgba(0, 23, 31, 0.5);  
`;

const ProgressBar = styled.div<{ width: number }>`
  width: ${(props) => `${Math.min(100, props.width)}%`};
  height: 12px;
  position: absolute;
  left: 0;
  top: 0;  
  background: #e8c547;
`;

export const AssessmentProgress: React.FC<AssessmentProgressProps> = ({
  onCompletion,
}) => {
  const [progress, setProgress] = useState<number>(0);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          setTimeout(onCompletion);
          clearInterval(interval);
          return 100;
        }
        return prevProgress >= 100 ? 0 : prevProgress + Math.random() ;
      });
    }, 20);
    return () => {
      clearInterval(interval);
    };
  }, [onCompletion]);

  return (
    <ProgressContainer>
      <ProgressBar width={progress} />
    </ProgressContainer>
  );
};
