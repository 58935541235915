import styled from "@emotion/styled";

export const ResponseBox = styled.div`
  background: rgba(0, 23, 31, 0.5);
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: #fff;
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #000;
  cursor: pointer;
  &:first-of-type {
    border-top: 1px solid #000;
  }
  &:hover {
    background: rgba(0, 23, 31, 0.8);
  }
`;