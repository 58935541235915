import { useEffect, useState } from "react";
import names from "american-sounding-names";

interface SimDetail {
  name: string;
  pic: string;
}

const details: Record<string, SimDetail> = {};

export const useSimDetails = (simId: string) => {
  const [simDetails, setSimDetails] = useState<SimDetail | null>(null);

  useEffect(() => {
    if (!details[simId]) {
      setSimDetails(null);
      const gender = Math.random() > 0.5 ? "male" : "female";
      // TODO: Faces
      // fetch(`https://fakeface.rest/face/json?minimum_age=65&gender=${gender}`) // gender=female&
      //   .then((r) => r.json())
      Promise.resolve({
        image_url: "https://placehold.co/64x64/EEE/31343C",
      }).then((r) => {
        details[simId] = {
          name: names({ gender }),
          pic: r.image_url,
        };
        setSimDetails(details[simId]);
      });
    } else {
      setSimDetails(details[simId]);
    }
  }, [simId]);

  return simDetails;
};
