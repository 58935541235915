import React, { useCallback, useEffect, useState } from "react";
import useGoogleSheets from "use-google-sheets";
import { DialogDebugConfigSheet } from "./DialogDebugConfigSheet";
import { DialogSheet } from "../DialogSheet";
import { dialogImport } from "../DialogImport";
import { Dialog, DialogNode } from "../Dialog";
import { DialogPlayer } from "../DialogPlayer";
import { Button } from "react-bootstrap";
import sha1 from "sha1";

import officeBackground from "../../assets/doctor-office-blur.png";
import styled from "@emotion/styled";
import { Route, Routes, useParams, useSearchParams } from "react-router-dom";

const Background = styled.div((props: { background?: string }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  backgroundImage: props.background ? `url(${props.background})` : undefined,
}));

//type Modes = "config" | "preview";

const createTTSCommand = (str: string) => {
  const hash = sha1(str);
  const text = str
    .replace(/'/g, "'\\''")
    .replaceAll("[Slide] ", "")
    .replaceAll("4ms", "four ehms")
    .replaceAll("4m", "four ehm");
  return `aws polly synthesize-speech --engine neural --output-format mp3 --voice-id Gregory --text '${text}' ${hash}.mp3`;
};

export const DialogDebugPreview: React.FC<{ sheetId: string, show4MEmrTab: boolean }> = ({
  sheetId,
  show4MEmrTab,
}) => {
  const { data, loading, error } = useGoogleSheets({
    apiKey: "AIzaSyBq0SkvM391pziy1cL01q_ZN5di68PAMkY",
    sheetId: sheetId,
  });

  const sheets = data as DialogSheet[];

  return (
    <div>
      {loading && <div>Loading...</div>}
      {error && <div>Error! {JSON.stringify(error)}</div>}
      {loading || (
        <Routes>
          <Route
            path="/:dialogId"
            element={<DebugDialogPreview sheets={sheets} sheetId={sheetId} show4MEmrTab={show4MEmrTab} />}
          />
          <Route
            path="/"
            element={<DialogDebugConfigSheet sheets={sheets} />}
          />
        </Routes>
      )}
    </div>
  );
};

const DebugDialogPreview: React.FC<{
  sheets: DialogSheet[];
  sheetId: string;
  show4MEmrTab: boolean;
}> = ({ sheets, sheetId, show4MEmrTab }) => {
  const { dialogId } = useParams();
  const [searchParams] = useSearchParams();
  const [dialog, setDialog] = useState<Dialog | null>(null);

  const idleVideo = searchParams.get("idle") || "/dialog_videos/cmo/idle.mp4";

  useEffect(() => {
    if (!sheets.find((s) => s.id === dialogId)) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { out, errors } = dialogImport(
      sheets.find((s) => s.id === dialogId)!
    );
    // TODO - show errors
    setDialog(out);
  }, [dialogId, sheets]);

  const exportDialog = useCallback(() => {
    const downloadBlob = new Blob([JSON.stringify(dialog, null, 2)]);
    const blobUrl = URL.createObjectURL(downloadBlob);
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = sheetId + ".json";

    // Append link to the body
    document.body.appendChild(link);

    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    document.body.removeChild(link);

    Object.values(dialog?.nodes || {}).forEach((node: DialogNode) => {
      node.prompts.forEach((prompt) => {
        console.info(createTTSCommand(prompt.txt));
      });
    });
  }, [dialog, sheetId]);

  return (
    <div>
      {dialog && (
        <>
          <hr />
          <Background background={officeBackground} />
          <DialogPlayer
            dialog={dialog}
            showLog={true}
            show4MEmrTab={show4MEmrTab}
            skipAllVoiceovers={true}
            showPromptButtons={true}
            idleSrc={idleVideo}
          />
          <Button
            style={{ position: "fixed", bottom: 0, right: 0, zIndex: 100000 }}
            onClick={exportDialog}
          >
            Export Dialog
          </Button>
        </>
      )}
    </div>
  );
};
