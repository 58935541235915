import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getSims } from "../selectors";
import { AgeBands, Sim } from "../simulation/simulation-types";

interface ReportData {
  age: string;
  // cumulativeIdealFourM: number;
  // cumulativeIdealTreatment: number;
  // cumulativeUserFourM: number;
  // cumulativeUserTreatment: number;
  // cumulativeBauFourM: number;
  // cumulativeBauTreatment: number;
  idealFourM: number;
  idealTreatment: number;
  userFourM: number;
  userTreatment: number;
  bauFourM: number;
  bauTreatment: number;
}
const initial = (): ReportData[] =>
  AgeBands.map((a) => ({
    age: a,
    cumulativeIdealFourM: 0,
    cumulativeIdealTreatment: 0,
    cumulativeUserFourM: 0,
    cumulativeUserTreatment: 0,
    cumulativeBauFourM: 0,
    cumulativeBauTreatment: 0,
    idealFourM: 0,
    idealTreatment: 0,
    userFourM: 0,
    userTreatment: 0,
    bauFourM: 0,
    bauTreatment: 0,
  }));

const calcSimData = (sim: Sim, index: number) => {
  const ageBandIndex = index + 1;
  return {
    age: AgeBands[index],
    // cumulativeIdealFourM: sim.idealAgeBands[ageBandIndex]?.fourMCost || 0,
    // cumulativeIdealTreatment:
    //   sim.idealAgeBands[ageBandIndex]?.treatmentCost || 0,
    // cumulativeUserFourM: sim.ageBands[ageBandIndex]?.fourMCost || 0,
    // cumulativeUserTreatment: sim.ageBands[ageBandIndex]?.treatmentCost || 0,
    // cumulativeBauFourM: 0,
    // cumulativeBauTreatment: sim.bauAgeBands[ageBandIndex]?.treatmentCost || 0,

    idealFourM: sim.idealAgeBands[ageBandIndex]?.fourMCost
      ? (sim.idealAgeBands[ageBandIndex]?.fourMCost || 0) -
          sim.idealAgeBands[ageBandIndex - 1]?.fourMCost || 0
      : 0,
    idealTreatment: sim.idealAgeBands[ageBandIndex]?.treatmentCost
      ? (sim.idealAgeBands[ageBandIndex]?.treatmentCost || 0) -
        (sim.idealAgeBands[ageBandIndex - 1]?.treatmentCost || 0)
      : 0,
    userFourM: sim.ageBands[ageBandIndex]?.fourMCost
      ? (sim.ageBands[ageBandIndex]?.fourMCost || 0) -
        (sim.ageBands[ageBandIndex - 1]?.fourMCost || 0)
      : 0,
    userTreatment: sim.ageBands[ageBandIndex]?.treatmentCost
      ? (sim.ageBands[ageBandIndex]?.treatmentCost || 0) -
        (sim.ageBands[ageBandIndex - 1]?.treatmentCost || 0)
      : 0,
    bauFourM: 0,
    bauTreatment: sim.bauAgeBands[ageBandIndex]?.treatmentCost
      ? (sim.bauAgeBands[ageBandIndex]?.treatmentCost || 0) -
        (sim.bauAgeBands[ageBandIndex - 1]?.treatmentCost || 0)
      : 0,
  };
};

const calculateData = (sims: Sim[]) => {
  // Per age band:
  return sims.reduce<ReportData[]>((acc: ReportData[], sim: Sim) => {
    return acc.map((previous: ReportData, index: number) => {
      const deltaForThisSim = calcSimData(sim, index);
      return {
        age: previous.age,
        // cumulativeIdealFourM:
        //   previous.cumulativeIdealFourM + deltaForThisSim.cumulativeIdealFourM,
        // cumulativeIdealTreatment:
        //   previous.cumulativeIdealTreatment +
        //   deltaForThisSim.cumulativeIdealTreatment,
        // cumulativeUserFourM:
        //   previous.cumulativeUserFourM + deltaForThisSim.cumulativeUserFourM,
        // cumulativeUserTreatment:
        //   previous.cumulativeUserTreatment +
        //   deltaForThisSim.cumulativeUserTreatment,
        // cumulativeBauFourM:
        //   previous.cumulativeBauFourM + deltaForThisSim.cumulativeBauFourM,
        // cumulativeBauTreatment:
        //   previous.cumulativeBauTreatment +
        //   deltaForThisSim.cumulativeBauTreatment,
        idealFourM: previous.idealFourM + deltaForThisSim.idealFourM,
        idealTreatment:
          previous.idealTreatment + deltaForThisSim.idealTreatment,
        userFourM: previous.userFourM + deltaForThisSim.userFourM,
        userTreatment: previous.userTreatment + deltaForThisSim.userTreatment,
        bauFourM: previous.bauFourM + deltaForThisSim.bauFourM,
        bauTreatment: previous.bauTreatment + deltaForThisSim.bauTreatment,
      };
    });
  }, initial());
};

// Per year:
//   return Array(30)
//     .fill(0)
//     .map((_, year) => ({
//       age: year + 65,
//       idealFourM: sim.idealYears[year]?.fourMCost || 0,
//       idealTreatment: sim.idealYears[year]?.treatmentCost || 0,
//       userFourM: sim.userYears[year]?.fourMCost || 0,
//       userTreatment: sim.userYears[year]?.treatmentCost || 0,
//       bauFourM: 0,
//       bauTreatment: sim.bauYears[year]?.treatmentCost || 0,
//     }));

/**
 *  Annual bar chart of costs with stacked bars for 4m + treatment cost
 */
export const DebugPopulationCost: React.FC<{}> = () => {
  const sims = useSelector(getSims);
  const data = useMemo(() => calculateData(sims), [sims]);

  return (
    <BarChart width={930} height={250} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="age" />
      <YAxis />
      <Legend />

      <Tooltip />
      {/* <Bar stackId="c" fill="#144b7f" label="bauFourM" dataKey="bauFourM" /> */}
      <Bar
        stackId="c"
        fill="#85a1bb"
        label="bauTreatment"
        dataKey="bauTreatment"
      />
      <Bar stackId="b" fill="#772acb" label="userFourM" dataKey="userFourM" />
      <Bar
        stackId="b"
        fill="#be9de2"
        label="userTreatment"
        dataKey="userTreatment"
      />

      <Bar stackId="a" fill="#1866b4" label="idealFourM" dataKey="idealFourM" />
      <Bar
        stackId="a"
        fill="#93bfeb"
        label="idealTreatment"
        dataKey="idealTreatment"
      />
    </BarChart>
  );
};
