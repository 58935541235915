import React from "react";
import { useSimDetails } from "./useSimDetail";

export const DebugSimPortrait: React.FC<{
  simId: string;
  width: number;
  style?: any;
  onClick?: () => void;
}> = ({ simId, width, style, onClick }) => {
  const details = useSimDetails(simId);

  return (
    details && (
      <img
        onClick={onClick}
        alt={`Portrait of ${details.name}`}
        style={style}
        width={width}
        height={width}
        src={details.pic}
      />
    )
  );
};
