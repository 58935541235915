import { Medication } from "../../data/emr";
import styled from "@emotion/styled";
import { IconInfo } from "../IconInfo";



const Item = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.45rem 1rem;
  border-bottom: 1px solid #cdd1c4;
  position: relative;
  svg {
    position: absolute;
    right: 1rem;
    top: 10px;
    
  }
`;


export const EMRMedications: React.FC<{ medications: Medication[] }> = ({
  medications,
}) => {
  return (
    <>
      {medications.map((medication, index) => {
        return (
          <Item key={index}>
            {medication.name}
            <IconInfo />
          </Item>
        );
      })}
    </>
  );
};
