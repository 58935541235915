import React, { FormEvent, useCallback, useState } from "react";
import { DialogSheet } from "../DialogSheet";
import { Link } from "react-router-dom";

interface Props {
  sheets: DialogSheet[];
}

const idleOptions = [
  "/dialog_videos/cmo/idle.mp4",
  "/dialog_videos/donna/donna_idle.mp4",
  "/dialog_videos/geron/geron_idle.mp4",
];

export const DialogDebugConfigSheet: React.FC<Props> = ({ sheets }) => {
  const sheetIds = sheets.map((sheet) => sheet.id);
  const [currentSheet, setCurrentSheet] = useState(sheetIds[0]);
  const [currentIdle, setCurrentIdle] = useState(idleOptions[0]);
  const [show4MEmrTab, setShow4MEmrTab] = useState(false);
  console.info("!!!", currentSheet, sheetIds[0]);
  const onIdleSelect = useCallback((e: FormEvent<HTMLSelectElement>) => {
    setCurrentIdle(e.currentTarget.value);
  }, []);

  const onSheetSelect = useCallback((e: FormEvent<HTMLSelectElement>) => {
    setCurrentSheet(e.currentTarget.value);
  }, []);

  const onShow4MEmrTabChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setShow4MEmrTab(e.target.checked);
  }, []);

  const dialogUrl = `${currentSheet || ""}?idle=${currentIdle || ""}&show4MEmrTab=${show4MEmrTab}`;

  return (
    <div>
      <div className="form-group">
        <label htmlFor="sheetId" className="form-label">
          Sheet to preview
        </label>
        <select
          className="form-control"
          id="sheetId"
          value={currentSheet}
          onChange={onSheetSelect}
        >
          {sheetIds.map((sheetId) => (
            <option key={sheetId} value={sheetId}>
              {sheetId}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="sheetId" className="form-label">
          Idle Animation
        </label>
        <select
          className="form-control"
          id="idel"
          value={currentIdle}
          onChange={onIdleSelect}
        >
          {idleOptions.map((idleOption) => (
            <option key={idleOption} value={idleOption}>
              {idleOption}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="show4MEmrTab" className="form-label">
          <input
            type="checkbox"
            id="show4MEmrTab"
            checked={show4MEmrTab}
            onChange={onShow4MEmrTabChange}
          />
          {" Show 4M EMR Tab"}
        </label>
      </div>

      <hr />
      <Link to={dialogUrl}>
        <button className="btn btn-primary">Let's Converse!</button>
      </Link>
    </div>
  );
};
