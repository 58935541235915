import styled from "@emotion/styled";

const NPCVideoPlayer = styled.video<{ visible: boolean; thumbnail: boolean }>(
  ({ visible, thumbnail }) => {
    return {
      position: "absolute",
      bottom: 0,
      right: 0,
      width: "100%",
      height: "100%",

      objectFit: "cover",
      opacity: visible ? 1 : 0,
      transition: "all 0.5s ease-in-out",
      ...(thumbnail && {
        width: 300,
        height: 200,
      }),
    };
  }
);

interface NPCVideoProps {
  visible: boolean;
  thumbnail: boolean;
  src: string;
  idleSrc: string;
  autoPlay: boolean;
  onEnded?: () => void;
}

export const NPCVideo: React.FC<NPCVideoProps> = ({
  visible,
  thumbnail,
  src,
  idleSrc,
  autoPlay,
  onEnded,
}) => {
  return (
    <>
      <NPCVideoPlayer
        src={idleSrc}
        thumbnail={thumbnail}
        autoPlay
        loop
        muted
        visible={visible}
      />

      <NPCVideoPlayer
        src={src}
        thumbnail={thumbnail}
        autoPlay
        visible={!!src && visible}
        onEnded={onEnded}
      />
    </>
  );
};
