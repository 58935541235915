// Generated from SVG to Code Figma Plugin
import React from "react";

export const Iconcheck = () => (
  <svg
    height="16"
    width="16"
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.36287 11.5417C6.28509 11.5417 6.2102 11.5277 6.1382 11.4997C6.06576 11.4721 6.00176 11.4306 5.9462 11.375L3.16287 8.59168C3.06287 8.49168 3.01553 8.37212 3.02087 8.23301C3.02664 8.09434 3.07398 7.97501 3.16287 7.87501C3.26287 7.77501 3.38242 7.72501 3.52153 7.72501C3.6602 7.72501 3.77398 7.77501 3.86287 7.87501L6.36287 10.375L12.1295 4.60834C12.2184 4.50834 12.3351 4.45834 12.4795 4.45834C12.624 4.45834 12.7406 4.50834 12.8295 4.60834C12.9295 4.70834 12.9795 4.82768 12.9795 4.96634C12.9795 5.10545 12.9295 5.22501 12.8295 5.32501L6.77953 11.375C6.72398 11.4306 6.6602 11.4721 6.5882 11.4997C6.51576 11.5277 6.44064 11.5417 6.36287 11.5417Z"
      fill="#4DA100"
    />
  </svg>
);
