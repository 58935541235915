import styled from "@emotion/styled";

export const Aside = styled.div({
  backgroundColor: "#fff7d0",
  borderLeft: "4px solid #ffe564",
  padding: "15px 5px 5px 15px",
  marginLeft: 20,
  marginRight: 120,
  marginBottom: 20,
});
