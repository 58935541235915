import * as React from "react"

function LibraryIcon(props: any) {
    return (
        <svg
            width={36}
            height={36}
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1526_9518)">
                <path
                    d="M9 29.25v3.375c0 .619-.506 1.125-1.125 1.125h-4.5a1.128 1.128 0 01-1.125-1.125V29.25H9zM7.875 36c.865 0 1.652-.323 2.25-.858a3.362 3.362 0 002.25.858h4.5a3.376 3.376 0 003.375-3.375V13.627l3.614 13.929.281 1.09 1.266 4.865c.464 1.793 2.25 2.855 3.987 2.377l4.19-1.16c1.737-.479 2.764-2.32 2.3-4.114l-.978-3.776-.281-1.09-4.493-17.296-.281-1.09-1.266-4.866c-.464-1.8-2.25-2.862-3.987-2.383l-4.19 1.16c-.246.07-.478.161-.696.281A3.363 3.363 0 0016.875 0h-4.5c-.865 0-1.652.324-2.25.858A3.362 3.362 0 007.875 0h-4.5A3.376 3.376 0 000 3.375v29.25A3.376 3.376 0 003.375 36h4.5zM20.25 4.556v-.091c.021-.506.359-.914.76-1.027l4.19-1.16c.464-.126 1.04.14 1.21.774l.99 3.81-6.137 1.702-.97-3.74a1.902 1.902 0 01-.043-.268zM18 4.514V6.75h-6.75V3.375c0-.619.506-1.125 1.125-1.125h4.5c.619 0 1.125.506 1.125 1.125v1.14zM12.375 33.75a1.128 1.128 0 01-1.125-1.125V29.25H18v3.375c0 .619-.506 1.125-1.125 1.125h-4.5zM9 9v18H2.25V9H9zm0-5.625V6.75H2.25V3.375c0-.619.506-1.125 1.125-1.125h4.5C8.494 2.25 9 2.756 9 3.375zM18 27h-6.75V9H18v18zm9.963-17.95l4.205 16.206-6.138 1.702-4.205-16.214 6.138-1.702v.007zm4.774 18.386l.97 3.74c.17.662-.224 1.245-.723 1.379l-4.191 1.16c-.464.127-1.04-.14-1.21-.774l-.99-3.81 6.137-1.702.008.007z"
                    fill="#fff"
                />
            </g>
            <defs>
                <clipPath id="clip0_1526_9518">
                    <path fill="#fff" d="M0 0H36V36H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LibraryIcon
