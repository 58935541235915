import React, { useMemo } from "react";
import cn from "comma-number";
import styled from "@emotion/styled";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { lastAgeBand } from "../simulation/sim";
import { Conditions, Sim } from "../simulation/simulation-types";
import format from "format-number";
import { getConstant, getShowIdeal } from "../selectors";
import { useSelector } from "react-redux";
import { plusSign } from "../util/stringUtil";
import { RootState } from "../root-store";
import { Aside } from "./Aside";
interface ChartDatapoint {
  bauCost: number;
  idealCost: number;
  userCost: number;

  bauYears: number;
  idealYears: number;
  userYears: number;
}
const dollars = format({ round: 2 });
const years = format({});

const Diff = styled.div({ fontStyle: "italic" });

export const DebugSavingsChart: React.FC<{
  sims: Sim[];
  condition: Conditions;
  ageBandIndex: number;
}> = React.memo(({ sims, condition, ageBandIndex }) => {
  const data = useMemo<ChartDatapoint>(() => {
    return sims.reduce<ChartDatapoint>(
      (acc: ChartDatapoint, curr: Sim) => {
        let { treatmentCost, age } = lastAgeBand(
          ageBandIndex,
          curr.bauAgeBands
        );
        acc.bauYears += age - 65;
        acc.bauCost += treatmentCost;

        const userAgeBand = lastAgeBand(ageBandIndex, curr.ageBands);
        acc.userCost += userAgeBand.treatmentCost + userAgeBand.fourMCost;
        acc.userYears += userAgeBand.age - 65;

        const idealBand = lastAgeBand(ageBandIndex, curr.idealAgeBands);
        acc.idealCost += idealBand.treatmentCost + idealBand.fourMCost;
        acc.idealYears += idealBand.age - 65;

        return acc;
      },
      {
        bauCost: 0,
        idealCost: 0,
        userCost: 0,
        bauYears: 0,
        idealYears: 0,
        userYears: 0,
      }
    );
  }, [ageBandIndex, sims]);
  const showIdeal = useSelector(getShowIdeal);
  const revenue: number =
    useSelector((state: RootState) =>
      getConstant(state, "AnnualRevenuePerPatient")
    )?.value || 1;

  const bauCost = data.bauCost;
  const userCost = data.userCost;
  const bauRevenue = revenue * data.bauYears;
  const userRevenue = revenue * data.userYears;
  const bauProfit = bauRevenue - bauCost;
  const userProfit = userRevenue - userCost;

  return (
    <div>
      <p>
        You saved the health care system{" "}
        <b>${dollars(userProfit - bauProfit)}</b> compared to business as usual
        by using the 4M's
      </p>
      <table className="table">
        <thead>
          <tr>
            <th>Model</th>
            <th>Overall Cost</th>
            <th>Overall Revenue</th>
            <th>System Profit</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>BAU</td>
            <td>${dollars(bauCost)}</td>
            <td>${dollars(bauRevenue)}</td>
            <td>${dollars(bauProfit)}</td>
          </tr>

          <tr>
            <td>User</td>
            <td>
              ${dollars(userCost)}
              <Diff>
                ({plusSign(userCost - bauCost)}
                {dollars(userCost - bauCost)})
              </Diff>
            </td>

            <td>
              ${dollars(userRevenue)}
              <Diff>
                ({plusSign(userRevenue - bauRevenue)}
                {dollars(userRevenue - bauRevenue)})
              </Diff>
            </td>
            <td>
              ${dollars(userProfit)}
              <Diff>
                ({plusSign(userProfit - bauProfit)}
                {dollars(userProfit - bauProfit)})
              </Diff>
            </td>
          </tr>
        </tbody>
      </table>
      {/* BaU: ${dollars(data.bauCost / data.bauYears)}, (${dollars(data.bauCost)} /{" "}
      {dollars(data.bauYears)}) User: ${dollars(data.userCost / data.userYears)}
      , (${dollars(data.userCost)} / {dollars(data.userYears)}) Ideal: $
      {dollars(data.idealCost / data.idealYears)} (${dollars(data.idealCost)} /{" "}
      {dollars(data.idealYears)})<h3>Total Cost</h3>
      BaU: ${cn(data.bauCost)}, User: ${cn(data.userCost)}, Ideal: $
      {cn(data.idealCost)} */}
    </div>
  );
  // (
  //   <BarChart
  //     width={600}
  //     height={300}
  //     data={data}
  //     margin={{
  //       top: 15,
  //       right: 30,
  //       left: 20,
  //       bottom: 5,
  //     }}
  //   >
  //     <CartesianGrid strokeDasharray="3 3" />
  //     <XAxis dataKey="name" />
  //     <YAxis />
  //     <Tooltip />
  //     <Legend />
  //     <Bar dataKey="bau" fill="#073b4c" />
  //     <Bar dataKey="user" fill="#06d6a0" />
  //     <Bar dataKey="ideal" fill="#118ab2" />
  //     {/* ffd166 ef476f */}
  //   </BarChart>
  // );
});
