import React from "react";
import { MaybeTreatment } from "../simulation/simulation-types";
import { Treatment } from "../treatments";

export const TreatmentPicker: React.FC<{
  selected: MaybeTreatment;
  treatments: Treatment[];
  onChange: (treatment: Treatment | null) => void;
}> = ({ selected, treatments, onChange }) => (
  <select
    className="form-select"
    value={selected ? selected.name : "empty"}
    onChange={(event) => {
      const treatmentIndex = event.target.selectedIndex - 1;
      const treatment = treatmentIndex < 0 ? null : treatments[treatmentIndex];
      onChange(treatment);
    }}
    style={{ width: 400, display: "block" }}
  >
    <option value="empty">None</option>
    {treatments.map((treatment, index) => (
      <option key={index} value={treatment.name}>
        {treatment.category} / {treatment.name}
      </option>
    ))}
  </select>
);
