import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { saveGameState } from "./state/saveGameService";
import { gameStateAtom, initialGameState } from "./state/gameStateAtom";
import { useRecoilState } from "recoil";

export const DebugMenu: React.FC = () => {
  const [gameState, setGameState] = useRecoilState(gameStateAtom);
  const debugStage = gameState.currentStage;
  const debugStep = gameState.stages.find(
    (s) => s.id === debugStage
  )?.currentStep;
  const debugLabel = `Current Step: ${debugStage} - ${debugStep}`;

  return (
    <div
      style={{ position: "fixed", right: 15, top: 5, width: 40, zIndex: 1000 }}
    >
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          🪲
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <NavLink to="/play">
            <Dropdown.Item href="/play">CEO brief</Dropdown.Item>
          </NavLink>
          <NavLink to="/play/assessments">
            <Dropdown.Item href="/play/assessments">Assessments</Dropdown.Item>
          </NavLink>
          <NavLink to="/play/assessmentresults">
            <Dropdown.Item href="/play/assessmentresults">
              Assessment Results
            </Dropdown.Item>
          </NavLink>
          <NavLink to="/play/interventions">
            <Dropdown.Item href="/play/interventions">
              Interventions
            </Dropdown.Item>
          </NavLink>
          <NavLink to="/play/interventionresults">
            <Dropdown.Item href="/play/interventionresults">
              Intervention Results
            </Dropdown.Item>
          </NavLink>
          <NavLink to="/play/casestudy">
            <Dropdown.Item href="/play/casestudy">Case Study</Dropdown.Item>
          </NavLink>
          <NavLink to="/play/simresults">
            <Dropdown.Item href="/play/simresults">Sim Results</Dropdown.Item>
          </NavLink>
          <NavLink to="/play/wrapup">
            <Dropdown.Item href="/play/wrapup">Wrapup</Dropdown.Item>
          </NavLink>

          <Dropdown.Divider />

          <NavLink to="/debug">
            <Dropdown.Item href="/debug">
              Debug visualization screen
            </Dropdown.Item>
          </NavLink>

          <NavLink to="/play/assessments-old">
            <Dropdown.Item href="/play/assessments">
              Assessments (Old Version)
            </Dropdown.Item>
          </NavLink>

          <NavLink to="/dialog">
            <Dropdown.Item href="/dialog">Dialog Debugger</Dropdown.Item>
          </NavLink>

          <Dropdown.Divider />
          <Dropdown.Item>{debugLabel}</Dropdown.Item>

          <Dropdown.Item
            onClick={() => {
              setGameState(initialGameState);
              saveGameState(initialGameState);
            }}
          >
            Reset Game State
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
