// Generated from SVG to Code Figma Plugin
import React from "react";

export const IconHistory = () => (
  <svg
    height="32"
    width="40"
    fill="none"
    viewBox="0 0 40 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8.25V3C6 2.45 5.55 2 5 2C4.45 2 4 2.45 4 3V11C4 11.55 4.45 12 5 12H13C13.55 12 14 11.55 14 11C14 10.45 13.55 10 13 10H7.35C9.59375 5.26875 14.4187 2 20 2C27.7313 2 34 8.26875 34 16C34 23.7313 27.7313 30 20 30C15.4187 30 11.3563 27.8 8.8 24.4C8.46875 23.9562 7.84375 23.8688 7.4 24.2C6.95625 24.5312 6.86875 25.1562 7.2 25.6C10.1188 29.4875 14.7625 32 20 32C28.8375 32 36 24.8375 36 16C36 7.1625 28.8375 0 20 0C13.975 0 8.73125 3.33125 6 8.25ZM20 8C19.45 8 19 8.45 19 9V16C19 16.2625 19.1062 16.5187 19.2937 16.7062L24.2938 21.7062C24.6813 22.0938 25.3187 22.0938 25.7062 21.7062C26.0938 21.3187 26.0938 20.6813 25.7062 20.2938L21 15.5875V9C21 8.45 20.55 8 20 8Z"
      fill="black"
    />
  </svg>
);
