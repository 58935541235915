import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getCurrentAgeBand } from "../selectors";
import { Sim, SimAgeBand } from "../simulation/simulation-types";
import { DebugSimPortrait } from "./DebugSimPortrait";
import { getConditionFromYear } from "../simulation/sim";

const lastQol = (ageBands: SimAgeBand[]): number =>
  ageBands[ageBands.length - 1].qolScore;

const conditionInterestingScore = (sim: Sim, ageBandIndex: number): number => {
  let s = 0;
  for (
    let i = 0;
    i < Math.min(sim.userYears.length, sim.bauYears.length);
    i++
  ) {
    s +=
      getConditionFromYear(sim.userYears[i], "frail") -
      getConditionFromYear(sim.bauYears[i], "frail");
  }
  return s;
};

const qolInterestingScore = (sim: Sim): number => {
  return Math.max(
    Math.abs(lastQol(sim.ageBands) - lastQol(sim.bauAgeBands))
    //Math.abs(lastQol(sim.idealAgeBands) - lastQol(sim.bauAgeBands))
  );
};

interface DIPProps {
  setCurrent: (sim: Sim) => void;
  sims: Sim[];
}
export const DebugInterestingPeople: React.FC<DIPProps> = ({
  sims,
  setCurrent,
}) => {
  const ageBandIndex = useSelector(getCurrentAgeBand);
  const qolInteresting = useMemo(() => {
    const sorted = [...sims]
      .filter((s) => qolInterestingScore(s) > 0)
      .sort((a, b) => qolInterestingScore(b) - qolInterestingScore(a));
    return sorted.slice(0, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sims, ageBandIndex]);

  const conditionInteresting = useMemo(() => {
    const sorted = [...sims]
      .filter((s) => conditionInterestingScore(s, ageBandIndex) > 0)
      .sort(
        (a, b) =>
          conditionInterestingScore(b, ageBandIndex) -
          conditionInterestingScore(a, ageBandIndex)
      );
    return sorted.slice(0, 10);
  }, [ageBandIndex, sims]);

  return (
    <div>
      {conditionInteresting.length === 0 && (
        <div>No interesting people yet. Try running the simulation.</div>
      )}
      <h4>By Condition Progression:</h4>
      {conditionInteresting.map((s) => (
        <DebugSimPortrait
          key={s.id}
          onClick={() => setCurrent(s)}
          width={50}
          simId={s.id}
        />
      ))}
      <h4>By QoL:</h4>
      {qolInteresting.map((s) => (
        <DebugSimPortrait
          key={s.id}
          onClick={() => setCurrent(s)}
          width={50}
          simId={s.id}
        />
      ))}
    </div>
  );
};
