export const DialogPalette = {
  lightBlue: "#7bdff2",
  darkBlue: "#003459",
  mediumBlue: "#00171f",
  lightTan: "#d3bdb0X",
  darkTan: "#c1ae9f",

  yellow: "#e8c547",
  darkGrey: "#30323d",
  lightGrey: "#4d5061",
  paleBlue: "#5c80bc",
  lightestGrey: "#cdd1c4",
};

export const dialogOptionsBackgroundGradient = `background: linear-gradient(270deg, rgba(0, 52, 89, 0.70) 0%, ${DialogPalette.darkBlue} 100%);`;
