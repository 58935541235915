import { Action, createSlice } from "@reduxjs/toolkit";
import { DialogDataTypes } from "../simulation/firedata";

interface GameState {
  showIdeal: boolean;
  simulationDataDirty: boolean;
  dialogData: Record<string, DialogDataTypes>;
}

const initial: GameState = {
  showIdeal: false,
  simulationDataDirty: false, // Should we save the sim datafile next save?
  dialogData: {},
};

const isSimStateChange = (action: Action) => {
  return action.type.startsWith("sim/") || action.type.startsWith("simulator/");
};

export const gameSlice = createSlice({
  name: "game",
  initialState: initial,
  reducers: {
    toggleIdeal: (state) => {
      state.showIdeal = !state.showIdeal;
    },
    markSimClean: (state) => {
      state.simulationDataDirty = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isSimStateChange, (state, action) => {
      state.simulationDataDirty = true;
    });
  },
});

export const { toggleIdeal, markSimClean } = gameSlice.actions;

export default gameSlice.reducer;
