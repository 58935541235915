import React from "react";
import { Sim, SimAgeBand } from "../simulation/simulation-types";
import styled from "@emotion/styled";
import { lastAgeBand } from "../simulation/sim";

import { DebugSimCost } from "./DebugSimCost";
import { useSimDetails } from "./useSimDetail";
import { DebugSimPortrait } from "./DebugSimPortrait";
import format from "format-number";

const qolFormat = format({ round: 2 });

const AgeBand: React.FC<{
  label: string;
  ageBand: SimAgeBand;
  show4MCost: boolean;
}> = ({ ageBand, show4MCost, label }) => {
  return (
    <tr>
      <td>{label}</td>
      <td>
        {ageBand.age}
        {ageBand.alive || (
          <>
            <br />
            (Deceased)
          </>
        )}
      </td>
      <td>{qolFormat(ageBand.qolScore)}</td>

      <td>
        <ul>
          {Object.keys(ageBand.conditionYears)
            .filter((condition) => condition.startsWith("frail"))
            .map((condition) => (
              <li>
                {condition}: {ageBand.conditionYears[condition]} years
              </li>
            ))}
        </ul>
      </td>

      <td>
        {Math.round(ageBand.fourMCost)} + {Math.round(ageBand.treatmentCost)} =
        {Math.round(ageBand.fourMCost) + Math.round(ageBand.treatmentCost)}
      </td>
    </tr>
  );
};

const Columns = styled.div({ display: "flex", gap: 10 });
const Left = styled.div({
  flexGrow: 0,
});
const Right = styled.div({
  flexGrow: 1,
});

const Person = styled.div({
  margin: 15,
  backgroundColor: "#f0f0f0",
  padding: 15,
});

export const DebugSimDetails: React.FC<{ sim: Sim }> = ({ sim }) => {
  const detail = useSimDetails(sim.id);

  return (
    <Person>
      <h1>{detail?.name}</h1>
      <Columns>
        <Left>
          {detail && (
            <React.Fragment>
              <DebugSimPortrait simId={sim.id} width={200} />
            </React.Fragment>
          )}
        </Left>
        <Right>
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Model</th>
                <th>Age</th>
                <th>QoL Score</th>
                <th>Condition Years</th>
                <th>Cost (4M + Treatment)</th>
              </tr>
            </thead>
            <tbody>
              <AgeBand
                label="BaU"
                show4MCost={false}
                ageBand={lastAgeBand(99, sim.bauAgeBands)}
              />
              {/* <AgeBand
                label="Ideal"
                show4MCost={true}
                ageBand={lastAgeBand(99, sim.idealAgeBands)}
              /> */}
              <AgeBand
                label="User"
                show4MCost={true}
                ageBand={lastAgeBand(99, sim.ageBands)}
              />
            </tbody>
          </table>
        </Right>
      </Columns>
      <Columns>
        <Left>{/* <DebugSimQolChart sim={sim} /> */}</Left>
        <Right>
          <DebugSimCost sim={sim} />
        </Right>
      </Columns>
    </Person>
  );
};
