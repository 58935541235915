import * as React from "react"

function StartIcon(props: any) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1526_9585)">
                <path
                    d="M12 1.5a10.5 10.5 0 110 21 10.5 10.5 0 010-21zM12 24a12 12 0 100-24 12 12 0 000 24zm3.408-12L9.75 15.455V8.54L15.408 12zM9.96 6.914a1.137 1.137 0 00-1.14-.023 1.124 1.124 0 00-.576.98v8.25a1.126 1.126 0 001.716.956l6.75-4.125a1.13 1.13 0 00.539-.961 1.13 1.13 0 00-.54-.961L9.96 6.905v.01z"
                    fill="#00171F"
                />
            </g>
            <defs>
                <clipPath id="clip0_1526_9585">
                    <path fill="#fff" d="M0 0H24V24H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default StartIcon
