// Generated from SVG to Code Figma Plugin
import React from "react";

export const IconSnapshot = () => (
    <svg height="32" width="40" fill="none" viewBox="0 0 40 32" xmlns="http://www.w3.org/2000/svg">
	<g clipPath="url(#clip0_999_67)">
		<path d="M12 2V6H16V2H12ZM10 0H12H16H18V2V6V7.33125L19.6688 12.125C20.1 12.0437 20.5438 12 21 12C22.9813 12 24.7625 12.8187 26.0375 14.1375L32 9.85V6V4H34H38H40V6V10V12H38H34H32.4313L27.2062 15.7625C27.7125 16.7313 28 17.8312 28 19C28 19.7687 27.875 20.5125 27.6437 21.2062L33.2375 24H34H38H40V26V30V32H38H34H32V30V26V25.6187L26.75 22.9937C25.4875 24.8125 23.3813 26 21 26C17.475 26 14.5563 23.3937 14.0688 20H8V21V23H6H2H0V21V17V15H2H6H8V17V18H14.0688C14.3938 15.725 15.8125 13.8 17.775 12.7812L16.1125 8H16H12H10V6V2V0ZM21 24C22.3261 24 23.5979 23.4732 24.5355 22.5355C25.4732 21.5979 26 20.3261 26 19C26 17.6739 25.4732 16.4021 24.5355 15.4645C23.5979 14.5268 22.3261 14 21 14C19.6739 14 18.4021 14.5268 17.4645 15.4645C16.5268 16.4021 16 17.6739 16 19C16 20.3261 16.5268 21.5979 17.4645 22.5355C18.4021 23.4732 19.6739 24 21 24ZM6 17H2V21H6V17ZM38 6H34V10H38V6ZM34 26V30H38V26H34Z" fill="white"/>
	</g>
	<defs>
		<clipPath id="clip0_999_67">
			<rect height="32" width="40" fill="white"/>
		</clipPath>
	</defs>
</svg>
);
