import React, { useCallback } from "react";
import sample from "lodash/sample";
import maxBy from "lodash/maxBy";

import { TreatmentPicker } from "./TreatmentPicker";

import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentTreatmentOption,
  getMaxImpact,
  getYourImpact,
} from "../selectors";
import {
  ConditionsOrHealthy,
  MaybeTreatment,
} from "../simulation/simulation-types";
import { RootState, useAppDispatch } from "../root-store";
import { Treatment } from "../treatments";
import { makeTreatmentSelection } from "../simulation/simulation-slice";
import styled from "@emotion/styled";

import { ageBands } from "../constants";
import { FiveMNames } from "../five-ms";
import { Button, ButtonGroup } from "react-bootstrap";
import { advanceSimulation } from "../simulation/simulate-worker-thunk";

type BandContainerProps = { disabled: boolean };

const OptionsContainer = styled.div(({ disabled }: BandContainerProps) => ({
  display: "flex",
  pointerEvents: disabled ? "none" : "auto",
  margin: 10,
  opacity: disabled ? 0.5 : 1,
}));

const BandContainer = styled.div({ margin: 10 });
const Picker: React.FC<{
  ageBandIndex: number;
  category: string;
  condition: ConditionsOrHealthy;
  treatmentIndex: number;
  treatments: Treatment[];
}> = React.memo(
  ({ ageBandIndex, category, condition, treatmentIndex, treatments }) => {
    const dispatch = useDispatch();

    const current = useSelector<RootState, MaybeTreatment>(
      (state): MaybeTreatment =>
        getCurrentTreatmentOption(
          state,
          condition,
          ageBandIndex,
          treatmentIndex
        )
    );

    const onPick = (treatment: MaybeTreatment) => {
      dispatch(
        makeTreatmentSelection({
          condition,
          ageBand: ageBandIndex,
          treatmentIndex: treatmentIndex,
          treatment,
        })
      );
    };

    return (
      <TreatmentPicker
        selected={current}
        treatments={treatments.filter((t) => t.category === category)}
        onChange={onPick}
      />
    );
  }
);

interface DebugAgeBandProps {
  ageBandIndex: number;
  condition?: ConditionsOrHealthy;
  treatments: Treatment[];
  disabled: boolean;
  showGrid: boolean;
}

const categories = Object.keys(FiveMNames);

export const DebugAgeBand = ({
  ageBandIndex,
  condition = "frail",
  treatments,
  disabled,
  showGrid,
}: DebugAgeBandProps) => {
  const dispatch = useAppDispatch();

  const maxHealthyImpact = useSelector((state: RootState) =>
    getMaxImpact(state, ageBandIndex, "healthy")
  );
  const yourHealthyImpact = useSelector((state: RootState) =>
    getYourImpact(state, ageBandIndex, "healthy")
  );
  const maxConditionImpact = useSelector((state: RootState) =>
    getMaxImpact(state, ageBandIndex, condition)
  );
  const yourConditionImpact = useSelector((state: RootState) =>
    getYourImpact(state, ageBandIndex, condition)
  );

  const idealize = useCallback(() => {
    for (let catIndex of [0, 1, 2, 3]) {
      const conditionTreatment = maxBy(
        treatments.filter(
          (t) => t.category === Object.keys(FiveMNames)[catIndex]
        ),
        (t) => t.impact[ageBandIndex][condition]
      );

      const healthyTreatment = maxBy(
        treatments.filter(
          (t) => t.category === Object.keys(FiveMNames)[catIndex]
        ),
        (t) => t.impact[ageBandIndex]["healthy"]
      );

      conditionTreatment &&
        dispatch(
          makeTreatmentSelection({
            condition,
            ageBand: ageBandIndex,
            treatmentIndex: catIndex,
            treatment: conditionTreatment,
          })
        );

      healthyTreatment &&
        dispatch(
          makeTreatmentSelection({
            condition: "healthy",
            ageBand: ageBandIndex,
            treatmentIndex: catIndex,
            treatment: healthyTreatment,
          })
        );
    }
  }, [ageBandIndex, condition, dispatch, treatments]);

  const randomize = useCallback(() => {
    for (let catIndex of [0, 1, 2, 3]) {
      const treatment = sample(
        treatments.filter(
          (t) => t.category === Object.keys(FiveMNames)[catIndex]
        )
      );

      treatment &&
        dispatch(
          makeTreatmentSelection({
            condition: "frail",
            ageBand: ageBandIndex,
            treatmentIndex: catIndex,
            treatment,
          })
        );

      treatment &&
        dispatch(
          makeTreatmentSelection({
            condition: "healthy",
            ageBand: ageBandIndex,
            treatmentIndex: catIndex,
            treatment,
          })
        );
    }
  }, [ageBandIndex, dispatch, treatments]);

  const simulate = useCallback(
    () =>
      dispatch(
        advanceSimulation({
          userImpactRatioCondition: yourConditionImpact / maxConditionImpact,
          userImpactRatioHealthy: yourHealthyImpact / maxHealthyImpact,
        })
      ),
    [
      dispatch,
      maxConditionImpact,
      maxHealthyImpact,
      yourConditionImpact,
      yourHealthyImpact,
    ]
  );

  // useEffect(() => {
  //   if (!disabled) {
  //     idealize();
  //     simulate();
  //   }
  // }, [disabled]);

  return (
    <BandContainer>
      <h1>{ageBands[ageBandIndex]}</h1>
      <OptionsContainer disabled={disabled}>
        <div>
          Healthy
          {[0, 1, 2, 3].map((i) => (
            <Picker
              key={i}
              category={categories[i]}
              ageBandIndex={ageBandIndex}
              condition="healthy"
              treatmentIndex={i}
              treatments={treatments}
            />
          ))}
          <ul>
            <li>Max Impact: {maxHealthyImpact}</li>
            <li>
              Your Impact: {yourHealthyImpact} &ndash; (
              {Math.round((100 * yourHealthyImpact) / maxHealthyImpact)}% ideal)
            </li>
          </ul>
        </div>
        <div>
          {condition}
          {[0, 1, 2, 3].map((i) => (
            <Picker
              key={i}
              category={categories[i]}
              ageBandIndex={ageBandIndex}
              condition={condition}
              treatmentIndex={i}
              treatments={treatments}
            />
          ))}
          <ul>
            <li>Max Impact: {maxConditionImpact}</li>
            <li>
              Your Impact: {yourConditionImpact} &ndash; (
              {Math.round((100 * yourConditionImpact) / maxConditionImpact)}%
              ideal)
            </li>
          </ul>
        </div>
      </OptionsContainer>

      <ButtonGroup>
        {showGrid || (
          <>
            <Button variant="outline-primary" onClick={randomize}>
              Randomize Selections
            </Button>
            <Button variant="outline-primary" onClick={idealize}>
              Ideal Selections
            </Button>
          </>
        )}
        {disabled || (
          <Button variant="success" onClick={simulate}>
            Simulate
          </Button>
        )}
      </ButtonGroup>
      <hr />
    </BandContainer>
  );
};
