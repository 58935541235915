import React from "react";
import { useSelector } from "react-redux";
import { getShowIdeal } from "../selectors";
import { Conditions, Sim, YearProp } from "../simulation/simulation-types";
import { showSign } from "../util/stringUtil";

type CriteriaFunction = (ageBand: YearProp, s: Sim) => boolean;

const hasConditionProgression =
  (condition: Conditions, level: number) => (yearProp: YearProp, sim: Sim) => {
    for (let i = 1; i < sim[yearProp].length; i++) {
      const previousCondition = sim[yearProp][i - 1].activeConditions.find(
        (c) => c.condition === condition
      );

      const currentCondition = sim[yearProp][i].activeConditions.find(
        (c) => c.condition === condition
      );

      if (
        currentCondition?.level !== previousCondition?.level &&
        currentCondition?.level === level
      )
        return true;
    }

    return false;
  };

const countSims = (
  ageBandProp: YearProp,
  criteria: CriteriaFunction,
  sims: Sim[]
) => sims.filter((sim) => criteria(ageBandProp, sim)).length;

const StatRow: React.FC<{
  sims: Sim[];
  ageBandIndex: number;
  showIdeal: boolean;
  criteria: CriteriaFunction;
  label: String;
}> = ({ showIdeal, label, sims, ageBandIndex, criteria }) => {
  const bauCount = countSims("bauYears", criteria, sims);
  const userCount = countSims("userYears", criteria, sims);
  const idealCount = countSims("idealYears", criteria, sims);

  return (
    <tr>
      <td>{label}</td>
      <td>{showSign(bauCount)}</td>
      <td>
        {showSign(userCount)} ({showSign(userCount - bauCount)})
      </td>

      {showIdeal && (
        <td>
          {showSign(idealCount)} ({showSign(idealCount - bauCount)})
        </td>
      )}
    </tr>
  );
};

interface DPTProps {
  ageBandIndex: number;
  condition: Conditions;
  sims: Sim[];
}
export const DebugProgressionTable: React.FC<DPTProps> = ({
  ageBandIndex,
  condition,
  sims,
}) => {
  const showIdeal = useSelector(getShowIdeal);
  return (
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          <th></th>
          <th>BaU</th>
          <th>User</th>
          {showIdeal && <th>Ideal</th>}
        </tr>
      </thead>
      <tbody>
        {[1, 2, 3, 4].map((level) => (
          <StatRow
            key={level}
            ageBandIndex={ageBandIndex || 0}
            criteria={hasConditionProgression(condition, level)}
            label={`${condition}-${level - 1} ➡️ ${condition}-${level} `}
            sims={sims}
            showIdeal={showIdeal}
          />
        ))}
      </tbody>
    </table>
  );
};
