import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { getShowIdeal } from "../selectors";
import { getConditionSeverity, lastAgeBand } from "../simulation/sim";
import { AgeBandProp, Conditions, Sim } from "../simulation/simulation-types";

interface ChartDatapoint {
  name: string;
  bau: number;
  ideal: number;
  user: number;
}

const getConditionKey = (
  sim: Sim,
  ageBand: AgeBandProp,
  condition: Conditions,
  ageBandIndex: number
) => {
  const band = lastAgeBand(ageBandIndex, sim[ageBand]);
  if (!band.alive) return "deceased";
  const severity = getConditionSeverity(band, condition);
  return `${condition}-${severity}`;
};

type DatapointMap = Record<string, ChartDatapoint>;

const initKey = (acc: DatapointMap, key: string) =>
  acc[key] || {
    name: key,
    bau: 0,
    ideal: 0,
    user: 0,
  };

export const DebugConditionChart: React.FC<{
  sims: Sim[];
  condition: Conditions;
  ageBandIndex: number;
  width?: number;
}> = React.memo(({ sims, condition, ageBandIndex, width = 600 }) => {
  const showIdeal = useSelector(getShowIdeal);
  const data = useMemo<ChartDatapoint[]>(() => {
    const mapped: DatapointMap = sims.reduce((acc: DatapointMap, curr: Sim) => {
      let key = getConditionKey(curr, "bauAgeBands", condition, ageBandIndex);
      acc[key] = initKey(acc, key);
      acc[key].bau += 1;

      key = getConditionKey(curr, "ageBands", condition, ageBandIndex);
      acc[key] = initKey(acc, key);
      acc[key].user += 1;

      key = getConditionKey(curr, "idealAgeBands", condition, ageBandIndex);
      acc[key] = initKey(acc, key);
      acc[key].ideal += 1;

      return acc;
    }, {});

    return Object.values(mapped).sort((a, b) => {
      if (a.name === "deceased") return 1;
      if (b.name === "deceased") return -1;
      return a.name.localeCompare(b.name);
    });
  }, [ageBandIndex, condition, sims]);

  return (
    <BarChart
      width={width}
      height={300}
      data={data}
      margin={{
        top: 15,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="bau" fill="#073b4c" name="BaU" />
      <Bar dataKey="user" fill="#06d6a0" name="Player 4M Choices" />
      {showIdeal && <Bar dataKey="ideal" fill="#118ab2" />}
      {/* ffd166 ef476f */}
    </BarChart>
  );
});
