import React from "react";
import { useSelector } from "react-redux";
import { getCalcProgress } from "../selectors";
import styled from "@emotion/styled";
import { Modal, ProgressBar } from "react-bootstrap";

const Backdrop = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1,
});

export const WorkingMessage = () => {
  const percent = useSelector(getCalcProgress);

  return (
    <Backdrop>
      <Modal show={true} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title style={{ height: "2rem" }}>Simulating</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Simulating population progression.
          <ProgressBar now={percent} />
        </Modal.Body>
      </Modal>
    </Backdrop>
  );
};
