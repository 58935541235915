import { uniq } from "lodash";
import { Medication, Problem, Screening } from "../data/emr";
import styled from "@emotion/styled";
import { Iconcheck } from "./IconCheck";
import { IconWarning } from "./IconWarning";
import { IconDanger } from "./IconDanger";
import { IconInfo } from "./IconInfo";

const CategoryName = styled.div`
  color: #4d5061;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-bottom: 1px solid #cdd1c4;
  padding: 0.45rem 1rem;
`;

const Item = styled.div`
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.45rem 1rem;
  border-bottom: 1px solid #cdd1c4;
  position: relative;
  svg {
    position: absolute;
    right: 1rem;
    top: 10px;
  }
`;

export const EMRComments: React.FC<{ comments: string[] }> = ({ comments }) => {
  return (
    <>
      {comments.map((comment, index) => {
        return <Item key={index}>{comment}</Item>;
      })}
    </>
  );
};
