import * as React from "react"

interface Props {
    open: boolean;
}

const OpenCloseIcon: React.FC<Props> = ({ open }) => {

    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {open && <path
                d="M12.53 17.277a.752.752 0 01-1.06 0l-9-9a.753.753 0 010-1.059.753.753 0 011.06 0l8.47 8.47 8.47-8.47a.753.753 0 011.06 0c.29.29.29.769 0 1.06l-9 9z"
                fill="#fff"
            />}
            {open || <path
                d="M17.277 11.47c.291.291.291.77 0 1.06l-9 9a.753.753 0 01-1.059 0 .753.753 0 010-1.06l8.47-8.47-8.47-8.47a.753.753 0 010-1.06.753.753 0 011.06 0l9 9z"
                fill="#fff"
            />}
        </svg>
    )
}

export default OpenCloseIcon
