export const IconMonitor: React.FC = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1170_8834)">
        <path
          d="M16.75 2C17.6375 2 18.4562 2.46875 18.9062 3.2375L32.4062 26.2375C32.8625 27.0125 32.8625 27.9688 32.4187 28.7437C31.975 29.5187 31.1437 30 30.25 30H3.25C2.35625 30 1.525 29.5187 1.08125 28.7437C0.637497 27.9688 0.643747 27.0062 1.09375 26.2375L14.5937 3.2375C15.0437 2.46875 15.8625 2 16.75 2ZM16.75 10C15.9187 10 15.25 10.6687 15.25 11.5V18.5C15.25 19.3312 15.9187 20 16.75 20C17.5812 20 18.25 19.3312 18.25 18.5V11.5C18.25 10.6687 17.5812 10 16.75 10ZM18.75 24C18.75 23.4696 18.5393 22.9609 18.1642 22.5858C17.7891 22.2107 17.2804 22 16.75 22C16.2196 22 15.7109 22.2107 15.3358 22.5858C14.9607 22.9609 14.75 23.4696 14.75 24C14.75 24.5304 14.9607 25.0391 15.3358 25.4142C15.7109 25.7893 16.2196 26 16.75 26C17.2804 26 17.7891 25.7893 18.1642 25.4142C18.5393 25.0391 18.75 24.5304 18.75 24Z"
          fill="#DCB011"
        />
      </g>
      <defs>
        <clipPath id="clip0_1170_8834">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
