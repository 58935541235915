import { ReactNode } from "react";
import styled from "@emotion/styled";

const Container = styled.div<{gridArea?: string}>`
  display: flex;
  flex-direction: column;
  background: #fff;
  flex: 1;
  flex-basis: 100%;
  grid-area: ${props => props.gridArea};
`;

const Header = styled.div`
  background: #cdd1c4;
  color: #000;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 1rem;
`;

const Body = styled.div`
  background: #fff;
  padding: 0;
  overflow: auto;
`;

export const EMRSection: React.FC<{
  title: string;
  children: ReactNode;
  style?: any;
  gridArea?: string;
}> = ({ title, children, style, gridArea }) => {
  return (
    <Container style={style} gridArea={gridArea}>
      <Header>{title} </Header>
      <Body>{children}</Body>
    </Container>
  );
};
