import styled from "@emotion/styled";

import React, { ReactNode } from "react";

const Switch = styled.div`
  color: #000;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const EMRToggle: React.FC<{
  active?: boolean;
  onClick?: () => void;
  children?: ReactNode;
  autoLabel?: boolean;
}> = ({ active, onClick, children, autoLabel = true }) => {
  const color = active ? "#5C80BC" : "#CDD1C4";

  return (
    <Switch>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="17"
        viewBox="0 0 32 17"
        fill="none"
        style={{ marginRight: 7 }}
      >
        <rect
          y="0.5"
          width="32"
          height="16"
          rx="8"
          fill={color}
          fillOpacity="0.25"
        />
        <circle cx="24" cy="8.5" r="7" fill={color} />
      </svg>
      {autoLabel ? (active ? "Yes" : "No") : ""}
      {children}
    </Switch>
  );
};
