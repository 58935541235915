import { airtableBase } from "./airtable";
import { Conditions } from "./simulation/simulation-types";

export type ConstantConditions = "all" | "healthy" | Conditions;
export type AgeBandOptions = "all" | "65-74" | "75-84" | "85+";

export const ageBands: AgeBandOptions[] = ["65-74", "75-84", "85+"];

export interface Constant {
  name: string;
  condition: ConstantConditions;
  ageBandIndex: number; // -1 for all
  value: number;
  level?: number;
}

const mapConstant = (record: any): Constant => ({
  name: record.get("Name"),
  condition: record.get("Condition"),
  ageBandIndex:
    record.get("Age Band") === "All"
      ? -1
      : ageBands.indexOf(record.get("Age Band")),
  value: record.get("Value"),
  level: record.get("Level"),
});

const mapSimTag = (record: any): SimTagDefinition => ({
  name: record.get("Tag Name"),
  level: toLevelNumber(record.get("Level")),
  percentPopulation: record.get("% of Population"),
  outOf: record.get("Out Of"),
  outOfLevel: toLevelNumber(record.get("Out Of Level")),
});

export type SimTagPopulationTypes =
  | "General Frailty"
  | "Physical Frailty"
  | "Mental Frailty"
  | "Independent";

const toLevelNumber = (level: string): number => {
  switch (level) {
    case "Level 0":
      return 0;
    case "Level 1":
      return 1;
    case "Level 2":
      return 2;
    case "Level 3":
      return 3;
    case "Level 4":
      return 4;
    default:
      return -1;
  }
};

export interface SimTagDefinition {
  name: string;
  level: number;
  percentPopulation: number;
  outOf: SimTagPopulationTypes;
  outOfLevel: number;
}
export const loadSimTags = (): Promise<SimTagDefinition[]> => {
  return new Promise<SimTagDefinition[]>((resolve, reject) => {
    airtableBase("SimTags")
      .select({ view: "Grid view" })
      .all((err, records) => {
        if (err || !records) {
          console.error(err);
          window.alert(
            "Error fetching data from Airtable! See console for details."
          );
          reject();
          return;
        }

        //console.log(`fetched ${records.length} Sim Tags rows`, records);

        resolve(records.map(mapSimTag));
      });
  });
};

export const loadConstants = (): Promise<Constant[]> =>
  new Promise<Constant[]>((resolve, reject) => {
    airtableBase("Constants")
      .select({ view: "Grid view" })
      .all((err, records) => {
        if (err || !records) {
          console.error(err);
          window.alert(
            "Error fetching data from Airtable! See console for details."
          );
          reject();
          return;
        }

        //console.log(`fetched ${records.length} Constants rows`, records);

        resolve(records.map(mapConstant));
      });
  });
