// Generated from SVG to Code Figma Plugin
import React from "react";

export const IconInfo = () => (
  <svg
    height="15"
    width="14"
    fill="none"
    viewBox="0 0 14 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_941_1100)">
      <path
        d="M7 1.375C8.62445 1.375 10.1824 2.02031 11.331 3.16897C12.4797 4.31763 13.125 5.87555 13.125 7.5C13.125 9.12445 12.4797 10.6824 11.331 11.831C10.1824 12.9797 8.62445 13.625 7 13.625C5.37555 13.625 3.81763 12.9797 2.66897 11.831C1.52031 10.6824 0.875 9.12445 0.875 7.5C0.875 5.87555 1.52031 4.31763 2.66897 3.16897C3.81763 2.02031 5.37555 1.375 7 1.375ZM7 14.5C8.85652 14.5 10.637 13.7625 11.9497 12.4497C13.2625 11.137 14 9.35652 14 7.5C14 5.64348 13.2625 3.86301 11.9497 2.55025C10.637 1.2375 8.85652 0.5 7 0.5C5.14348 0.5 3.36301 1.2375 2.05025 2.55025C0.737498 3.86301 0 5.64348 0 7.5C0 9.35652 0.737498 11.137 2.05025 12.4497C3.36301 13.7625 5.14348 14.5 7 14.5ZM5.6875 10.125C5.44688 10.125 5.25 10.3219 5.25 10.5625C5.25 10.8031 5.44688 11 5.6875 11H8.3125C8.55313 11 8.75 10.8031 8.75 10.5625C8.75 10.3219 8.55313 10.125 8.3125 10.125H7.4375V7.0625C7.4375 6.82188 7.24062 6.625 7 6.625H5.90625C5.66563 6.625 5.46875 6.82188 5.46875 7.0625C5.46875 7.30312 5.66563 7.5 5.90625 7.5H6.5625V10.125H5.6875ZM7 5.53125C7.17405 5.53125 7.34097 5.46211 7.46404 5.33904C7.58711 5.21597 7.65625 5.04905 7.65625 4.875C7.65625 4.70095 7.58711 4.53403 7.46404 4.41096C7.34097 4.28789 7.17405 4.21875 7 4.21875C6.82595 4.21875 6.65903 4.28789 6.53596 4.41096C6.41289 4.53403 6.34375 4.70095 6.34375 4.875C6.34375 5.04905 6.41289 5.21597 6.53596 5.33904C6.65903 5.46211 6.82595 5.53125 7 5.53125Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_941_1100">
        <rect
          height="14"
          width="14"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
