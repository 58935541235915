import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import DialogBG from "./DialogBG";

// window.showSlide = (slide:string) => {}

const Img = styled.img({
  position: "absolute",
  top: 30,
  left: 570,
  right: 70,
});

export const Slide: React.FC = () => {
  const [src, setSrc] = useState<string | null>(null);

  useEffect(() => {
    window.showSlide = (slide: string | null) => {
      setSrc(slide);
    };
  }, []);

  return (
    <>
      <DialogBG />
      <Img src={src || ""} />
    </>
  );
};

declare global {
  interface Window {
    showSlide: (slide: string | null) => void;
  }
}
