import React from "react";
import { useSelector } from "react-redux";
import { getShowIdeal } from "../selectors";
import { lastAgeBand } from "../simulation/sim";
import { AgeBandProp, Conditions, Sim } from "../simulation/simulation-types";
import { showSign } from "../util/stringUtil";

type CriteriaFunction = (
  ageBandIndex: number,
  ageBand: AgeBandProp,
  s: Sim
) => boolean;

const isDeceased = (ageBandIndex: number, ageBand: AgeBandProp, sim: Sim) =>
  !lastAgeBand(ageBandIndex, sim[ageBand]).alive;

const isHealthy =
  (condition: Conditions) =>
  (ageBandIndex: number, ageBand: AgeBandProp, sim: Sim) =>
    !isDeceased(ageBandIndex, ageBand, sim) &&
    !lastAgeBand(ageBandIndex, sim[ageBand]).activeConditions.find(
      (c) => c.condition === condition
    );

const hasConditionAlive =
  (condition: Conditions, level: number) =>
  (ageBandIndex: number, ageBand: AgeBandProp, sim: Sim) =>
    !isDeceased(ageBandIndex, ageBand, sim) &&
    !!lastAgeBand(ageBandIndex, sim[ageBand]).activeConditions.find(
      (c) => c.condition === condition && c.level === level
    );

// const hasCondition =
//   (condition: Conditions, level: number) =>
//   (ageBandIndex: number, ageBand: AgeBandProp, sim: Sim) =>
//     !!lastAgeBand(ageBandIndex, sim[ageBand]).activeConditions.find(
//       (c) => c.condition === condition && c.level === level
//     );

const hasConditionAnyLevel =
  (condition: Conditions) =>
  (ageBandIndex: number, ageBand: AgeBandProp, sim: Sim) =>
    !!lastAgeBand(ageBandIndex, sim[ageBand]).activeConditions.find(
      (c) => c.condition === condition
    );

// const hasConditionDeceased =
//   (condition: Conditions, level: number) =>
//   (ageBandIndex: number, ageBand: AgeBandProp, sim: Sim) =>
//     isDeceased(ageBandIndex, ageBand, sim) &&
//     !!lastAgeBand(ageBandIndex, sim[ageBand]).activeConditions.find(
//       (c) => c.condition === condition && c.level === level
//     );

const countSims = (
  ageBandIndex: number,
  ageBandProp: AgeBandProp,
  criteria: CriteriaFunction,
  sims: Sim[]
) => sims.filter((sim) => criteria(ageBandIndex, ageBandProp, sim)).length;

const StatRow: React.FC<{
  sims: Sim[];
  ageBandIndex: number;
  showIdeal: boolean;
  criteria: CriteriaFunction;
  label: String;
}> = ({ showIdeal, label, sims, ageBandIndex, criteria }) => {
  const bauCount = countSims(ageBandIndex || 0, "bauAgeBands", criteria, sims);
  const userCount = countSims(ageBandIndex || 0, "ageBands", criteria, sims);
  const idealCount = countSims(
    ageBandIndex || 0,
    "idealAgeBands",
    criteria,
    sims
  );

  return (
    <tr>
      <td>{label}</td>
      <td>{bauCount}</td>
      <td>
        {userCount} ({showSign(userCount - bauCount)})
      </td>

      {showIdeal && (
        <td>
          {idealCount} ({showSign(idealCount - bauCount)})
        </td>
      )}
    </tr>
  );
};

interface DPTProps {
  ageBandIndex: number;
  condition: Conditions;
  sims: Sim[];
}
export const DebugPrevalanceTable: React.FC<DPTProps> = ({
  ageBandIndex,
  condition,
  sims,
}) => {
  const showIdeal = useSelector(getShowIdeal);
  return (
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          <th></th>
          <th>BaU</th>
          <th>User</th>
          {showIdeal && <th>Ideal</th>}
        </tr>
      </thead>
      <tbody>
        <StatRow
          ageBandIndex={ageBandIndex || 0}
          criteria={isDeceased}
          label="Deceased"
          sims={sims}
          showIdeal={showIdeal}
        />
        <StatRow
          ageBandIndex={ageBandIndex || 0}
          criteria={isHealthy(condition)}
          label={"Healthy / No Frailty"}
          sims={sims}
          showIdeal={showIdeal}
        />

        {[1, 2, 3, 4].map((level) => (
          <StatRow
            key={level}
            ageBandIndex={ageBandIndex || 0}
            criteria={hasConditionAlive(condition, level)}
            label={`${condition}-${level} `}
            sims={sims}
            showIdeal={showIdeal}
          />
        ))}

        <StatRow
          ageBandIndex={ageBandIndex || 0}
          criteria={hasConditionAnyLevel(condition)}
          label={`${condition} (total)`}
          sims={sims}
          showIdeal={showIdeal}
        />
      </tbody>
    </table>
  );
};
